import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { getAgent, editAgentProfile } from '../../../services/admin';
import { useParams } from 'react-router-dom';
import { API_DOMAIN, AGENT_PROFILE_URL, getStateByCode } from '../../../constants/common';

import Alert from 'react-bootstrap/Alert';

import './EditAgentProfile.scss';
import AuthChecker from '../AuthChecker/AuthChecker';
import EditAgent from '../editagent';
import { ToastContainer, toast } from 'react-toastify';
import { AgentProfileWrapper, AgentListWrapper, AgentListItem } from '../AgentProfile/AgentProfile';
import { Loader } from '../../../components/loader';
import { useContext } from 'react';
import { MainContext } from '../../../context';
import { StaticNavigation } from '../layout';

const EditAgentProfile = () => {
  let { agent_id } = useParams();

  const mlsNameInputRef = useRef(null);
  const mlsCodeInputRef = useRef(null);
  const mlsOfficeInputRef = useRef(null);

  const licenseInputRef = useRef(null);
  const licenseStateInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState('');

  const [mlsNameInput, setMlsNameInput] = useState('');
  const [mlsCodeInput, setMlsCodeInput] = useState('');
  const [mlsOfficeInput, setMlsOfficeInput] = useState('');

  const [licenseInput, setLicenseInput] = useState('');
  const [licenseStateInput, setLicenseStateInput] = useState('');

  const [mls, setMls] = useState([]);
  const [licenses, setLicenses] = useState([]);

  const [agent, setAgent] = useState(false);
  const [imgSrc, setImgSrc] = useState('');

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showLoadingAlert, setShowLoadingAlert] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});

  const [selectedCompensation, setSelectedCompensation] = useState('');
  const toastId = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { loading, setLoading, setActivePage, activeAgent } = useContext(MainContext);

  const fetchAgent = async () => {
    setLoading(true);
    try {
      let data = await getAgent({ agent_id: agent_id });
      if (data.result) {
        setLoading(false);
        const { states_served, cities_served, communities_served } = data.agent;

        if (data.agent.states_served) {
          data.agent.states_served = states_served.split(',').map((s) => {
            return { name: getStateByCode(s), value: s };
          });
        }
        if (data.agent.cities_served && typeof data.agent.cities_served == 'string') {
          data.agent.cities_served = cities_served.split(',').map((s) => {
            return { name: s, value: s };
          });
        }
        data.agent.communities_served =
          (communities_served &&
            communities_served.map((s) => {
              return { ...s, value: s.id };
            })) ||
          [];

        if (data.agent.mls.length == 0) {
          data.agent.mls = [{ name: '', code: '', office: '' }];
        } else {
          data.agent.mls = data.agent.mls.map((m) => {
            return {
              ...m,
              name: m.mls_name,
              code: m.mls_code,
            };
          });
        }
        if (data.agent.licenses.length == 0) {
          data.agent.licenses = [{ license: '', state: '' }];
        }
        setAgent(data.agent);
        setImgSrc(imageUrl(data.agent.photo));
        setMls([]);
        data.agent.mls.forEach((item) => {
          setMls((arr) => arr.concat({ name: item.mls_name, code: item.mls_code, office: item.office }));
        });

        setSelectedCompensation(agent.compensation_plan);

        setLicenses(data.agent.licenses);
      } else {
        setLoading(false);
        window.location.href = '/error/404';
      }
    } catch {
      setLoading(false);
      window.location.href = '/error/404';
    }
  };

  const imageUrl = (url) => {
    return `${AGENT_PROFILE_URL}${url}`;
  };

  const handleImageError = (event) => {
    let avatar = 'avatar-user-profile.svg';
    event.target.src = imageUrl(avatar); // Set the placeholder image source
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedCompensation(event.target.value);
  };

  const handleMlsNameInputChange = (elem) => {
    console.log(elem.target.value);
    setMlsNameInput(elem.target.value);
  };

  const handleMlsCodeInputChange = (elem) => {
    console.log(elem.target.value);
    setMlsCodeInput(elem.target.value);
  };

  const handleMlsOfficeInputChange = (elem) => {
    console.log(elem.target.value);
    setMlsOfficeInput(elem.target.value);
  };

  const addMlsAction = () => {
    if (mlsNameInput !== '' && mlsCodeInput !== '') {
      setMls((arr) => arr.concat({ name: mlsNameInput, code: mlsCodeInput, office: mlsOfficeInput }));
      setMlsNameInput('');
      setMlsOfficeInput('');
      setMlsCodeInput('');
    }
  };

  const handleLicenseInputChange = (elem) => {
    console.log(elem.target.value);
    setLicenseInput(elem.target.value);
  };

  const handleLicenseStateInputChange = (elem) => {
    console.log(elem.target.value);
    setLicenseStateInput(elem.target.value);
  };

  const addLicenseAction = () => {
    if (licenseInput !== '') {
      setLicenses((arr) => arr.concat({ license: licenseInput, state: licenseStateInput }));
      setLicenseInput('');
      licenseInputRef.value = '';
      licenseStateInputRef.value = '';
    }
  };

  const handleRemoveMls = (index) => {
    setMls((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const handleRemoveLicenses = (index) => {
    setLicenses((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const handleEditAgent = async (data) => {
    try {
      toastId.current = toast.loading('Submitting', {
        onOpen: () => setIsSubmitting(true),
      });

      let result = await editAgentProfile(data);
      //setShowLoadingAlert(false)
      if (result.result) {
        //setShowSuccessAlert(true);
        toast.update(toastId.current, {
          render: 'Agent successfully updated',
          type: 'success',
          autoClose: 2000,
          closeOnClick: true,
          isLoading: false,
          onClose: () => {
            if (agent && agent.user.user_role_id == 2) {
              window.location.href = '/admin/agents';
            } else window.location.href = window.location.pathname.replace('/edit', '');
          },
        });
      } else {
        toast.update(toastId.current, {
          render: result.message,
          type: 'error',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
          onClose: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (e) {
      toast.update(toastId.current, {
        render: e?.response?.data?.message ?? 'Something went wrong.',
        type: 'error',
        isLoading: false,
        closeOnClick: true,
        autoClose: 2000,
        onClose: () => setIsSubmitting(false),
      });
    }
  };

  const handleSubmit2 = useCallback((values) => {
    setFieldErrors({});

    const data = {
      ...values,
      name: values.first_name + ' ' + values.last_name,
      mls: JSON.stringify(values.mls),
      licenses: JSON.stringify(
        values.licenses.map((l) => {
          return {
            license: l.license,
            state: l.state,
          };
        }),
      ),
      states_served: '',
      cities_served: '',
      agent_id: values.id,
      title: '',
      disclaimer: '',
      office_city_st_zip: '',
     
    };

    if(values.loan_officer) {
      data['loan_officer'] =  values.loan_officer.value;
    }
    if (typeof values.photo === 'string') {
      data['photo'] = '';
    } else {
      data['photo'] = values.photo;
    }
    if (values.states_served) {
      data['states_served'] = values.states_served.map((a) => a.value).join(',');
    }
    if (values.cities_served) {
      //city|state, city|state format
      data['cities_served'] = values.cities_served.map((a) => a.name.replace(', ', '|')).join(',');
    }
    if (values.communities_served) {
      data['communities_served'] = values.communities_served.map((a) => a.value).join(',');
    }

    if(values.enable_subdomain == 1) {
      data['enable_subdomain'] = 'true';
    }

    if (!data.first_name) {
      setFieldErrors({ ...fieldErrors, first_name: 'First Name Required.' });
    } else if (!data.last_name) {
      setFieldErrors({ ...fieldErrors, last_name: 'Last Name Required.' });
    } else if (!data.brokerage) {
      setFieldErrors({ ...fieldErrors, brokerage: 'Brokerage Required.' });
    } else if (!data.mobile_phone) {
      setFieldErrors({ ...fieldErrors, mobile_phone: 'Mobile number Required' });
    } else {
      handleEditAgent(data);
    }
  }, []);

  useEffect(() => {
    setActivePage('agent-profile-edit');
    fetchAgent();
  }, []);

  useEffect(() => {
    if (Object.keys(fieldErrors).length > 0) {
      toast.error('Please fill the necessary fields', {
        autoClose: 2000,
      });
    }
  }, [fieldErrors]);

  const handleSave = () => {
    handleSubmit2(activeAgent);
  };

  return (
    <div className="">
      <AuthChecker />

      <StaticNavigation page="agent-profile-edit" handleSave={handleSave} />
      {!agent && loading && <Loader />}
      {agent && (
        <AgentProfileWrapper>
          {loading && <Loader />}
          {!loading && (
            <EditAgent isSubmitting={isSubmitting} agent={agent} handleSubmit={handleSubmit2} errors={fieldErrors} />
          )}
        </AgentProfileWrapper>
      )}

      <ToastContainer />
    </div>
  );
};

export default memo(EditAgentProfile);
