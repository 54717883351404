import { useEffect, useState, useContext} from "react";
import { useParams } from "react-router-dom";
import { MainContext } from "../context";
import Loader from "../components/loader";
import { getOfficer } from "../services/admin";
import { AgentProfileWrapper, AgentListWrapper, AgentListItem } from "./Admin/AgentProfile/AgentProfile";
import styled from 'styled-components'
import Officer from "./Admin/officer";
const NotFound = styled.h1`
    text-align: center;
    margin-top: 4rem !important;
`
const PublicLoanOfficer = () => {
    let { id } = useParams();
    const [officer, setAgent] = useState(false);
    const { loading, setLoading } = useContext(MainContext);
   
    const fetchAgent = async () => {
        setLoading(true)
        let data = await getOfficer({ id })
        setLoading(false)
        if (data.officer) {
            setAgent(data.officer)
        }
    }

    useEffect(() => {
        fetchAgent()
    }, [])


    return (
        <div>
            {loading && <Loader/>}
            {officer && <AgentProfileWrapper>
                            {loading && <Loader/>}
                            {!loading && <Officer officer={officer} isPublic={true} />}
                        </AgentProfileWrapper> }
                        {!loading && !officer && <NotFound>404: NOT FOUND</NotFound>}
            
        </div>
    )
}

export default PublicLoanOfficer
