const CITY_DESCRIPTION_TITLES = {
  0: '',
  1: 'Education',
  2: 'Weather', //pending image
  3: 'Recreation',
  4: 'Economy',
  5: 'History', //pending image
  6: 'Sports', //pending image
  7: 'Arts',
  8: 'Retired',
  9: 'Family',
  10: 'Young Adults',
  11: 'Golf', //pending image
  12: 'Retail',
  13: 'Boating', //pending image
  14: 'Hunting', //pending image
  16: 'Equestrian', //pending image
  17: 'Airports',
};

const insertImgToArray = (array) => {
  return array.map((item, index) => {
    const _item = { ...item };

    const title = CITY_DESCRIPTION_TITLES[_item.type] ?? '';
    _item.title = title;
    if (title.toLowerCase().includes('schools') || title.toLowerCase().includes('education') || _item.type == 1) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=jdVtnriOuChPArfyJRil7WJobPJh1r6M&fmode=open';
    } else if (title.toLowerCase().includes('weather') || _item.type == 2) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=8X1TPXEeKMl6DRXzTYEeHwOyZJETvJgg&fmode=open';
    } else if (title.toLowerCase().includes('recreation') || _item.type == 3) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=2Qnc18lGgqNlwWQeMGzQ4N6qVEHz1KZI&fmode=open';
    } else if (title.toLowerCase().includes('economi') || _item.type == 4) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=2khoNeQfl7Afgifv63p6om55wZP3U4Ju&fmode=open';
    } else if (title.toLowerCase().includes('history') || _item.type === 5) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=jJEhej9f9aybHI0f8EDkIrBE2OC1DJUu&fmode=open';
    } else if (title.toLowerCase().includes('sports') || _item.type == 6) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=KWqSHgBKap3N2gKi3IuPGZNundwZIt3N&fmode=open';
    } else if (title.toLowerCase().includes('arts') || _item.type == 7) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=DGV5QJ326g8lgloiFKOvsxjGLTjPcAIV&fmode=open';
    } else if (title.toLowerCase().includes('seniors') || _item.type == 8 || title.toLowerCase().includes('retire')) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=dRjByjYn7MX4S4EyrDib9OaHzZlZejHf&fmode=open';
    } else if (title.toLowerCase().includes('famil') || _item.type == 9) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=gsnXq5doU6x7wIj0JQlOMyXNEYpJfzvX&fmode=open';
    } else if (title.toLowerCase().includes('young adults') || _item.type == 10) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=f6lSFzBSXaF60Tihe6O4wcAAC6rXED2a&fmode=open';
    } else if (title.toLowerCase().includes('golf') || _item.type == 11) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=BRDx2ewSxgfFpYpW15hJxdsY8zHBgtcS&fmode=open';
    } else if (title.toLowerCase().includes('retail') || _item.type == 12) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=ssDR2AE2d7Y8fzpGEMddJw2NOyrOwx2E&fmode=open';
    } else if (title.toLowerCase().includes('boating') || _item.type == 13) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=Whz7wqzm6rbv7S2qsrGQAGSCAAPqLRvn&fmode=open';
    } else if (title.toLowerCase().includes('hunting') || _item.type == 14) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=RGD6tIE8FDM1PnUwAZgFw3E86HnkFgrd&fmode=open';
    } else if (title.toLowerCase().includes('equestrian') || _item.type == 16) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=rXJM02yzg6AWMoABs1CzQdrpoMZiXa8V&fmode=open';
    } else if (
      title.toLowerCase().includes('transportation') ||
      title.toLowerCase().includes('airport') ||
      _item.type == 17
    ) {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=T2eiyUhGtLCi7waIAx9NzYWJRAtGD4At&fmode=open';
    } else {
      _item.img = 'https://bucket.ywhdev.com/wl/?id=k5Vmj2IDDcAVtudupcaRQ0fp4nP4XVaa&fmode=open';
    }

    return _item;
  });
};

export default insertImgToArray;
