import { useQuery } from '@tanstack/react-query';
import { getImages } from '../../../services/general';
import { COMMUNITY_IMAGES } from './key';

export default function useGetCommunityDetails({ state, market, community, id }) {
  return useQuery({
    queryKey: [COMMUNITY_IMAGES, id],
    queryFn: async () => {
      const params = { state, market, community, id };

      const data = await getImages(params);

      return data;
    },
    enabled: !!id,
  });
}
