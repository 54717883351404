import { centerCrop, makeAspectCrop } from 'react-image-crop';

const centerAspectCrop = (mediaWidth, mediaHeight, aspect = 1) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 450,
        height: 450,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );

export default centerAspectCrop;
