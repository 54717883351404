import { memo, useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { login } from '../../../services/admin';
import { setAccessToken, removeAccessToken, getUserType } from '../../../utils/common';

import './Logout.scss';
import routes from '../../../routes';
import { MainContext } from '../../../context';
import { getLocalStorage, removeLocalStorage } from '../../../utils/storage';
import { STORAGE_KEYS } from '../../../constants/common';

const Logout = () => {
    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(MainContext);

    const logoutUser = async () => {
        const userType = getUserType();
        removeAccessToken();
        removeLocalStorage(STORAGE_KEYS.USER_TYPE);
        removeLocalStorage('YWH_REMEMBER_ME_LOGIN');
        setIsLoggedIn(false);
        if(userType == '3') {
            navigate(routes.userLogin())
        } else navigate(routes.login());
    }

    useEffect(() => {
        if(getLocalStorage('agent_id')) {
            removeLocalStorage('agent_id')
        }
        logoutUser()
    }, []);
};


export default memo(Logout);