import { styled } from '@mui/material';

const Section4Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '64px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '64px 32px 0',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '2.5rem 1rem',
  },
  '& div.content': {
    order: 2,
    width: 526,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    [theme.breakpoints.down('lg')]: {
      width: 446,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      order: 1,
    },
    h3: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 500,
      textAlign: 'justify',
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    order: 1,
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 500,
      objectFit:'cover',
      boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        width: 500,
        order: 2,
      },
    },
  },
}));

const Section4 = () => {
  return (
    <Section4Container>
      <div className="content">
        <h3>Our team will help you understand which new home options add to resale value and which options don't.</h3>
        <p>
          Our team will help you understand which new home options add to resale value and which options don't. Plus
          we're experienced in matters such as understanding the soils report (and the difference between caissons and
          footers, slab on grade versus a structural floor). We have the resources to help inform you of future
          surrounding development. And we'll explain mill levies and how special metro districts will affect your taxes.
          We're your expert set of eyes and your advocate. We work hand in hand with the home builders and understand
          they have a tough job to do. We just want you to get nothing less than the best.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=aqNbMH80EsQsyPnEAKvqieJ1S4Zm7pcF&fmode=open"
          alt="showing two women having a conversation."
        />
      </div>
    </Section4Container>
  );
};

export default Section4;
