import { styled } from '@mui/system';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/loader';
import { DOMAIN } from '../../constants/common';
import { useMainContext } from '../../context';
import { getCityDescription, getCommunityList } from '../../services/general';
import './CommunityList.scss';
import { CityCard } from './components';
import { getLastParagraph, insertImgToArray, removeLastParagraph } from './utils';

const CityContainer = styled('div')({
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #edf2f4 44.79%)',
  paddingBottom: 96,
});

const CityWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 64,
  fontFamily: 'Montserrat',
  padding: '6rem 3rem 0 3rem',
});

const CityFooter = styled('div')({
  textAlign: 'center',
  padding: 64,
  maxWidth: 1200,
  backgroundColor: '#f4f4f6',
  boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.4)',
  borderRadius: 16,
  margin: '0 auto',
  marginTop: 24,
  '& h3': {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1.4,
  },
});

const CitySection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '100%',
  },
});

const CityCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '6rem 0',
  gap: 180,
  flexGrow: 0,
  maxWidth: 1370,
  [theme.breakpoints.down('md')]: {
    gap: 120,
  },
}));

const CitySectionHeader = styled('header')({
  gap: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  '& div.details-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: 64,
    maxWidth: 950,
    '& h1': {
      marginBottom: -56,
      color: '#04246b',
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      zIndex: 20,
    },
    '& h2': {
      marginBottom: -56,
      color: '#04246b',
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      zIndex: 20,
    },
    '& div': {
      fontSize: '1.7rem',
      fontWeight: 400,
      lineHeight: 1.3,
      maxWidth: 950,
      textAlign: 'center',
    },
    '& ul': {
      '& li': {
        borderRadius: 8,
        background: '#6292E4',
        boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.40)',
        color: 'white',
        fontSize: 20,
        lineHeight: 1.6,
        padding: 32,
      },
    },
  },
  '& h2': {
    color: '#04246b',
    fontSize: 39,
    fontWeight: 700,
    lineHeight: 1.3,
    margin: 0,
    textAlign: 'center',
    zIndex: 20,
    maxWidth: 900,
  },
  '& h3': {
    fontSize: 30,
    fontWeight: 400,
    lineHeight: 1.3,
    margin: 0,
    maxWidth: 1244,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  '& img.header-icon': {
    width: 280,
    position: 'absolute',
    top: 20,
  },
});

const CommunityListTile = styled('div')(({ theme }) => ({
  marginTop: 120,
  display: 'flex',
  justifyContent: 'center',
  gap: 32,
  maxWidth: 1400,
  flexWrap: 'wrap',
  '& a': {
    borderRadius: 8,
    background: '#6292E4',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.40)',
    fontSize: 20,
    lineHeight: 1.6,
    padding: 32,
    color: 'white',
    width: 'fit-content',
  },
}));

const CommunityList = () => {
  const navigate = useNavigate();
  const { state, market } = useParams();
  const { loading, setLoading } = useMainContext();
  const [city, setCity] = useState(null);
  const [cityDescriptions, setCityDescriptions] = useState([]);

  const fetchCommunityList = async () => {
    setLoading(true);
    try {
      const params = {
        state: state,
        market: market,
      };
      const result = await getCommunityList(params);

      if (result.result) {
        setCity({ ...result, city_name: _.startCase(result.city_name) });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCityDescription = async () => {
    setLoading(true);
    try {
      const params = {
        state: state,
        market: market,
      };
      const result = await getCityDescription(params);

      if (result.result) {
        setCityDescriptions(result.descriptions);
      } else {
        navigate('/error/404', { replace: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommunityList();
    fetchCityDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardDetails = useMemo(() => {
    if (cityDescriptions && cityDescriptions.length > 0) {
      return insertImgToArray(cityDescriptions.slice(1));
    }

    return [];
  }, [cityDescriptions]);

  const citySummary = useMemo(() => {
    if (cityDescriptions && cityDescriptions.length > 0) {
      return getLastParagraph(cityDescriptions[0].description);
    }

    return '';
  }, [cityDescriptions]);

  if (loading) {
    return <Loader />;
  }

  return (
    <CityContainer>
      <div className="city-hero">
        <img src="https://bucket.ywhdev.com/wl/?id=H5hvOXyj2YMyJwWYCvMkJ3RfnjNhpdMA&fmode=open" alt="Header" />
        {city?.city_name && (
          <h1
            style={{
              bottom: 0,
              top: 'unset',
              left: 'unset',
              width: '100%',
              borderRadius: 0,
              padding: '32px 64px',
              fontSize: '4rem',
              lineHeight: 1.3125,
              fontFamily: 'Playfair Display',
              textTransform: 'uppercase',
              transform: 'none',
            }}
          >
            {city?.city_name}
            {`, ${city?.state_name}` ?? ''}
          </h1>
        )}
      </div>
      <CityWrapper>
        <CitySection>
          <CitySectionHeader style={{ paddingTop: 240 }}>
            <img
              className="header-icon"
              src="https://bucket.ywhdev.com/wl/?id=wLxwGWRVuAY5jXTceCeyyRTJqUXTBDW5&fmode=open"
              alt=""
            />
            <h2 style={{ maxWidth: 600 }}>Explore New Home Communities In {city?.city_name}</h2>
            <h3 style={{ maxWidth: 922, fontWeight: 500, fontSize: 28 }}>
              Dreaming of a brand new home? You'll find it here. To search for resale homes click here.
            </h3>
          </CitySectionHeader>
          {!!city?.communities.length && (
            <CommunityListTile>
              {city?.communities.map((cm, i) => {
                const url = `/community/${cm.slug.substring(0, 2)}/${_.kebabCase(
                  city?.city_name,
                )}/${_.kebabCase(cm.name)}/${cm.id}`;
                return (
                  <Link to={url} key={i}>
                    {cm.name}
                  </Link>
                );
              })}
            </CommunityListTile>
          )}
        </CitySection>
        <CitySection>
          <img src="https://bucket.ywhdev.com/wl/?id=xIYNCOQGLZJgFwo1ILy2YyeRGWlNcrb1&fmode=open" alt="" />
        </CitySection>
        <CitySection>
          <CitySectionHeader>
            <CitySectionHeader>
              {city && (
                <h2>
                  Discover {city.city_name}: Why this city in {city.state_name} Might Be Your Next Home
                </h2>
              )}
              {cityDescriptions.length > 0 && (
                <h3>{removeLastParagraph(cityDescriptions[0].description.replace('-*-', '').replace('- * -', ''))}</h3>
              )}
            </CitySectionHeader>
            {!!cardDetails.length && (
              <CityCardContainer>
                {cardDetails.map((data, index) => (
                  <CityCard key={index} img={data.img} title={data.title} description={data.description} />
                ))}
              </CityCardContainer>
            )}
          </CitySectionHeader>
        </CitySection>
      </CityWrapper>
      {citySummary && (
        <CityFooter>
          <h3>{citySummary}</h3>
        </CityFooter>
      )}
    </CityContainer>
  );
};

export default CommunityList;
