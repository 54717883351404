const BeachIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path
      d="M31.9959 32.0041L23.7471 62M31.9959 32.0041L3.02286 24.2396C6.4094 11.5948 17.3924 3.013 29.7462 2.08312M31.9959 32.0041L60.9705 39.7685C64.477 26.6798 58.7643 13.2956 47.8128 6.50752M31.9959 32.0041L60.969 39.7685M47.8128 6.50752C45.313 4.96026 42.5994 3.7889 39.7589 3.03099C36.4986 2.15292 33.1134 1.83245 29.7462 2.08312M47.8128 6.50752C45.3132 4.95922 42.5995 3.78834 39.7589 3.02949M29.7462 2.08312C33.1132 1.83147 36.4985 2.15243 39.7589 3.02949M39.7589 3.02949C39.7589 3.02949 31.3 8.52625 26.4617 14.9934C21.6233 21.4605 20.407 28.898 20.407 28.898M39.7589 3.02949C39.7589 3.02949 44.3378 12.0208 45.2946 20.0402C46.2515 28.0596 43.5849 35.1101 43.5849 35.1101M2 62H61.9919"
      stroke="#3C4D7D"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BeachIcon;
