import { getRequest, postRequest } from './base';

export const getImages = async (params) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id;

    let url = `/api/community/images/${state}/${market}/${community}/${id}`;
    return getRequest(url);
};

export const getCommunity = async (params) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id;

    let url = `/api/community/${state}/${market}/${community}/${id}`;
    return getRequest(url);
};

export const getCommunityDescription = async (params) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id

    let url = `/api/community/description/${state}/${market}/${community}/${id}`;
    return getRequest(url);
};

export const getCityDescription = async (params) => {
    let state = params.state;
    let market = params.market;

    let url = `/api/city/description/${state}/${market}`;
    return getRequest(url);
};

export const getHighlights = async (params) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id;

    let url = `/api/community/highlight/${state}/${market}/${community}/${id}`;
    return getRequest(url);
};

export const getAllHighlights = async () => {
    let url = `/api/community/highlights`;
    return getRequest(url);
};

export const getMap = async (params) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;

    let url = `/api/community/map/${state}/${market}/${community}`;
    return getRequest(url);
};

export const getAgent = async (params) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id;


    let url = `/api/community/agent/${state}/${market}/${community}/${id}`;
    return getRequest(url);
};

export const scheduleTour = async (params, data) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id;

    let url = `/api/community/tour/${state}/${market}/${community}/${id}`;
    return postRequest(url, data);
};

export const sendAgentMessage = async (params, data) => {
    let state = params.state;
    let market = params.market;
    let community = params.community;
    let id = params.id;

    let url = `/api/community/message/${state}/${market}/${community}/${id}`;
    return postRequest(url, data);
};

export const getStates = async () => {
    // let state = params.state;

    let url = `/api/community`;
    return getRequest(url);
};

export const getMarkets = async (params) => {
    let state = params.state;

    let url = `/api/community/${state}`;
    return getRequest(url);
};

export const getCommunityList = async (params) => {
    let state = params.state;
    let market = params.market;

    let url = `/api/community/${state}/${market}`;
    return getRequest(url);
};


export const getUserData = async () => {
    return getRequest('/api/user/me');
};

export const getDataByUsername = async (params) => {
    return getRequest('/api/data/username/get', params);
};

export const getPublicAgent = async (params) => {
    let url = `/api/agent/${params.agent_id}`;
    return getRequest(url);
};

export const getPublicAgentBySubdomain = async (params) => {
    let url = `/api/agent/subdomain/${params.subdomain}`;
    return getRequest(url);
};

export const getCitiesByState = async (state) => {
    return getRequest(`/api/cities/${state.toUpperCase()}`);
}
