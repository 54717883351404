import { styled } from '@mui/material';
import React from 'react';
import { HomePageButton } from './HomePage';

const HomePageSection = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  // [theme.breakpoints.down('lg')]: {
  //   maxWidth: 1200,
  // },
}));

const HomepageSection2 = styled(HomePageSection)(({ theme }) => ({
  padding: '120px 0 0 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 120,
  alignSelf: 'stretch',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    backgroundColor: '#EDF2F4',
    padding: '0 64px 0 0',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: 0,
    gap: 0,
  }
}));

const HomepageSection2RightCol = styled('div')(({ theme }) => ({
  order: 2,
  borderRadius: '32px 0px 0px 32px',
  padding: '64px 120px 64px 240px',
  marginLeft: -240,
  backgroundColor: '#EDF2F4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 830,
  gap: 32,
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
    width: 496,
    margin: 0,
    padding: '100px 0px',
    borderRadius: 0,
  },
  [theme.breakpoints.down('md')]: {
    order: 1,
    padding: '80px 32px',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& p': {
    fontSize: 18,
    lineHeight: '28px',
  },
  '& p.title': {
    borderBottom: '2px solid #CDA07D',
    paddingBottom: 8,
  },
}));

const HomepageSection2LeftCol = styled('div')(({ theme }) => ({
  zIndex: 2,
  maxWidth: 800,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 0,
  },
  [theme.breakpoints.down('md')]: {
    order: 2,
    width: '100%',
    flex: 1,
    maxWidth: '100%',
  },
  '& img': {
    borderRadius: '0px 16px 16px 0px',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.40)',
    height: 600,
    maxWidth: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: 692,
      width: 576,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: 0,
      height: 300,
      boxShadow: 'none',
    },
  },
}));

const HomepageSection2Heading = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: 16,
  '& img': {
    width: 48,
    height: 48,
  },
  '& h2': {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: 700,
    color: '#04246B',
    textAlign: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Section2 = () => {
  return (
    <HomepageSection2>
      <HomepageSection2LeftCol>
        <img
          src="https://bucket.ywhdev.com/wl/?id=AeGIt0TCJHcuJCCLb3rSDH0LKCguBSSS&fmode=open"
          alt="A beautiful showing a deck of a house."
        />
      </HomepageSection2LeftCol>
      <HomepageSection2RightCol>
        <div>
          <p className="title">We'll help you find the home of your dreams.</p>
        </div>
        <HomepageSection2Heading>
          <img src="https://bucket.ywhdev.com/wl/?id=nch1xUhzdVWlzj4YqLo1r1QhrEsah61V&fmode=open" alt="" />
          <h2>BUY A HOME</h2>
        </HomepageSection2Heading>
        <p>
          Our experienced real estate professionals are here to guide you through the process, from selecting the right
          property to securing financing and closing the deal. With our extensive knowledge of the local real estate
          market and access to the latest listings, we'll help you find the home of your dreams at a price you can
          afford. Whether you're a first-time buyer or a seasoned investor, we have the resources and expertise to help
          you achieve your real estate goals.
        </p>
        <p>Start browsing our inventory today and take the first step towards owning your dream home!</p>
        <HomePageButton onClick={() => window.open('https://www.yourwayhome.net/', '_blank')}>
          <span>View Homes For Sale</span>
          <i className="fa-sharp fa-solid fa-circle-chevron-right"></i>
        </HomePageButton>
      </HomepageSection2RightCol>
    </HomepageSection2>
  );
};

export default Section2;
