const getSubdomain = () => {
  const subdomain = window.location.hostname.replace(/^www\./, '').split('.')[0];

  // not agent subdomain
  if (subdomain === 'localhost' ||
  subdomain === 'yourwayhome' ||
  subdomain === 'dev-ywhcom' ||
  subdomain === 'stage-ywhcom') {
    return null;
  } else {
    return subdomain;
  }
};

export default getSubdomain;
