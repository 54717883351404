const SizeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
    <path
      d="M28.25 20.0897V23.2727H21.75V20.0897V18.8978L20.8996 19.7329L15.8996 24.6437L15.5364 25.0004L15.8996 25.3571L20.8996 30.2678L21.75 31.103V29.9111V26.7281H28.25V29.9111V31.103L29.1004 30.2678L34.1004 25.3571L34.4636 25.0004L34.1004 24.6437L29.1004 19.7329L28.25 18.8978V20.0897ZM12.5 14.679H12V15.179V34.8218V35.3218H12.5H15H15.5V34.8218V15.179V14.679H15H12.5ZM35 14.679H34.5V15.179V34.8218V35.3218H35H37.5H38V34.8218V15.179V14.679H37.5H35Z"
      fill="#3C4D7D"
      stroke="#3C4D7D"
    />
    <circle cx="25" cy="25" r="22" stroke="#3C4D7D" strokeWidth="6" />
  </svg>
);

export default SizeIcon;
