import { memo, useEffect, useState, useCallback, useContext } from 'react';
import { searchCommunities } from '../../../services/admin';

import './SelectCommunity.scss';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import AuthChecker from '../AuthChecker/AuthChecker';
import Communities from '../communities';
import { getCitiesByState, getMarkets, getStates } from '../../../services/general';
import { MainContext } from '../../../context';
import Loader from '../../../components/loader';
import { StaticNavigation } from '../layout';
import _ from 'lodash';
import { getRequest } from '../../../services/base';

const keysArray = ['AZ', 'CA', 'CO', 'DE', 'FL', 'GA', 'MD', 'MI', 'NJ', 'NY', 'NC', 'PA', 'TX', 'UT', 'WA'];

const SelectCommunity = ({hasStatic = true}) => {
  const [communityPage, setCommunityPage] = useState(1);
  const [communityKey, setCommunityKey] = useState(null);
  const [communities, setCommunities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('AZ');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const { loading, setLoading, isLoggedIn, loadMore } = useContext(MainContext);
  const [nextCities, setNextCities] = useState(null);
  const [currentCities, setCurrentCities] = useState(1);
  const handleCommunityPrevPage = () => {
    if (communityPage !== 1) {
      setCommunityPage(communityPage - 1);
    }
  };

  const handleCommunityNextPage = () => {
    setCommunityPage(communityPage + 1);
  };

  const handleCommunitySearchChange = (elem) => {
    setCommunityKey(elem.target.value);
  };

  const findCommunity = async () => {
    let param = {
      key: communityKey,
      page: communityPage,
    };
    let ax = await searchCommunities(param);
    if (ax.result) {
      setCommunities(ax.communities);
    }
  };

  const listStates = async () => {
    let reqStates = await getStates();
    if (reqStates.result) {
      setStates(reqStates.states.filter((s) => s.has_communities));
    }
  };

  const fetchMoreCities = async () => {
    if(nextCities) {
      const result = await getRequest(nextCities)
      if(result.cities.data.length > 0) {
        setNextCities(`/api/cities/${selectedState}?page=${currentCities + 1}`);
        setCurrentCities(currentCities + 1)
        setCities(cities.concat(result.cities.data))
      } else setNextCities(null)
    }
  }

  useEffect(() => {
    if(loadMore) {
      fetchMoreCities()
    }
  }, [loadMore])

  const listCities = async (state) => {
    setCitiesLoading(true);
    try {
      let reqState = await getCitiesByState( state);
      if (reqState.result) {
        setCities(reqState.cities.data);
        setCurrentCities(2)
        setNextCities(`/api/cities/${selectedState}?page=${2}`)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCitiesLoading(false);
    }
  };

  useEffect(() => {
    //findCommunity();
    listStates();
    //listCities(selectedState);
  }, []);

  useEffect(() => {
    if (selectedCommunity === null) {
      const timeoutID = setTimeout(() => {
        findCommunity();
      }, 500);
      return () => clearTimeout(timeoutID);
    }
    if (communityKey == '') {
      setSelectedCommunity(null);
    }
  }, [communityKey]);

  useEffect(() => {
    if (selectedCommunity) {
      setCommunityKey(selectedCommunity.name + ',' + selectedCommunity.city + ',' + selectedCommunity.state);
      setCommunities([]);
    }
  }, [selectedCommunity]);

  useEffect(() => {
    listCities(selectedState);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [selectedState]);

  const handleSearchChange = (name, value) => {
    setCommunityKey('');
    if (name == 'state') {
      setSelectedState(value);
    } else {
      setSelectedCity(value);
    }
  };
  const handleSearch = () => {
    if (selectedCommunity) {
      console.log(selectedCommunity);
      window.open(
        `/community/${selectedCommunity.state.toLowerCase()}/${selectedCommunity.city.toLowerCase()}/${_.kebabCase(
          selectedCommunity.name,
        )}/${selectedCommunity.id}`,
        '_blank',
      );
    }
  };

  return (
    <div className="">
      <AuthChecker />
      {hasStatic && <StaticNavigation />}
      {loading ? (
        <Loader />
      ) : (
        <Communities
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedState={selectedState}
          states={states}
          onChange={handleSearchChange}
          setSelectedState={setSelectedState}
          cities={cities}
          isLoggedIn={isLoggedIn}
          isLoading={citiesLoading}
          keyword={communityKey}
          setKeyword={handleCommunitySearchChange}
          communities={communities}
          setSelectedCommunity={setSelectedCommunity}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
};

export default memo(SelectCommunity);
