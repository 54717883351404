import axios from 'axios';
import { API_DOMAIN, NODEJS_API_DOMAIN } from '../constants/common';
import { getAccessToken } from '../utils/common';

export const baseFetch = async (url, options) => {
    const requestURL = `${API_DOMAIN}${url}`;

    return axios({
        url: requestURL,
        validateStatus: function (status) {
            return (status >= 200 && status < 300) || (status >= 400 && status < 500);
        },
        ...options
    })
        .then((response) => {
            if (response.status >= 400 && response.status < 500) {
                if (response.status === 401) {
                    // error
                }
                else if(response.status === 404) {
                    // window.location.replace('/error/404')
                    return response
                }
                else if(response.status === 400 ||
                    response.status === 404 ||
                    response.status === 422
                    ) {
                    return response.data
                } else {
                    throw new Error('Something went wrong!');
                }
            }

            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getRequest = (url, params, recaptcha_action = null) => {
    const accessToken = getAccessToken();
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        params
    };

    let captcha = null;
    if (recaptcha_action) {
        captcha = recaptcha_action;
    }

    return baseFetch(url, requestOptions, captcha);
};

export const postRequest = (url, body, recaptcha_action = null) => {
    const accessToken = getAccessToken();
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`
        },
        data: body
    };

    let captcha = null;
    if (recaptcha_action) {
        captcha = recaptcha_action;
    }

    return baseFetch(url, requestOptions, captcha);
};

export const putRequest = (url, body, recaptcha_action = null) => {
    const accessToken = getAccessToken();
    const requestOptions = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        data: body
    };

    let captcha = null;
    if (recaptcha_action) {
        captcha = recaptcha_action;
    }

    return baseFetch(url, requestOptions, captcha);
};

export const deleteRequest = (url, body, recaptcha_action = null) => {
    const accessToken = getAccessToken();
    const requestOptions = {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        data: body
    };

    let captcha = null;
    if (recaptcha_action) {
        captcha = recaptcha_action;
    }

    return baseFetch(url, requestOptions, captcha);
};

export const baseNodeApiFetch = async (url, options) => {
    return axios({
        baseURL: NODEJS_API_DOMAIN || 'http://localhost:7001',
        url: url,
        ...options
    }).then(response =>  {
        return response.data
    })
    .catch(error => {
        if(error.response.status === 400 ||
            error.response.status === 404 ||
            error.response.status === 422
            ) {
            return error.response.data
        } else {
            throw new Error('Something went wrong!');
        }
    })
}
