import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Make sure your front entrance is clean and inviting',
  "Paint or replace your front door if it's faded or worn.",
  'Add some paint to shutters, trim, and any other outside features showing signs of wear.',
];

const Section1Container = styled('section')(({ theme }) => ({
  width: '100%',
  paddingBottom: 96,
  padding: 100,
  [theme.breakpoints.down('md')]: {
    padding: '80px 0',
    paddingBottom: 0,
  },
  '& div.header': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    textAlign: 'center',
    marginBottom: 32,
    padding: '0 32px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      color: '#04246B',
    },
    h5: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: 64,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '64px 0',
      gap: 64,
      paddingBottom: 0,
      maxWidth: '100%',
    },
    ul: {
      flexBasis: '50%',
      maxWidth: 436,
      display: 'flex',
      gap: 24,
      flexDirection: 'column',
      padding: 0,
      flexShrink: 0,
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      textAlign: 'center',
      width: '100%',
      img: {
        objectFit: 'cover',
        height: 400,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
          <circle cx="50" cy="51" r="48.5" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M71.8182 74.4751C71.8182 75.193 71.5308 75.8815 71.0194 76.3891C70.5079 76.8967 69.8142 77.1818 69.0909 77.1818H30.9091C30.1858 77.1818 29.4921 76.8967 28.9806 76.3891C28.4692 75.8815 28.1818 75.193 28.1818 74.4751V50.115H20L48.1645 24.7047C48.6667 24.2513 49.3211 24 50 24C50.6789 24 51.3333 24.2513 51.8355 24.7047L80 50.115H71.8182V74.4751ZM66.3636 71.7685V45.1293L50 30.3644L33.6364 45.1293V71.7685H66.3636ZM50 66.3551L40.8391 57.2634C40.2693 56.6978 39.8173 56.0265 39.5089 55.2876C39.2005 54.5487 39.0418 53.7568 39.0418 52.957C39.0418 52.1573 39.2005 51.3653 39.5089 50.6265C39.8173 49.8876 40.2693 49.2162 40.8391 48.6507C41.4089 48.0852 42.0854 47.6366 42.8299 47.3305C43.5744 47.0245 44.3723 46.867 45.1782 46.867C45.984 46.867 46.782 47.0245 47.5265 47.3305C48.271 47.6366 48.9475 48.0852 49.5173 48.6507L50 49.1298L50.4827 48.6507C51.0525 48.0852 51.729 47.6366 52.4735 47.3305C53.218 47.0245 54.016 46.867 54.8218 46.867C55.6277 46.867 56.4256 47.0245 57.1701 47.3305C57.9146 47.6366 58.5911 48.0852 59.1609 48.6507C59.7307 49.2162 60.1827 49.8876 60.4911 50.6265C60.7995 51.3653 60.9582 52.1573 60.9582 52.957C60.9582 53.7568 60.7995 54.5487 60.4911 55.2876C60.1827 56.0265 59.7307 56.6978 59.1609 57.2634L50 66.3551Z"
            fill="#CDA07D"
          />
        </svg>
        <h2>First Impressions are Lasting Impressions</h2>
        <h5>The exterior of your home often determines how buyer will view the interior of the home.</h5>
      </div>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=6z4v6iKgbvugHK88LflGcupi1sOymya1&fmode=open" alt="" />
        </div>
      </div>
    </Section1Container>
  );
};

export default Section1;
