import React from 'react';
import { styled } from '@mui/system';

const CitySectionCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  // alignItems: 'center',
  width: 500,
  '& h4': {
    fontSize: '1.75rem',
    fontWeight: 700,
    lineHeight: 1.42,
    marginBottom: -16,
  },
  '& h6': {
    fontSize: '1.25rem',
    lineHeight: 1.4,
    fontWeight: 400,
  },
  '& img': {
    width: '100%',
    borderRadius: '80px 8px',
    height: 500,
  },
  '& div': {
    width: '100%',
    border: '2px solid #cda07d',
    borderRadius: 10,
    height: 1,
  },
  '& ul': {
    '& li': {
      fontWeight: 400,
      lineHeight: 1.4,
      fontSize: '1.25rem',
      listStyle: 'disc',
    },
  },
});

const CityCard = ({ title, img, description }) => {
  return (
    <CitySectionCard>
      <img src={img} alt="" />
      <h4>{title}</h4>
      <div />
      {typeof description === 'string' ? (
        <h6>{description}</h6>
      ) : (
        <ul>
          {description?.map((desc) => (
            <li key={desc}>{desc}</li>
          ))}
        </ul>
      )}
    </CitySectionCard>
  );
};

export default CityCard;
