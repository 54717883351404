import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  background-color: #04246b;
  border-radius: 6.25rem;
  display: flex;
  padding: 0.75rem 2.5rem;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
`;

const ViewHomes = ({ url, agentIdentifier, fallbackUrl, city, state, priceMax, priceMin }) => {
  const handleViewHomesClick = () => {
    if (url) {
      let _url = url.replace(
        'https://www.newhomesource.com',
        `https://www.showingnew.com/${
          // (subdomainAgent && subdomainAgent.showingnew_identifier) ||
          agentIdentifier ?? 'yourwayhome'
        }`,
      );
      fetch(_url)
        .then((_) => window.open(_url))
        .catch((_) => {
          console.log('Error');
        });
    } else {
      if (fallbackUrl) {
        window.open(fallbackUrl);
      } else {
      }
    }
  };

  const handleResaleClick = async () => {
    let key = encodeURIComponent(city + ', ' + state.toUpperCase());
    let minPrice = priceMin ?? 0;
    let maxPrice = priceMax ?? 0;

    if (minPrice === 0) {
      minPrice = 200000;
    } else if (minPrice > 100000) {
      minPrice = minPrice - 100000;
    }

    if (maxPrice === 0) {
      maxPrice = 3000000;
    } else {
      maxPrice = maxPrice + 500000;
    }

    const year = new Date().getFullYear();
    let condition = JSON.stringify({
      location: {
        city: [key],
      },
      price: minPrice + ',' + maxPrice,
      yearbuilt: `${year - 10}, ${year + 1}`,
    });

    let url =
      'https://www.yourwayhome.net/listing?keywordType=city&page=1&listingSource=all%20listings&zoom=10&page=1&condition=' +
      encodeURI(condition);

    // if (activeUser && activeUser.user_role_id == 3) {
    //   const response = await loftyLogin({ lofty_website_url: url });
    //   if (response.result) {
    //     window.open(response.data);
    //   } else window.open(url);
    // } else window.open(url);
    window.open(url);
  };

  return (
    <ButtonContainer>
      <Button onClick={handleViewHomesClick}>View New Homes</Button>
      <Button onClick={handleResaleClick}>Search for Resale Homes</Button>
    </ButtonContainer>
  );
};

export default ViewHomes;
