export const CommunityHighlightType = {
  Clubhouse: 'Clubhouse',
  CommunityCenter: 'Community Center',
  Greenbelt: 'Greenbelt',
  Playground: 'Playground',
  SoccerField: 'Soccer Field',
  TennisCourt: 'Tennis Court',
  Pool: 'Pool',
  Trails: 'Trails',
  Park: 'Park',
  BaseballField: 'Baseball Field',
  BasketballCourt: 'Basketball Court',
  Beach: 'Beach',
  HOA: 'HOA',
  Lake: 'Lake',
  Marina: 'Marina',
  Pond: 'Pond',
  Views: 'Views',
  Volleyball: 'Volleyball',
  WaterfrontLots: 'Waterfront Lots',
  GolfCourse: 'Golf Course',
};
