import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Go around the perimeter of the house and move all garbage cans, discarded wood scraps, extra building materials, etc., into the garage.',
  'Check gutters and/or roof for dry rot. Make sure they are swept and cleaned.',
  'Look at all plants...prune bushes and trees. Keep plants from blocking windows.',
];

const Section7Container = styled('section')(({ theme }) => ({
  width: '100%',
  paddingBottom: 96,
  padding: '80px 100px 0 100px',
  [theme.breakpoints.down('md')]: {
    padding: '80px 0 0 0',
  },
  '& div.header': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    textAlign: 'center',
    marginBottom: 32,
    padding: '0 32px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      color: '#04246B',
    },
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: 64,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '64px 0',
      paddingBottom: 0,
    },
    ul: {
      padding: 0,
      flexShrink: 0,
      flexBasis: '50%',
      maxWidth: 536,
      display: 'flex',
      gap: 24,
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      textAlign: 'center',
      width: '100%',
      img: {
        objectFit: 'cover',
        height: 336,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section7 = () => {
  return (
    <Section7Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <circle cx="50" cy="50" r="48.5" fill="white" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M70.5266 45.8949L48.6318 24L24 48.6318H29.4737V67.7898C29.4737 69.2415 30.0504 70.6338 31.0769 71.6603C32.1035 72.6868 33.4957 73.2635 34.9474 73.2635H48.6318"
            stroke="#CDA07D"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40.4219 73.264V56.8429C40.4219 55.3911 40.9986 53.9989 42.0251 52.9724C43.0516 51.9458 44.4439 51.3691 45.8956 51.3691H51.3693C52.2588 51.3691 53.0963 51.5799 53.838 51.9576M59.5799 76.0009C59.5799 76.0009 59.5799 70.5272 67.7905 65.0534"
            stroke="#CDA07D"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M67.7892 73.2639C65.6116 73.2639 63.5232 72.3989 61.9834 70.8591C60.4437 69.3193 59.5786 67.2309 59.5786 65.0534C59.5786 62.8758 60.4437 60.7874 61.9834 59.2476C63.5232 57.7078 65.6116 56.8428 67.7892 56.8428H75.9998V65.0534C75.9998 67.2309 75.1347 69.3193 73.595 70.8591C72.0552 72.3989 69.9668 73.2639 67.7892 73.2639Z"
            stroke="#CDA07D"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h2>Home Updates to make Outside</h2>
      </div>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=2lqKiXjuw7IwiKv85NbHg82pnnODF0q0&fmode=open" alt="" />
        </div>
      </div>
    </Section7Container>
  );
};

export default Section7;
