import { useState } from 'react';
import styled, { css } from 'styled-components';

const DescriptionText = styled.p`
  font-size: 1.0625rem !important;
  line-height: 1.75rem !important;

  ${(props) =>
    !props.showAll &&
    css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
`;

const ShowMoreButton = styled.span`
  font-size: 1.0625rem;
  line-height: 1.75rem;
  color: #4874c5;
  cursor: pointer;

  ${(props) => props.showAll && 'display: none;'}
`;

const Description = ({ description }) => {
  const [showAll, setShowAll] = useState(false);

  if (description.length < 200) {
    return <DescriptionText showAll={showAll}>{description}</DescriptionText>;
  }

  return (
    <div>
      <DescriptionText showAll={showAll}>{description}</DescriptionText>
      <ShowMoreButton showAll={showAll} onClick={() => setShowAll(true)}>
        continue reading
      </ShowMoreButton>
    </div>
  );
};

export default Description;
