import {
  BaseballFieldIcon,
  BasketballCourtIcon,
  BeachIcon,
  ClubhouseIcon,
  CommunityCenterIcon,
  GolfCourseIcon,
  GreenbeltIcon,
  HOAIcon,
  LakeIcon,
  MarinaIcon,
  ParkIcon,
  PlaygroundIcon,
  PondIcon,
  PoolIcon,
  SoccerFieldIcon,
  TennisCourtIcon,
  TrailIcon,
  ViewsIcon,
  VolleyballIcon,
  WaterfrontIcon,
} from '../../icons';
import { CommunityHighlightType } from '../../utils/constants';

const HighlightIcon = ({ highlights }) => {
  switch (highlights) {
    case CommunityHighlightType.Clubhouse:
      return <ClubhouseIcon />;
    case CommunityHighlightType.Greenbelt:
      return <GreenbeltIcon />;
    case CommunityHighlightType.Playground:
      return <PlaygroundIcon />;
    case CommunityHighlightType.SoccerField:
      return <SoccerFieldIcon />;
    case CommunityHighlightType.TennisCourt:
      return <TennisCourtIcon />;
    case CommunityHighlightType.Trails:
      return <TrailIcon />;
    case CommunityHighlightType.Beach:
      return <BeachIcon />;
    case CommunityHighlightType.GolfCourse:
      return <GolfCourseIcon />;
    case CommunityHighlightType.HOA:
      return <HOAIcon />;
    case CommunityHighlightType.Lake:
      return <LakeIcon />;
    case CommunityHighlightType.Marina:
      return <MarinaIcon />;
    case CommunityHighlightType.Park:
      return <ParkIcon />;
    case CommunityHighlightType.Pond:
      return <PondIcon />;
    case CommunityHighlightType.Pool:
      return <PoolIcon />;
    case CommunityHighlightType.BaseballField:
      return <BaseballFieldIcon />;
    case CommunityHighlightType.BasketballCourt:
      return <BasketballCourtIcon />;
    case CommunityHighlightType.Views:
      return <ViewsIcon />;
    case CommunityHighlightType.Volleyball:
      return <VolleyballIcon />;
    case CommunityHighlightType.WaterfrontLots:
      return <WaterfrontIcon />;
    default:
      return <CommunityCenterIcon />;
  }
};

export default HighlightIcon;
