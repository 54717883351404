import { styled } from '@mui/material';
import React from 'react';

const Section1Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 32,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: 32,
  [theme.breakpoints.down('md')]: {
    paddingBottom: 80,
  },
  '& div.header': {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    alignItems: 'center',
    maxWidth: 700,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 32,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '48px',
      color: '#04246B',
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  '& div.content': {
    padding: 64,
    maxWidth: 1000,
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      gap: 80,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
    '& div.image': {
      flexBasis: '50%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
        width: '100%',
      },
      img: {
        maxWidth: '100%',
        borderRadius: 16,
        height: 300,
        objectFit: 'cover',
        boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          borderRadius: 0,
        },
      },
    },
    '& div.subheader': {
      marginBottom: 40,
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      img: {
        height: 60,
        width: 60,
      },
      h6: {
        paddingLeft: 16,
        borderLeft: '2px solid #CDA07D',
      },
    },
    p: {
      flexBasis: '50%',
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        padding: '0 32px',
      },
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101" fill="none">
          <circle cx="51" cy="51" r="48.5" fill="white" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M70.0909 77.0233H31.9091C31.1858 77.0233 30.4921 76.7389 29.9806 76.2329C29.4692 75.7268 29.1818 75.0404 29.1818 74.3246V50.0372H21L49.1645 24.7026C49.6667 24.2505 50.3211 24 51 24C51.6789 24 52.3333 24.2505 52.8355 24.7026L81 50.0372H72.8182V74.3246C72.8182 75.0404 72.5308 75.7268 72.0194 76.2329C71.5079 76.7389 70.8142 77.0233 70.0909 77.0233ZM34.6364 71.626H67.3636V45.0663L51 30.3454L34.6364 45.0663V71.626ZM41.7027 57.6176C41.3572 56.1785 41.3572 54.6794 41.7027 53.2404L39 51.6968L41.7273 47.0228L44.43 48.5691C45.515 47.5516 46.8267 46.801 48.2591 46.3778V43.2906H53.7136V46.3778C55.1645 46.8069 56.4682 47.5652 57.5427 48.5691L60.2455 47.0228L62.9727 51.6968L60.27 53.2404C60.6156 54.6794 60.6156 56.1785 60.27 57.6176L62.9727 59.1612L60.2455 63.8325L57.5427 62.2888C56.4575 63.3054 55.1458 64.0551 53.7136 64.4774V67.5673H48.2591V64.4747C46.827 64.0523 45.5153 63.3026 44.43 62.2861L41.7273 63.8325L39 59.1585L41.7027 57.6149V57.6176ZM50.9864 59.4742C51.5358 59.4941 52.0836 59.4041 52.5972 59.2098C53.1107 59.0154 53.5793 58.7207 53.9751 58.3431C54.3709 57.9655 54.6857 57.5128 54.9008 57.0121C55.1158 56.5114 55.2266 55.973 55.2266 55.429C55.2266 54.885 55.1158 54.3465 54.9008 53.8458C54.6857 53.3451 54.3709 52.8925 53.9751 52.5149C53.5793 52.1373 53.1107 51.8425 52.5972 51.6482C52.0836 51.4538 51.5358 51.3639 50.9864 51.3838C49.928 51.422 48.9258 51.865 48.1907 52.6194C47.4556 53.3739 47.0448 54.381 47.0448 55.429C47.0448 56.477 47.4556 57.4841 48.1907 58.2386C48.9258 58.993 49.928 59.4359 50.9864 59.4742Z"
            fill="#CDA07D"
          />
        </svg>
        <h2>Our Custom Styles</h2>
        <h4>
          We draw inspiration from icons of American architectural style and from those classic roots we work to craft
          an ideal home to function in perfect harmony with your lifestyle.
        </h4>
      </div>
      <div className="content">
        <div className="image">
          <img
            className="shadow-left"
            src="https://bucket.ywhdev.com/wl/?id=pC7TZGYRvisHfOGw0GAdb9uSWCIVEjfZ&fmode=open"
            alt="showing a man shake hands with a woman."
          />
        </div>
        <p>
          If you see a house you like or have another style or set of plans you love, come talk to our in-house design
          team. We can take any style or plan and adjust it to your specific need or budget. You can even walk through
          your dream home digitally before it is built. We take the risk out of designing to build and guarantee a price
          before construction ever begins.
        </p>
      </div>
    </Section1Container>
  );
};

export default Section1;
