import { styled } from '@mui/material';

const Section2Container = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  margin: '0 auto',
  marginBottom: 64,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '64px',
  paddingTop: 0,
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 64,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
  },
  '& div.content': {
    maxWidth: 626,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      order: 2,
      maxWidth: 800,
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
    '& div.header': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 64,
      [theme.breakpoints.down('lg')]: {},
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      order: 1,
    },
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 648,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('lg')]: {
        height: 500,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
}));

const Section2 = () => {
  return (
    <Section2Container>
      <div className="content">
        <div className="header">
          <h2>
            Also called the settlement, the closing is the process of passing ownership of a property from the seller to
            the buyer.
          </h2>
          <p>
            And it can be bewildering. As a buyer, you will sign what seems like endless piles of documents and will
            have to present a sizeable check for the down payment and various closing costs. It's the fees associated
            with the closing that many times remains a mystery to many buyers who may simply hand over thousands of
            dollars without really knowing what they are paying for.
            <br />
            <br />
            As a responsible buyer, you should be familiar with these costs that are both mortgage-related and
            government-imposed. Make sure you consult a real estate professional in your area to find out which
            fees--and how much--you will be expected to pay during the closing of your prospective home. Keep in mind
            that you can negotiate these costs with the seller during the offering stage. In some instances, the seller
            might even agree to pay all of the settlement costs.
          </p>
        </div>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=AwnjLzTTfmVfYegSxRbel6gzVQne7Y9G&fmode=open"
          alt="showing two women having a conversation in a sofa with laptop and papers on the table."
        />
      </div>
    </Section2Container>
  );
};

export default Section2;
