import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import { useState } from 'react';
import { CF_URL } from '../../../../constants/common';
import { Divider } from '../AgentLoanOfficer/AgentLoanOfficer';

const Section = styled.section`
  margin-top: 7.5rem;
`;

// const Divider = styled.img`
//   height: 99px;
//   width: 100%;
//   display: none;

//   @media (min-width: 993px) {
//     display: block;
//   }
// `;

const BuilderContainer = styled.div`
  background-color: #f7f7f7;
  padding: 5rem 4rem 7.5rem 4rem;

  @media (min-width: 993px) {
    padding: 5rem 32px 7.5rem 32px;
  }
`;

const BuilderDetailsContainer = styled.div`
  margin: 0 auto;
  max-width: 1280px;

  & .details {
    margin-bottom: ${(props) => (!props['margin-bottom'] ? '0' : '120px')};
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 5rem;

    & img {
      margin: 0 auto;
      height: 200px;
      width: 250px;
      border-radius: 0.5rem;
    }

    & p {
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      color: #404041;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2.25rem;
    }
  }
`;

const BuilderDescriptionContainer = styled.div`
  margin: 0 auto;
  flex-direction: column;
  display: flex;

  @media (min-width: 993px) {
    max-width: 1280px;
    flex-direction: row;
    gap: 8px;
  }

  & p {
    color: #404041;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;
    order: 2;

    @media (min-width: 993px) {
      order: 1;
      max-width: calc(50% - 20px);
    }
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    order: 1;

    @media (min-width: 993px) {
      order: 2;
      max-width: calc(50% - 20px);
    }
  }
`;

const Builder = ({ builder }) => {
  const [showImage, setShowImage] = useState(true);

  return (
    <Section>
      <Divider xmlns="http://www.w3.org/2000/svg" width="1500" height="99" viewBox="0 0 1500 99" fill="none">
  <path d="M393 0.272461L0 93.2725V99.2725H1500V93.6725L393 0.272461Z" fill="#F7F7F7"/>
</Divider>
      <BuilderContainer>
        <BuilderDetailsContainer>
          <SectionTitle title="Builder Details" backgroundTitle="Builder" />
          <div className="details" margin-bottom={builder?.builder_image && showImage}>
            {builder?.builder_image && showImage && (
              <img
                src={builder.builder_image}
                alt={builder.name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  setShowImage(false);
                }}
              />
            )}
            {builder?.builder_highlight_details && <p>{builder.builder_highlight_details}</p>}
          </div>
          <BuilderDescriptionContainer>
            { builder?.description &&<p style={{maxWidth: `${builder?.builder_content_image ? 'calc(50% - 20px)' : '100%'}`}}>{builder?.description}</p> }
            { builder?.builder_content_image && <img src={builder?.builder_content_image} alt="" /> }
          </BuilderDescriptionContainer>
        </BuilderDetailsContainer>
      </BuilderContainer>
    </Section>
  );
};

export default Builder;
