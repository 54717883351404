import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Keep windows and floors clean',
  'Replaced faded wallpaper',
  'Repair worn woodword',
  'Repaint scarred or dirty walls',
  'Steam clean carpet or replace',
  'Repair loose knobs, sticking doors & windows, warped cabinet drawers, broken light switches, and other minor flaws.',
  'Check & repair caulking in bathtubs/showers',
];

const Section2Container = styled('section')(({ theme }) => ({
  width: '100%',
  padding: 100,
  paddingBottom: 0,
  backgroundColor: '#F4F4F6',
  [theme.breakpoints.down('md')]: {
    padding: '80px 0',
  },
  '& div.header': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    textAlign: 'center',
    marginBottom: 32,
    padding: '0 32px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      color: '#04246B',
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
      },
    },
    h5: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: 64,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '64px 0 0 0',
    },
    ul: {
      order: 2,
      maxWidth: 536,
      display: 'flex',
      gap: 24,
      padding: 0,
      flexShrink: 0,
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        order: 1,
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      order: 1,
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        order: 2,
      },
      img: {
        objectFit: 'cover',
        height: 496,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 400,
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section2 = () => {
  return (
    <Section2Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="107" viewBox="0 0 106 107" fill="none">
          <circle cx="56" cy="57" r="48.5" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M47.9722 78.918V63.9454C47.9722 62.6218 48.498 61.3523 49.434 60.4164C50.3699 59.4804 51.6394 58.9546 52.963 58.9546H56.9557"
            stroke="#CDA07D"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M75.4222 53.9634L55.4588 34L33 56.4588H37.9908V73.9268C37.9908 75.2505 38.5167 76.5199 39.4526 77.4559C40.3886 78.3918 41.658 78.9176 42.9817 78.9176H53.3602M70.4314 76.4222C69.1077 76.4222 67.8383 75.8964 66.9023 74.9604C65.9663 74.0245 65.4405 72.755 65.4405 71.4314C65.4405 70.1077 65.9663 68.8383 66.9023 67.9023C67.8383 66.9663 69.1077 66.4405 70.4314 66.4405M70.4314 76.4222C71.755 76.4222 73.0245 75.8964 73.9604 74.9604C74.8964 74.0245 75.4222 72.755 75.4222 71.4314C75.4222 70.1077 74.8964 68.8383 73.9604 67.9023C73.0245 66.9663 71.755 66.4405 70.4314 66.4405M70.4314 76.4222V80.1654M70.4314 66.4405V62.6974M77.9975 67.0644L74.7559 68.9359M66.1143 73.9268L62.8702 75.7984M62.8702 67.0644L66.1143 68.9359M74.7559 73.9268L78 75.7984"
            stroke="#CDA07D"
            stroke-width="9"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h2>Accentuate the Positive</h2>
        <h5>How we live in a home and how we sell a home are oftern two different things.</h5>
      </div>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=YFOxdc1je8LnuUz9BgoqgKACmrvjZ1kB&fmode=open" alt="" />
        </div>
      </div>
    </Section2Container>
  );
};

export default Section2;
