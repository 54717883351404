import { Alert, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { memo, useContext, useState } from 'react';
import { FaFacebookF, FaGoogle } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { Loader } from '../../components/loader';
import { MainContext } from '../../context';
import { loginChime } from '../../services/admin';
import { setAccessToken, setUserType } from '../../utils/common';
import routes from '../../routes';

export const LoginFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: 1000,
  margin: '0 auto',
  padding: '160px 0px',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    maxWidth: 600,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '64px 24px',
  },
}));

export const LoginFormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 872,
  height: 600,
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '16px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: 600,
    height: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const LoginFormForm = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  background: '#edf2f4',
  overflow: 'hidden',
  flex: '1 0 0',
  alignSelf: 'stretch',
  [theme.breakpoints.down('md')]: {
    overflow: 'visible',
    width: 600,
    flex: '1',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const LoginFormContents = styled('form')({
  marginTop: 41,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  padding: '0 61px',
  flexShrink: '1',
  // box-sizing: border-box,
  width: '100%',
});

export const LoginFormFields = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 8,
});

export const LoginFormFieldWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '400px',
  height: '45px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '8px',
  border: '1px solid #a8a8a8',
  background: '#fff',

  '& input': {
    color: '#a8a8a8',

    /* ywh-caption */
    fontFamily: 'Montserrat',
    fontSize: '17px',
    fontWeight: '400',
    lineHeight: '27.54px' /* 162% */,
    width: '100%',
    border: 'none',
    background: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const LoginFormIcon = styled('div')({
  display: 'flex',
  paddingRight: '16px',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRight: '1px solid #a8a8a8',
});

export const LoginFormImage = styled('img')(({ theme }) => ({
  width: 350,
  flexShrink: 0,
  alignSelf: 'stretch',
  background: 'url(<path-to-image>), lightgray 50% / cover no-repeat',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 300,
  },
}));

export const LoginFormFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 40,
  alignSelf: 'stretch',
  paddingBottom: 32,
}));

export const LoginFormFooterCol = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
  alignSelf: 'stretch',
  '& button': {
    padding: '16px 32px',
    borderRadius: 8,
    background: '#04246b',
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 19,
    fontWeight: 500,
    lineHeight: '19px',
  },
  '& a': {
    color: '#04246b !important',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    lineHeight: '27.54px',
  },
  '&.user-login-form': {
    flexDirection: 'column',
    [theme.breakpoints.up(540)]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  }
}));

export const LoginFormHeading = styled('div')({
  display: 'flex',
  padding: '32px 0',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  alignSelf: 'stretch',
  '& h3': {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 25,
    fontWeight: 400,
    lineHeight: '25px',
  },
  '& h2': {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Playfair Display',
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '39px',
    '& span': {
      color: '#cda07d',
      fontFamily: 'Playfair Display',
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '39px',
    },
  },
  borderBottom: '1px solid #d8d8d8',
  boxShadow: '0px 2px 4px 0px #d8d8d8',
});

export const LoginFormFooterTerms = styled('p')({
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Montserrat',
  fontSize: '13px !important',
  fontWeight: '400 !important',
  lineHeight: '21.06px !important',
  width: '100%',
  '& a': {
    color: '#04246b !important',
    fontFamily: 'Montserrat',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21.06px',
  },
});

const SSOContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
}));

const GoogleLogin = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  justifyContent: 'center',
  backgroundColor: 'white',
  padding: 8,
  width: '100%',
  color: '#232323',
  fontWeight: 600,
  fontSize: 14,
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const FacebookLogin = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  justifyContent: 'center',
  backgroundColor: '#3e5a97',
  padding: 8,
  width: '100%',
  fontSize: 14,
  fontWeight: 600,
  '&:hover': {
    backgroundColor: '#3e5a97',
  },
}));

const Login = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  console.log({ location, win: window.location });

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setIsLoggedIn, loading, setLoading, setActiveUser } = useContext(MainContext);
  const [error, setError] = useState(false);

  const handleUsernameChange = (event) => {
    setError(false);
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setError(false);
    setPassword(event.target.value);
  };

  const loginUser = async (username, password, google_id = null, facebook_id = null) => {
    let params = {
      email: username,
      is_chime_user: true,
    };

    if (password) {
      params.password = password;
    }

    if (google_id) {
      params.google_id = google_id;
    }

    if (facebook_id) {
      params.facebook_id = facebook_id;
    }
    setLoading(true);
    try {
      let result = await loginChime(params);
      setLoading(false);
      if (result.token) {
        setAccessToken(result);
        setIsLoggedIn(true);
        setUserType(result.user.user_role_id);
        setActiveUser(result.user);
        navigate(routes.userDashboard());
      } else {
        setError('Invalid username / password.');
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    loginUser(username, password);
  };

  return (
    <LoginFormContainer>
      <LoginFormWrapper>
        <LoginFormImage src={mediumScreen ? '/login-form__image-md.webp' : '/login-form__image.webp'} />
        <LoginFormForm>
          <LoginFormHeading>
            <h3>Welcome to</h3>
            <h2>
              YOUR<span>WAY</span>HOME
            </h2>
          </LoginFormHeading>
          <LoginFormContents onSubmit={handleSubmit}>
            <LoginFormFields>
              <LoginFormFieldWrapper>
                <LoginFormIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                    <path
                      d="M10.17 24.5C10.0569 24.1787 9.99944 23.8406 10 23.5V16.5C10 15.732 10.289 15.03 10.764 14.5H3C2.20435 14.5 1.44129 14.8161 0.87868 15.3787C0.316071 15.9413 0 16.7044 0 17.5V18.215C0 21.933 4.21 24.5 10 24.5H10.17ZM16 6.5C16 4.9087 15.3679 3.38258 14.2426 2.25736C13.1174 1.13214 11.5913 0.5 10 0.5C8.4087 0.5 6.88258 1.13214 5.75736 2.25736C4.63214 3.38258 4 4.9087 4 6.5C4 8.0913 4.63214 9.61742 5.75736 10.7426C6.88258 11.8679 8.4087 12.5 10 12.5C11.5913 12.5 13.1174 11.8679 14.2426 10.7426C15.3679 9.61742 16 8.0913 16 6.5ZM13.5 14.5C12.837 14.5 12.2011 14.7634 11.7322 15.2322C11.2634 15.7011 11 16.337 11 17V17.191L17.5 20.441L24 17.191V17C24 16.337 23.7366 15.7011 23.2678 15.2322C22.7989 14.7634 22.163 14.5 21.5 14.5H13.5ZM24 18.309L17.724 21.447C17.6545 21.4818 17.5778 21.5 17.5 21.5C17.4222 21.5 17.3455 21.4818 17.276 21.447L11 18.31V23C11 23.663 11.2634 24.2989 11.7322 24.7678C12.2011 25.2366 12.837 25.5 13.5 25.5H21.5C22.163 25.5 22.7989 25.2366 23.2678 24.7678C23.7366 24.2989 24 23.663 24 23V18.309Z"
                      fill="#A8A8A8"
                    />
                  </svg>
                </LoginFormIcon>
                <input type="text" name="email" required placeholder="Email" onChange={handleUsernameChange} />
              </LoginFormFieldWrapper>
              <LoginFormFieldWrapper>
                <LoginFormIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                    <path
                      d="M10.17 24.5C10.0569 24.1787 9.99944 23.8406 10 23.5V16.5C10 15.732 10.289 15.03 10.764 14.5H3C2.20435 14.5 1.44129 14.8161 0.87868 15.3787C0.316071 15.9413 0 16.7044 0 17.5V18.215C0 21.933 4.21 24.5 10 24.5H10.17ZM16 6.5C16 4.9087 15.3679 3.38258 14.2426 2.25736C13.1174 1.13214 11.5913 0.5 10 0.5C8.4087 0.5 6.88258 1.13214 5.75736 2.25736C4.63214 3.38258 4 4.9087 4 6.5C4 8.0913 4.63214 9.61742 5.75736 10.7426C6.88258 11.8679 8.4087 12.5 10 12.5C11.5913 12.5 13.1174 11.8679 14.2426 10.7426C15.3679 9.61742 16 8.0913 16 6.5ZM13.5 14.5C12.837 14.5 12.2011 14.7634 11.7322 15.2322C11.2634 15.7011 11 16.337 11 17V17.191L17.5 20.441L24 17.191V17C24 16.337 23.7366 15.7011 23.2678 15.2322C22.7989 14.7634 22.163 14.5 21.5 14.5H13.5ZM24 18.309L17.724 21.447C17.6545 21.4818 17.5778 21.5 17.5 21.5C17.4222 21.5 17.3455 21.4818 17.276 21.447L11 18.31V23C11 23.663 11.2634 24.2989 11.7322 24.7678C12.2011 25.2366 12.837 25.5 13.5 25.5H21.5C22.163 25.5 22.7989 25.2366 23.2678 24.7678C23.7366 24.2989 24 23.663 24 23V18.309Z"
                      fill="#A8A8A8"
                    />
                  </svg>
                </LoginFormIcon>
                <input type="text" name="number" required placeholder="Phone Number" onChange={handlePasswordChange} />
              </LoginFormFieldWrapper>
              {/* <InputPassword name="password" onChange={handlePasswordChange} /> */}
            </LoginFormFields>
            <LoginFormFooter>
              {error && <Alert severity="error">{error} </Alert>}
              <LoginFormFooterCol className="user-login-form">
                {loading ? <Loader /> : <button type="submit">SIGN IN</button>}
                <Link style={{fontSize: 19}} to={routes.userRegister()}>REGISTER</Link>
              </LoginFormFooterCol>
              <SSOContainer>
                <LoginSocialGoogle
                  scope="https://www.googleapis.com/auth/userinfo.email"
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onResolve={({ _, data }) => {
                    loginUser(data.email, null, data.sub);
                  }}
                  onReject={console.log}
                >
                  <GoogleLogin type="button">
                    <FaGoogle />
                    Login with Google
                  </GoogleLogin>
                </LoginSocialGoogle>
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onResolve={console.log}
                  onReject={console.log}
                >
                  <FacebookLogin type="button">
                    <FaFacebookF />
                    Login with Facebook
                  </FacebookLogin>
                </LoginSocialFacebook>
              </SSOContainer>
              <LoginFormFooterTerms>
                By signing up, you agree to YourWayHome's <br />
                <Link>Terms and Conditions</Link> & <Link>Privacy Policy</Link>.
              </LoginFormFooterTerms>
            </LoginFormFooter>
          </LoginFormContents>
        </LoginFormForm>
      </LoginFormWrapper>
    </LoginFormContainer>
  );
};

export default memo(Login);
