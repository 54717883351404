import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Open draperies and curtains to let the light in during the showing.',
  'Remove all unnecessary clutter from your attic, basement and closets to better display spacious rooms (consider storage or a garage sale to dispose of extraneous items).',
  'Arrange all your rooms neatly and remove excess furniture. Keep fresh, clean towels in the bathroom. Use candles or air fresheners to make the room smell pleasant.',
];

const Section3Container = styled('section')(({ theme }) => ({
  width: '100%',
  paddingBottom: 96,
  padding: 100,
  backgroundColor: '#F4F4F6',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  '& div.header': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    textAlign: 'center',
    marginBottom: 80,
    padding: '0 32px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      color: '#04246B',
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
      },
    },
    h5: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: 64,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '64px 0 0 0',
    },
    ul: {
      maxWidth: 536,
      display: 'flex',
      gap: 24,
      padding: 0,
      flexShrink: 0,
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      width: '100%',
      textAlign: 'center',
      img: {
        objectFit: 'cover',
        height: 432,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 400,
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section3 = () => {
  return (
    <Section3Container>
      <div className="header" style={{ marginBottom: 48 }}>
        <h2>
          Try to see your home with a fresh perspective and arrange each room to bring out its best attributes,
          including:
        </h2>
      </div>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=4HCd3TbyTDXPJFvwB4ZZRKqWyLD3t8Qx&fmode=open" alt="" />
        </div>
      </div>
    </Section3Container>
  );
};

export default Section3;
