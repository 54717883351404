import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  display: flex;
  padding: 1rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #eaeaea;
  background: #fcfcfc;
  font-size: 1rem;
  line-height: 1.5rem;

  &:focus {
    outline: none;
  }
`;

const Textarea = (props) => {
  return <StyledTextarea {...props} rows={5} />;
};

export default Textarea;
