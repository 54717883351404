import { styled } from '@mui/material';

const Section2Container = styled('section')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 1500,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '48px 64px',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '48px 0 0 0',
    gap: 64,
  },
  '& div.content': {
    order: 2,
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      width: 526,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      order: 1,
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      maxWidth: 526,
      marginBottom: 32,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      maxWidth: 526,
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    order: 1,
    textAlign: 'center',
    flexBasis: '50%',
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
    img: {
      maxWidth: 526,
      borderRadius: 16,
      height: 756,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('xl')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: 0,
        height: 400,
      },
    },
  },
}));

const Section2 = () => {
  return (
    <div style={{ backgroundColor: '#F4F4F6' }}>
      <Section2Container>
        <div className="content">
          <div className="header">
            <img src="https://bucket.ywhdev.com/wl/?id=Beo2cpwwZVOuzQWeGy3DPT8JrAYoFI1D&fmode=open" alt="" />
            <h2>On the Internet</h2>
          </div>
          <p style={{ marginBottom: 40 }}>
            The Internet has revolutionized the real estate landscape, making property listing distribution to major
            websites a game-changer in securing a sale. When your listing is featured on top real estate platforms, it
            gains unparalleled visibility, accessing a vast pool of potential buyers both locally and globally. These
            platforms optimize listings with search algorithms, ensuring that your property appears in search results
            for users with preferences matching your home's features.
          </p>
          <p>
            Moreover, with the proliferation of mobile devices, prospective buyers can now view listings on-the-go,
            anytime and anywhere, amplifying the chances of generating genuine interest. By leveraging the
            interconnected web of major real estate websites, sellers benefit from exponential reach, continuous
            availability, and a streamlined process, drastically enhancing the speed and efficiency of selling a home in
            today's digital age.
          </p>
        </div>
        <div className="image">
          <img
            className="shadow-left"
            src="https://bucket.ywhdev.com/wl/?id=zVgpjNLlvXRj6GIANplqDXzgoMGEUzXW&fmode=open"
            alt="showing a beautiful 2-storey house."
          />
        </div>
      </Section2Container>
    </div>
  );
};

export default Section2;
