import { styled } from '@mui/material';

const CARD_ITEMS = [
  {
    id: 1,
    title: 'Most of buyer activity will occur in the first few weeks.',
    content:
      'Pricing a home properly and then creating immediate urgency in the minds of agents and buyers is critical.',
  },
  {
    id: 2,
    title: 'Buyers are waiting for the "right house" on the market.',
    content:
      "Most buyers have seen the available homes in their price range and are waiting for the perfect house. That's why if a house is priced right, it will sell quickly.",
  },
  {
    id: 3,
    title: "Don't start with a high price just to lower it.",
    content: 'By the time you decide to lower the price, it me be too late, as interest will have already waned.',
  },
  {
    id: 4,
    title: 'A major cause of concern is appraisal problems.',
    content: 'Overpricing can lead to loan rejections and lost time.',
  },
  {
    id: 5,
    title: "Looks alone won't get buyers inside.",
    content:
      "Even if your home is nicer than other homes in the same area, your house won't be picked for viewing if you set the price too high.",
  },
  {
    id: 6,
    title: 'Long exposure period works against the seller.',
    content:
      'Buyers and agents become aware of the long exposure period and often are hesitant to make an offer because they fear something is wrong with the property.',
  },
  // {
  //   id: 7,
  //   title: 'Most of buyer activity will occur in the first few weeks.',
  //   content:
  //     'Pricing a home properly and then creating immediate urgency in the minds of agents and buyers is critical.',
  // },
  // {
  //   id: 8,
  //   title: 'Most of buyer activity will occur in the first few weeks.',
  //   content:
  //     'Pricing a home properly and then creating immediate urgency in the minds of agents and buyers is critical.',
  // },
];

const Section4Container = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  margin: '0 auto',
  marginBottom: 64,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '64px',
  paddingTop: 0,
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    gap: 64,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  '& div.content': {
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 672,
      margin: '0 auto',
    },
    '& div.header': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
    },
  },
}));

const Section4CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 64,
  flexFlow: 'row wrap',
  [theme.breakpoints.down('lg')]: {
    padding: 32,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const Section4Card = styled('div')(({ theme }) => ({
  border: '2px solid #DEDEE0',
  borderRadius: 16,
  width: 'calc(50% - 32px)',
  display: 'flex',
  padding: 32,
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  gap: 16,
  div: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
    h4: {
      maxWidth: 'calc(100% - 64px - 16px)',
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
  },
  p: {
    fontSize: 18,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
}));

const Section4 = () => {
  return (
    <Section4Container>
      <div className="content">
        <div className="header">
          <img
            className="shadow-left"
            src="https://bucket.ywhdev.com/wl/?id=Sb50wLZcarVG5Iq2xc745BYPNLYJdWnm&fmode=open"
            alt=""
          />
          <h2>Dangers of overpricing</h2>
        </div>
      </div>
      <Section4CardContainer>
        {CARD_ITEMS.map((item) => (
          <Section4Card key={item.id}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.1363 3.8793C28.238 -1.30352 35.758 -1.30352 38.8637 3.8793L62.8488 43.889C66.0465 49.2239 62.2044 56.0076 55.981 56.0076H8.01898C1.7956 56.0076 -2.04649 49.2239 1.15125 43.889L25.1363 3.8793ZM32 15.9859C32.7961 15.9859 33.5596 16.3021 34.1225 16.865C34.6854 17.4279 35.0016 18.1914 35.0016 18.9875V30.994C35.0016 31.7901 34.6854 32.5536 34.1225 33.1165C33.5596 33.6794 32.7961 33.9957 32 33.9957C31.2039 33.9957 30.4404 33.6794 29.8775 33.1165C29.3146 32.5536 28.9984 31.7901 28.9984 30.994V18.9875C28.9984 18.1914 29.3146 17.4279 29.8775 16.865C30.4404 16.3021 31.2039 15.9859 32 15.9859ZM36.0022 42C36.0022 43.0614 35.5805 44.0794 34.83 44.83C34.0794 45.5805 33.0614 46.0022 32 46.0022C30.9386 46.0022 29.9206 45.5805 29.17 44.83C28.4195 44.0794 27.9978 43.0614 27.9978 42C27.9978 40.9386 28.4195 39.9206 29.17 39.17C29.9206 38.4195 30.9386 37.9978 32 37.9978C33.0614 37.9978 34.0794 38.4195 34.83 39.17C35.5805 39.9206 36.0022 40.9386 36.0022 42Z"
                  fill="#5284E0"
                />
              </svg>
              <h4>{item.title}</h4>
            </div>
            <p>{item.content}</p>
          </Section4Card>
        ))}
        <Section4Card>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1363 3.8793C28.238 -1.30352 35.758 -1.30352 38.8637 3.8793L62.8488 43.889C66.0465 49.2239 62.2044 56.0076 55.981 56.0076H8.01898C1.7956 56.0076 -2.04649 49.2239 1.15125 43.889L25.1363 3.8793ZM32 15.9859C32.7961 15.9859 33.5596 16.3021 34.1225 16.865C34.6854 17.4279 35.0016 18.1914 35.0016 18.9875V30.994C35.0016 31.7901 34.6854 32.5536 34.1225 33.1165C33.5596 33.6794 32.7961 33.9957 32 33.9957C31.2039 33.9957 30.4404 33.6794 29.8775 33.1165C29.3146 32.5536 28.9984 31.7901 28.9984 30.994V18.9875C28.9984 18.1914 29.3146 17.4279 29.8775 16.865C30.4404 16.3021 31.2039 15.9859 32 15.9859ZM36.0022 42C36.0022 43.0614 35.5805 44.0794 34.83 44.83C34.0794 45.5805 33.0614 46.0022 32 46.0022C30.9386 46.0022 29.9206 45.5805 29.17 44.83C28.4195 44.0794 27.9978 43.0614 27.9978 42C27.9978 40.9386 28.4195 39.9206 29.17 39.17C29.9206 38.4195 30.9386 37.9978 32 37.9978C33.0614 37.9978 34.0794 38.4195 34.83 39.17C35.5805 39.9206 36.0022 40.9386 36.0022 42Z"
                fill="#5284E0"
              />
            </svg>
            <h4>Attracting the wrong buyers.</h4>
          </div>
          <div style={{ marginTop: 32 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1363 3.8793C28.238 -1.30352 35.758 -1.30352 38.8637 3.8793L62.8488 43.889C66.0465 49.2239 62.2044 56.0076 55.981 56.0076H8.01898C1.7956 56.0076 -2.04649 49.2239 1.15125 43.889L25.1363 3.8793ZM32 15.9859C32.7961 15.9859 33.5596 16.3021 34.1225 16.865C34.6854 17.4279 35.0016 18.1914 35.0016 18.9875V30.994C35.0016 31.7901 34.6854 32.5536 34.1225 33.1165C33.5596 33.6794 32.7961 33.9957 32 33.9957C31.2039 33.9957 30.4404 33.6794 29.8775 33.1165C29.3146 32.5536 28.9984 31.7901 28.9984 30.994V18.9875C28.9984 18.1914 29.3146 17.4279 29.8775 16.865C30.4404 16.3021 31.2039 15.9859 32 15.9859ZM36.0022 42C36.0022 43.0614 35.5805 44.0794 34.83 44.83C34.0794 45.5805 33.0614 46.0022 32 46.0022C30.9386 46.0022 29.9206 45.5805 29.17 44.83C28.4195 44.0794 27.9978 43.0614 27.9978 42C27.9978 40.9386 28.4195 39.9206 29.17 39.17C29.9206 38.4195 30.9386 37.9978 32 37.9978C33.0614 37.9978 34.0794 38.4195 34.83 39.17C35.5805 39.9206 36.0022 40.9386 36.0022 42Z"
                fill="#5284E0"
              />
            </svg>
            <h4>Fewer potentially qualified buyers will respond.</h4>
          </div>
        </Section4Card>
        <Section4Card>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1363 3.8793C28.238 -1.30352 35.758 -1.30352 38.8637 3.8793L62.8488 43.889C66.0465 49.2239 62.2044 56.0076 55.981 56.0076H8.01898C1.7956 56.0076 -2.04649 49.2239 1.15125 43.889L25.1363 3.8793ZM32 15.9859C32.7961 15.9859 33.5596 16.3021 34.1225 16.865C34.6854 17.4279 35.0016 18.1914 35.0016 18.9875V30.994C35.0016 31.7901 34.6854 32.5536 34.1225 33.1165C33.5596 33.6794 32.7961 33.9957 32 33.9957C31.2039 33.9957 30.4404 33.6794 29.8775 33.1165C29.3146 32.5536 28.9984 31.7901 28.9984 30.994V18.9875C28.9984 18.1914 29.3146 17.4279 29.8775 16.865C30.4404 16.3021 31.2039 15.9859 32 15.9859ZM36.0022 42C36.0022 43.0614 35.5805 44.0794 34.83 44.83C34.0794 45.5805 33.0614 46.0022 32 46.0022C30.9386 46.0022 29.9206 45.5805 29.17 44.83C28.4195 44.0794 27.9978 43.0614 27.9978 42C27.9978 40.9386 28.4195 39.9206 29.17 39.17C29.9206 38.4195 30.9386 37.9978 32 37.9978C33.0614 37.9978 34.0794 38.4195 34.83 39.17C35.5805 39.9206 36.0022 40.9386 36.0022 42Z"
                fill="#5284E0"
              />
            </svg>
            <h4>You could lose money as you sit and wait.</h4>
          </div>
          <p>
            You could end making extra mortgage payments while incurring taxes, insurance and unplanned maintenance
            costs.
          </p>
        </Section4Card>
      </Section4CardContainer>
    </Section4Container>
  );
};

export default Section4;
