import { checkIsLoggedIn, getUserType } from '../../../utils/common';
import { useNavigate } from "react-router-dom";
import routes from '../../../routes';

import AuthChecker from '../AuthChecker/AuthChecker';

const AdminChecker = () => {
    const navigate = useNavigate();

    const CheckAdmin = () => {
        if (getUserType() !== '2') {
            navigate(routes.http401());
        }
    }

    return (
        <>
            <AuthChecker />
            <CheckAdmin/>
        </>
    );
};

export default AdminChecker;