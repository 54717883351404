import { memo, useEffect, useCallback, useState, useRef, useContext } from 'react';
import { addLoanOfficer } from '../../../services/admin';
import { useNavigate } from "react-router-dom";
import { checkIsLoggedIn } from '../../../utils/common';
import routes from '../../../routes';

import AddOfficer from '../addOfficer';
import { ToastContainer, toast } from 'react-toastify';
import { MainContext } from '../../../context';
import { StaticNavigation } from '../layout';
import AuthChecker from '../AuthChecker/AuthChecker';

const CreateLoanOfficer = () => {
    const navigate = useNavigate();

    const toastId = useRef(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {setActivePage, activeOfficer} = useContext(MainContext)
    const [fieldErrors, setFieldErrors] = useState({})

    const handleCreate = async (data) => {
        try {
            toastId.current = toast.loading("Submitting", {
                onOpen: () => setIsSubmitting(true)
            })
            
            if(data.headshot) {
                delete data.headshot
            }

            let result = await addLoanOfficer(data);
            if (result.result) {
                toast.update(toastId.current, {
                    render: 'Loan officer successfully created',
                    type: 'success',
                    autoClose: 2000,
                    closeOnClick: true,
                    isLoading: false,
                    onClose: () => {
                        setIsSubmitting(false)
                        if (checkIsLoggedIn()) {
                           setActivePage('loan-officers')
                            navigate('/admin/loan-officers');
                        }
                        else {
                            navigate(routes.login());
                        }
                    }
                
                })
                
            } else {
                toast.update(toastId.current, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 2000,
                    onClose: () =>  setIsSubmitting(false)
                })
                if(result.errors) {
                    let errorsObject = {}
                    result.errors.forEach(e => {
                        errorsObject[e.path] = e.msg
                    })
                    setFieldErrors(errorsObject)
                
                }
            }
        } catch(e) {
            toast.update(toastId.current, {
                render: e?.response?.data?.message ?? 'Something went wrong.',
                type: 'error',
                isLoading: false,
                closeOnClick: true,
                autoClose: 2000,
                onClose: () =>  setIsSubmitting(false)
            })
        }

    }

    const handleSubmit = useCallback(
        async (values)  => {
            delete values.assigned_agents.communities
            const data = {
                ...values,
                state_licenses: values.state_licenses.map(a => a.value).join(','),
                assigned_agents: JSON.stringify(values.assigned_agents),
            }

            handleCreate(data)
        }
    )

    const handleSave = () => {
        handleSubmit(activeOfficer)
    }

    useEffect(() => setActivePage('add-loan-officer'), [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave?';
            event.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <>
         <AuthChecker />
         <StaticNavigation page="add-loan-officer" handleSave={handleSave}/>
         <AddOfficer handleSubmit={handleSubmit} isSubmitting={isSubmitting}  fieldErrors={fieldErrors}/>
        <ToastContainer />
        </>
    );
};


export default CreateLoanOfficer;