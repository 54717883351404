import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../components/loader';
import { MainContext } from '../context';
import { getPublicAgent, getPublicAgentBySubdomain } from '../services/general';
import { AgentListWrapper, AgentProfileWrapper } from './Admin/AgentProfile/AgentProfile';
import Agent from './Admin/agent';
import getSubdomain from '../utils/getSubdomain';


const NotFound = styled.h1`
  text-align: center;
  margin-top: 4rem !important;
`;

const PublicAgent = () => {
  let { agent_id } = useParams();
  const [agent, setAgent] = useState(false);
  const { loading, setLoading, setActiveAgent } = useContext(MainContext);

  const navigate = useNavigate();
 
  const fetchAgent = async () => {
    setLoading(true);

    const subdomain = getSubdomain();

    try {
      let data = null;
      if(subdomain || isNaN(agent_id)) {
        data = await getPublicAgentBySubdomain({subdomain: subdomain || agent_id});
      } else { 
        data = await getPublicAgent({ agent_id });
      }
       
      if (data.result) {
        setAgent(data.agent);
        setActiveAgent(data.agent);
      }
    } catch (error) {
      navigate('/error/404', { replace: true });
    } finally {
      setLoading(false);

    }
  };

  useEffect(() => {
    fetchAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(agent) {
      if(!isNaN(agent_id)) {
        window.history.pushState({}, null, `/agent/${agent.id}/${agent.first_name.toLowerCase()}-${agent.last_name.toLowerCase()}`);
      }
    }
  }, [agent])
  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {agent && (
        <AgentProfileWrapper>
          <AgentListWrapper></AgentListWrapper>
          <Agent agent={agent} isPublic={true} />
        </AgentProfileWrapper>
      )}
      {!loading && !agent && <NotFound>404: NOT FOUND</NotFound>}
    </div>
  );
};

export default PublicAgent;
