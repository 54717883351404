const PoolIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="64" viewBox="0 0 68 64" fill="none">
    <path
      d="M53.2 38.3158V8.31579C53.2 4.82632 56.064 2 59.6 2H66M11.6 38.3158V8.31579C11.6 4.82632 14.464 2 18 2H24.4M11.6 14.6316H53.2M11.6 27.2632H53.2M2 49.3684L6 51.5022C8.56 52.8677 11.44 52.8677 14 51.5022C16.56 50.1366 19.44 50.1366 22 51.5022C24.56 52.8677 27.44 52.8677 30 51.5022C32.56 50.1366 35.44 50.1366 38 51.5022C40.56 52.8677 43.44 52.8677 46 51.5022C48.56 50.1366 51.44 50.1366 54 51.5022C56.56 52.8677 59.44 52.8677 62 51.5022L66 49.3684M2 58.8421L6 60.9759C8.56 62.3414 11.44 62.3414 14 60.9759C16.56 59.6103 19.44 59.6103 22 60.9759C24.56 62.3414 27.44 62.3414 30 60.9759C32.56 59.6103 35.44 59.6103 38 60.9759C40.56 62.3414 43.44 62.3414 46 60.9759C48.56 59.6103 51.44 59.6103 54 60.9759C56.56 62.3414 59.44 62.3414 62 60.9759L66 58.8421"
      stroke="#3C4D7D"
      stroke-width="4"
      stroke-miterlimit="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default PoolIcon;
