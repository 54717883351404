import { memo, useEffect, useState, useCallback, useContext, useRef } from 'react';
import { createCommunity } from '../../../services/admin';
import { getImages, getAllHighlights } from '../../../services/general';
import { useNavigate, useParams } from 'react-router-dom';
import { COMMUNITY_IMAGE_URL } from '../../../constants/common';

import Alert from 'react-bootstrap/Alert';

import './CreateCommunity.scss';
import AuthChecker from '../AuthChecker/AuthChecker';
import AddCommunity from '../addCommunity';
import { StaticNavigation } from '../layout';
import { MainContext } from '../../../context';
import { ToastContainer, toast } from 'react-toastify';

const CreateCommunity = () => {
  const navigate = useNavigate();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showLoadingAlert, setShowLoadingAlert] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const [highlights, setHighlights] = useState([]);
  const [allHighlights, setAllHighlights] = useState([]);

  const toastId = useRef(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const fetchAllHighlights = async () => {
    let result = await getAllHighlights();
    if (result.result) {
      setAllHighlights(result.highlights);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);

    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleImageClick = async (item) => {
    // Check if the item is already selected
    const isDeleted = deletedImages.includes(item);

    if (isDeleted) {
      // Item is already selected, remove it from the selectedItems array
      setDeletedImages(deletedImages.filter((deletedItem) => deletedItem.id !== item.id));
    } else {
      // Item is not selected, add it to the selectedItems array
      setDeletedImages([...deletedImages, item]);
    }
  };

  const handleHighlightsClick = async (item) => {
    console.log(highlights);
    console.log(allHighlights);
    // Check if the item is already selected
    const isSelected = highlights.some((obj) => obj.name === item.name);
    console.log('Selected? ' + isSelected);
    if (isSelected) {
      // Item is already selected, remove it from the selectedItems array
      setHighlights(highlights.filter((selectedItem) => selectedItem.id !== item.id));
    } else {
      // Item is not selected, add it to the selectedItems array
      setHighlights([...highlights, item]);
    }
  };

  const imageUrl = (url) => {
    return `${COMMUNITY_IMAGE_URL}${url}`;
  };

  const handleCreateCommunity = async (data) => {
    toastId.current = toast.loading('Saving...', {
      //onClose: () =>  setIsSubmitting(false)
    });
    try {
      const _data = {...data};

      if (_data.url && _data.url.startsWith('https://www.showingnew.com')) {
        const url = _data.url.split('/community')
        _data.url = ['https://www.newhomesource.com', url[1]].join('/community');
      }

      let result = await createCommunity(_data);
      //setShowLoadingAlert(false)
      if (result.result) {
        //setShowSuccessAlert(true);
        toast.update(toastId.current, {
          render: result.message,
          type: 'success',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
          onClose: () => {
            navigate('/admin/community/edit/' + result.slug + '/' + result.id);
          },
        });
      } else {
        toast.update(toastId.current, {
          render: result.message,
          type: 'error',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
          //onClose: () =>  setIsSubmitting(false)
        });
        if (result.errors) {
          setFieldErrors(result.errors);
        }
      }
    } catch (e) {
      toast.update(toastId.current, {
        render: e.response.data.message,
        type: 'error',
        isLoading: false,
        closeOnClick: true,
        autoClose: 2000,
        //onClose: () =>  setIsSubmitting(false)
      });
    }
  };

  const handleSubmit = async (values) => {
    values.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setShowSuccessAlert(false);
    setShowFailAlert(false);
    setShowLoadingAlert(true);
    let target = values.target;

    let data = {
      name: target.name.value,
      price_min: target.price_min.value,
      price_max: target.price_max.value,
      area_min: target.area_min.value,
      area_max: target.area_max.value,
      homes: target.homes.value,
      quick_move_ins: target.quick_move_ins.value,
      description: target.description.value,
      photos: target.photos.files,
      deleted_images: deletedImages,
      highlights: highlights,
      street: target.street.value,
      city: target.city.value,
      state: target.state.value,
      zip: target.zip.value,
      builderName: target.builderName.value,
      builderWebsite: target.builderWebsite.value,
      builderProfile: target.builderProfile.value,
      builderDescription: target.builderDescription.value,
    };

    handleCreateCommunity(data);
  };

  useEffect(() => {
    fetchAllHighlights();
  }, []);

  const { communityData } = useContext(MainContext);

  const handleSave = () => {
    handleCreateCommunity(communityData);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave?';
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <div className="">
      <AuthChecker />
      <StaticNavigation handleSave={handleSave} page="add-community" />
      <AddCommunity fieldErrors={fieldErrors} />
      <ToastContainer />
      
    </div>
  );
};

export default memo(CreateCommunity);
