import { memo, useEffect, useState, useCallback } from 'react';
import { getAgent, getAgents } from '../../../services/admin';
import { Link, useParams } from 'react-router-dom';
import { AGENT_PROFILE_URL } from '../../../constants/common';

import { checkIsLoggedIn } from '../../../utils/common';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';

import './AgentProfile.scss';
import AuthChecker from '../AuthChecker/AuthChecker';
import Agent from '../agent';
import { useContext } from 'react';
import { MainContext } from '../../../context';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import { getUserType } from '../../../utils/common';
import { StaticNavigation } from '../layout';

export const AgentProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

export const AgentListWrapper = styled.div`
  background-color: #e8e8e8;
  height: calc(100vh - 40px - 54px - 117px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const AgentListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  padding: 24px 0;
  width: 250px;
  cursor: pointer;
  border-bottom: 0.5px solid #bcbcbc;

  & h5 {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.3px;
  }

  &.active {
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
  }
`;

const AgentListItemImage = styled.div`
  border-radius: 50px;
  border: 4px solid #cda07d;
  width: 80px;
  height: 80px;
  overflow: hidden;

  & img {
    width: 100%;
  }
`;

export const AgentListItem = ({ photo, name, active, onClick, index }) => {
  return (
    <AgentListItemWrapper index={index} className={active ? 'active' : ''} onClick={onClick}>
      <AgentListItemImage>
        {photo && (
          <img
            src={`${AGENT_PROFILE_URL}${photo}`}
            className={active ? 'active' : ''}
            onError={(e) => e.target.remove()}
          />
        )}
      </AgentListItemImage>
      <h5 className={active ? 'active' : ''}>{name}</h5>
    </AgentListItemWrapper>
  );
};

const AgentProfile = () => {
  let { agent_id } = useParams();
  const [agent, setAgent] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const navigate = useNavigate();

  const [mls, setMls] = useState([]);
  const [licenses, setLicenses] = useState([]);

  const { loading, setLoading, setActiveAgent, setActivePage } = useContext(MainContext);
  const [agents, setAgents] = useState(false);
  const isAdmin = getUserType() == 2;

  const fetchAgent = async (id) => {
    setLoading(true);
    let data = await getAgent({ agent_id: id || agent_id });
    if (data.result) {
      setLoading(false);
      if (typeof data.agent.cities_served == 'string') {
        data.agent.cities_served = data.agent.cities_served.split(',').map((s) => {
          return { name: s, value: s };
        });
      }
      setAgent(data.agent);
      setActiveAgent(data.agent);
      setImgSrc(imageUrl(data.agent.photo));
      setMls([]);
      data.agent.mls.forEach((item) => {
        setMls((arr) => arr.concat({ name: item.mls_name, code: item.mls_code }));
      });

      setLicenses(data.agent.licenses);
      fetchAgents(data.agent);
    }
  };

  const imageUrl = (url) => {
    return `${AGENT_PROFILE_URL}${url}`;
  };

  const handleImageError = (event) => {
    let avatar = 'avatar-user-profile.svg';
    event.target.src = imageUrl(avatar); // Set the placeholder image source
  };

  const fetchAgents = async (agent) => {
    setLoading(true);
    if (isAdmin) {
      let data = await getAgents();
      if (data.result) {
        setAgents([agent, ...data.agents.filter((a) => a.id !== agent.id)]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setActivePage('agent-profile');
    fetchAgent();
  }, []);

  useEffect(() => {
    if (!isAdmin && agent) {
      setAgents([agent]);
      setLoading(false);
    }
  }, [agent]);
  return (
    <div className="relative">
      <AuthChecker />
      {checkIsLoggedIn && (
        <>
          <StaticNavigation page="agent-profile" />
          {!agent && loading && <Loader />}
          {
            agent && (
              <AgentProfileWrapper>
                {loading && <Loader />}
                {!loading && <Agent agent={agent} />}
              </AgentProfileWrapper>
            )
          }
        </>
      )}
      {!checkIsLoggedIn && <h1>401: Forbidden</h1>}
    </div>
  );
};

export default memo(AgentProfile);
