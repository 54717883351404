import { memo, useEffect, useState } from 'react';
import './HomePage.scss';
import '@typeform/embed/build/css/popup.css';
import { useMainContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../components/common';
import { styled } from '@mui/system';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import { getUserData } from '../../services/general';
import routes from '../../routes';
import Loader from '../../components/loader';

const HomePageHero = styled('section')(({ theme }) => ({
  height: 450,
  [theme.breakpoints.up('md')]: {
    height: 750,
  },
  width: '100%',
  background: 'url("https://bucket.ywhdev.com/wl/?id=5R9OM4sCK9uVHuNyOPz25K3aHhIEqyF5&fmode=open")',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  margin: 'auto',
  paddingBottom: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    backgroundPosition: 'center',
    height: 600,
  },
}));

const HomepageContainer = styled('section')(() => ({
  overflow: 'hidden',
}));

export const HomePageSection = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  [theme.breakpoints.down('lg')]: {
    maxWidth: 1200,
  },
}));

export const HomePageButton = styled('button')(() => ({
  width: 'fit-content',
  margin: '0 auto',
  fontWeight: 500,
  backgroundColor: '#04246B',
  borderRadius: '50px',
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 40px',
  '& span': {
    fontSize: 16,
    lineHeight: '24px',
  },
}));

export const HomepageSectionHeading = styled('header')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  '& img': {
    width: 130,
    height: 130,
    margin: 'auto',
  },
  '& h2': {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: 700,
    color: '#04246B',
    textAlign: 'center',
  },
}));

const HomePage = () => {
  const navigate = useNavigate();
  const { setActiveUser, isLoggedIn, activeUser } = useMainContext();

  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const result = await getUserData();
      if (result.message === 'Unauthenticated.') navigate(routes.userLogin());
      else {
        setActiveUser(result.user);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && activeUser) {
      if (activeUser.user_role_id === 3) {
        navigate('/user/dashboard', { replace: true });
      } else {
        navigate('/admin/agents', { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser, isLoggedIn]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section>
        <HomePageHero>
          <SearchBar />
        </HomePageHero>
        <HomepageContainer>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
        </HomepageContainer>
      </section>
    </>
  );
};

export default memo(HomePage);
