import { styled } from '@mui/material';
import React from 'react';

const Section2Container = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 80,
  marginBottom: 64,
  padding: '0 80px',
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& div.header': {
    display: 'flex',
    width: '100%',
    gap: 32,
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 840,
    margin: '0 auto',
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      color: '#04246B',
      fontWeight: 700,
    },
    img: {
      height: 100,
      width: 100,
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
}));

const Section2Card = styled('div')(({ theme }) => ({
  border: '2px solid #DEDEE0',
  borderRadius: 16,
  padding: 48,
  display: 'flex',
  flexDirection: 'column',
  gap: 64,
  maxWidth: 900,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: 700,
  },
  '& div.card-header': {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    div: {
      alignItems: 'center',
      display: 'flex',
      h1: {
        color: '#7BA4E9',
        fontSize: 120,
        lineHeight: '120px',
        fontWeight: 700,
      },
      img: {
        width: 70,
        height: 65,
      },
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '36px',
    },
  },
  '& div.card-content': {
    display: 'flex',
    gap: 80,
    [theme.breakpoints.down('md')]: {
      gap: 48,
      flexDirection: 'column',
      textAlign: 'center',
    },
    div: {
      maxWidth: 362,
      h4: {
        color: '#91805B',
        marginBottom: 8,
        fontSize: 24,
        lineHeight: '36px',
        fontWeight: 600,
      },
      p: {
        fontSize: 18,
        lineHeight: '28px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
      },
    },
  },
}));

const Section2 = () => {
  return (
    <Section2Container>
      <div className="header">
        <img src="https://bucket.ywhdev.com/wl/?id=1SrYEeNpIp7GDEBwMFhKeooHikJJXl60&fmode=open" alt="" />
        <h2>Seal the Deal: 5 Power Tips for Homebuyers Making an Offer!</h2>
        <h4>
          Owning a home is a dream for many, and when you've finally found 'The One', making a successful offer can be
          both exciting and nerve-wracking. How can you ensure that your offer stands out in this competitive market?
          Let's unlock the secrets to making an irresistible offer!
        </h4>
      </div>
      <Section2Card>
        <div className="card-header">
          <div>
            <h1>1</h1>
            <img src="https://bucket.ywhdev.com/wl/?id=XPSlrW0n5yocXZPkXvBDCUqZVk1KHMIX&fmode=open" alt="" />
          </div>
          <h4>Know Your Budget</h4>
        </div>
        <div className="card-content">
          <div>
            <div>
              <h4>What to Do:</h4>
            </div>
            <p>
              Before you even start house hunting, sit down and calculate how much you can afford to spend. Speak with a
              loan officer to get pre-approved for a mortgage.
            </p>
          </div>
          <div>
            <div>
              <h4>Why:</h4>
            </div>
            <p>
              Sellers take offers more seriously when they come from buyers who are already pre-approved. This shows
              them you're not only serious but also financially prepared.
            </p>
          </div>
        </div>
      </Section2Card>
      <Section2Card>
        <div className="card-header">
          <div>
            <h1>2</h1>
            <img src="https://bucket.ywhdev.com/wl/?id=XPSlrW0n5yocXZPkXvBDCUqZVk1KHMIX&fmode=open" alt="" />
          </div>
          <h4>Be Flexible with Your Terms</h4>
        </div>
        <div className="card-content">
          <div>
            <div>
              <h4>What to Do:</h4>
            </div>
            <p>
              While the price is paramount, other terms such as the closing date, contingencies, or included appliances
              can sway a seller in your favor.
            </p>
          </div>
          <div>
            <div>
              <h4>Why:</h4>
            </div>
            <p>
              Flexibility can be just as enticing as a higher price, especially if the seller needs certain conditions
              met.
            </p>
          </div>
        </div>
      </Section2Card>
      <Section2Card>
        <div className="card-header">
          <div>
            <h1>3</h1>
            <img src="https://bucket.ywhdev.com/wl/?id=XPSlrW0n5yocXZPkXvBDCUqZVk1KHMIX&fmode=open" alt="" />
          </div>
          <h4>Make a Strong Initial Offer</h4>
        </div>
        <div className="card-content">
          <div>
            <div>
              <h4>What to Do:</h4>
            </div>
            <p>
              Don't lowball in a competitive market. Start with a strong and fair offer based on comparable homes in the
              area and current market conditions.
            </p>
          </div>
          <div>
            <div>
              <h4>Why:</h4>
            </div>
            <p>
              In a seller's market, a strong initial offer can prevent a bidding war and show the seller that you're
              serious.
            </p>
          </div>
        </div>
      </Section2Card>
      <Section2Card>
        <div className="card-header">
          <div>
            <h1>4</h1>
            <img src="https://bucket.ywhdev.com/wl/?id=XPSlrW0n5yocXZPkXvBDCUqZVk1KHMIX&fmode=open" alt="" />
          </div>
          <h4>Keep Contingencies to a Minimum</h4>
        </div>
        <div className="card-content">
          <div>
            <div>
              <h4>What to Do:</h4>
            </div>
            <p>
              While it's essential to protect your interests, try not to bog down your offer with too many
              contingencies. Opt for fundamental ones like home inspection and financing.
            </p>
          </div>
          <div>
            <div>
              <h4>Why:</h4>
            </div>
            <p>The cleaner your offer, the more appealing it is to sellers.</p>
          </div>
        </div>
      </Section2Card>
      <Section2Card>
        <div className="card-header">
          <div>
            <h1>5</h1>
            <img src="https://bucket.ywhdev.com/wl/?id=XPSlrW0n5yocXZPkXvBDCUqZVk1KHMIX&fmode=open" alt="" />
          </div>
          <h4>React Quickly to Counter-Offers</h4>
        </div>
        <div className="card-content">
          <div>
            <div>
              <h4>What to Do:</h4>
            </div>
            <p>
              In a fast-paced market, time is of the essence. If the seller counters, respond promptly to keep the
              momentum going.
            </p>
          </div>
          <div>
            <div>
              <h4>Why:</h4>
            </div>
            <p>Quick reactions show eagerness and can prevent the seller from considering other offers.</p>
          </div>
        </div>
      </Section2Card>
    </Section2Container>
  );
};

export default Section2;
