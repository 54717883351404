import SelectCommunity from '../../Admin/SelectCommunity/SelectCommunity';
import { Layout } from '../components';

const Dashboard = () => {
  return (
    <Layout>
      <section>
        <SelectCommunity hasStatic={false} />
      </section>
    </Layout>
  );
};

export default Dashboard;
