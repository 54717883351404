import { styled } from '@mui/system';
import React, { useState } from 'react';

const ContinueReadingSpan = styled('span')({
  color: '#5184DF',
  cursor: 'pointer',
});

const ContinueReadingContainer = styled('div')({
  maxWidth: 880,
  textAlign: 'center',
  fontSize: 18,
  lineHeight: '28px',
  marginTop: 16,
});

const ContinueReading = ({ description }) => {
  const [showAll, setShowAll] = useState(false);

  return description?.length > 300 && !showAll ? (
    <ContinueReadingContainer>
      {description?.substring(0, 300)} ...
      <ContinueReadingSpan onClick={() => setShowAll(true)}>continue reading</ContinueReadingSpan>
    </ContinueReadingContainer>
  ) : (
    <ContinueReadingContainer>{description ?? ''}</ContinueReadingContainer>
  );
};

export default ContinueReading;
