import React from 'react';
import { styled } from '@mui/system';

const TermsOfServiceContainer = styled('div')(({ theme }) => ({
  padding: '80px 64px',
  maxWidth: 1000,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    padding: '80px 32px',
  },
  div: {
    marginBottom: 40,
    h3: {
      color: '#494949',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '28px',
      marginBottom: 8,
    },
    p: {
      fontSize: 14,
      lineHeight: '20px',
      a: {
        color: '#2E50A2',
      },
    },
    ul: {
      padding: '0 0 0 16px',
      margin: '8px 0',
      listStyleType: 'circle',
      li: {
        listStyle: 'disc',
        fontSize: 14,
        lineHeight: '20px',
        marginBottom: 8,
      },
    },
  },
}));

const TermsOfService = () => {
  return (
    <TermsOfServiceContainer>
      <div>
        <h3>Last updated: Dec 22, 2017</h3>
        <p>
          Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the website.
          Your access to and use of the Services is conditioned on your acceptance of and compliance with these Terms.
          These Terms apply to all visitors, users and others who access or use the Services. By accessing or using the
          Services you agree to be bound by these Terms. If you disagree with any part of the terms then you may not
          access the Services.
        </p>
      </div>
      <div>
        <h3>General</h3>
        <p>
          This Website is provided "as is" without a warranty of any kind, either expressed or implied, including, but
          not limited to, the implied warranties of merchantability, fitness for a particular purpose or
          non-infringement. This Website may include inaccuracies or typographical errors. Changes and additions are
          routinely made to the information herein. We may make additions and/or changes to this Website and/or the
          services described herein at any time.
        </p>
      </div>
      <div>
        <h3>Accounts</h3>
        <p>
          When you create an account with us, you must provide us information that is accurate, complete, and current at
          all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of
          your account on our Services. You are responsible for safeguarding the password that you use to access the
          Services and for any activities or actions under your password, whether your password is with our Services or
          a third-party service. You agree not to disclose your password to any third party. You must notify us
          immediately upon becoming aware of any breach of security or unauthorized use of your account.
        </p>
      </div>
      <div>
        <h3>Calls & Emails</h3>
        <p>
          If you, as a consumer, provide a real estate agent with your phone number through the Services, you consent to
          our and the real estate agent’s using your phone number to deliver or cause to be delivered to you at that
          phone number telemarketing calls (which may use an automatic telephone dialing system) and artificial or
          prerecorded voice or text messages. If you do not wish to receive such calls or messages, you may opt out. You
          further consent to our sharing your phone number to our or the real estate agent’s partners. You consent to
          our or the real estate agent’s partners using your phone number to deliver or cause to be delivered to you at
          that phone number telemarketing calls (which may use an automatic telephone dialing system) and artificial or
          prerecorded voice or text messages. You release us and the real estate agent from any possible liability or
          claim stemming from how our or the real estate agent’s partners use your phone number, included but not
          limited to claims arising from the Telephone Consumer Protection Act. You understand that you are not required
          to provide this consent and authorization and that it is not a condition to quality for a loan or to receive
          any good or service.
        </p>
      </div>
      <div>
        <h3>Links To Other Websites</h3>
        <p>
          Our Services may contain links to third-party websites or services that are not owned or controlled by
          YourWayHome. We have no control over, and assume no responsibility for, the content, privacy policies, or
          practices of any third party websites or services. You further acknowledge and agree that YourWayHome shall
          not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with use of or reliance on any such content, goods or services available on or through any
          such websites or services. We strongly advise you to read the terms and conditions and privacy policies of any
          third-party web sites or services that you visit.
        </p>
      </div>
      <div>
        <h3>Limitation of Liabilities</h3>
        <p>
          Neither YourWayHome nor any of its owners, affiliates, licensors or licensees shall have any liability for any
          errors, inaccuracies, omissions, or other defects in the information contained within this website. In no
          event shall YourWayHome, its owners, affiliates, licensors or its licensees or providers be liable to you or
          anyone else for any damages, other than direct damages, arising out of your access to or use of, or inability
          to use, this website or any information, products or services available through this website, including,
          without limitation, any special, indirect, consequential, punitive, exemplary or similar damages whatsoever
          (including, without limitation, damages for lost revenues or profits, or loss of business or data), even if
          YourWayHome or any such other person has been advised of the possibility of such damages.
          <br />
          <br />
          Without limiting the foregoing, you agree that the entire liability, if any, of YourWayHome, its owners,
          affiliates, licensors and licensees arising out of any kind of legal claim (whether in contract, tort or under
          any other legal theory) arising out of your access to or use of, or inability to use, this Website or any
          information, products or services available through this Website will not exceed the amount that you paid to
          YourWayHome, if any, for (i) the use of this Website or (ii) such information, products or services. Because
          some states/jurisdictions do not allow the exclusion or limitation of liability for consequential or
          incidental damages, some of these limitations may not apply to you.
        </p>
      </div>
      <div>
        <h3>Release & Indemnity</h3>
        <p>
          You agree to release, defend and hold harmless YourWayHome, its owners, affiliates, licensors and licensees
          from and against any and all claims, costs, demands, losses, damages and expenses, including, without
          limitation, attorney's fees, arising from or relating to: (i) your breach of this Agreement or any matter for
          which you are responsible or liable under the terms of this Agreement, or (ii) any dispute between you and any
          third party, including, without limitation, any other user, any advertiser or any party to any actual,
          prospective or terminated sale or transaction.
        </p>
      </div>
      <div>
        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the laws of United States, without regard to
          its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be
          considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by
          a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire
          agreement between us regarding our Services, and supersede and replace any prior agreements we might have
          between us regarding the Services.
        </p>
      </div>
      <div>
        <h3>Copyright & Trademarks</h3>
        <p>
          Copyright in this Website is owned by YourWayHome and this Website may only be used for informational
          purposes. The content displayed on this Website, including but not limited to the Website's look and feel,
          layout, text, graphics, images, sound or video materials, designs, the URL and software (collectively
          "Content"), is either the property of, or used with permission by YourWayHome and is protected by United
          States and international copyright and trademark laws. All rights, including but not limited to, copyright,
          title and interest in the Content belong to YourWayHome and/or its respective owners. The compilation (meaning
          the collection, arrangement and assembly) of all Content on this Website is also the exclusive property of
          YourWayHome and/or its respective owners and protected by U.S. and international copyright laws.
          <br />
          <br />
          The trademarks, service marks, trade names, domain names and logos ("Trademarks") displayed on this Website
          are either pending, registered or unregistered Trademarks of YourWayHome or their respective owners.
          <br />
          <br />
          You may NOT use any of the Trademarks without express written authorization of YourWayHome or the respective
          owners. You are strictly prohibited from reproducing, republishing, copying, transmitting, distributing in any
          form, or by any means, any Content. You may not modify, adapt, reverse engineer or in any way exploit any of
          the Content in whole or in part, except as expressly stated otherwise in Terms of Use or Visitor Agreement (if
          posted on this Website) and/or the prior written consent of the owner of the Content. Any unauthorized use of
          the Content is a violation of copyright laws and trademark laws, and may also violate laws of privacy and
          publicity, and/or communications regulations and statutes. Any of the aforementioned violations may subject
          you to criminal penalties.
        </p>
      </div>
      <div>
        <h3>Claims of Copyright Infringement</h3>
        <p>
          We respect the intellectual property rights of others, and ask that everyone using the Services do the same.
          Anyone who believes that their work has been reproduced on the Services in a way that constitutes copyright
          infringement may notify us by providing the following information:
        </p>
        <ul>
          <li>
            Identification of the material that you claim is infringing and needs to be removed, including a description
            of where it is located on the Services so that the we can locate it;
          </li>
          <li>
            Your address, telephone number, and, if available, e-mail address, so that the YourWayHome may contact you
            about your complaint;
          </li>
          <li>
            A signed statement that the above information is accurate; that you have a good faith belief that the
            identified use of the material is not authorized by the copyright owner, its agent, or the law; and, under
            penalty of perjury, that you are the copyright owner or are authorized to act on the copyright owner's
            behalf in this situation.
          </li>
        </ul>
      </div>
      <div>
        <p style={{ marginBottom: 8 }}>Notices of copyright infringement claims should be sent as follow:</p>
        <p>
          By email: <a href="mailto:ywhteam@yourwayhome.com">ywhteam@yourwayhome.com</a>
        </p>
      </div>
      <div>
        <h3>Ownership & License</h3>
        <p>
          This Website and all content displayed on this Website and all software, data and information used to provide
          this Website, including text and images, method of display and presentation, source code, embedded routines
          and programs and other materials, as well as all copyrights, trademarks, patents and other intellectual
          property rights therein or thereto, are owned by YourWayHome, its owners, affiliates, licensors or licensees,
          and are protected under worldwide copyright, trademark and other applicable laws and treaties, including,
          without limitation, applicable intellectual property laws. We grant you the limited and nonexclusive right and
          license to access or download a single copy of the content from this Website solely for your personal and
          non-commercial use and as necessary in connection with the use of any services available through the Website
          except as expressly authorized in this Agreement, you may not modify, distribute, reproduce, display, or use
          this Website or any elements thereof. All rights not expressly granted to you by us in this Agreement are
          reserved to YourWayHome and you acknowledge that you do not acquire any ownership rights by accessing or
          downloading any material, whether or not copyrighted, from the Website as authorized hereunder.
        </p>
      </div>
      <div>
        <h3>Changes</h3>
        <p style={{ marginBottom: 8 }}>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
          material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes
          a material change will be determined at our sole discretion. By continuing to access or use our Services after
          those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new
          terms, please stop using the Service.
        </p>
        <p>Notices of copyright infringement claims should be sent as follow:</p>
        <p>
          By email: <a href="mailto:ywhteam@yourwayhome.com">ywhteam@yourwayhome.com</a>
        </p>
      </div>
    </TermsOfServiceContainer>
  );
};

export default TermsOfService;
