const BaseballFieldIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="60" viewBox="0 0 68 60" fill="none">
    <path
      d="M33.9999 0C12.9052 0 0.842041 22.1053 0.842041 22.1053L25.6631 48.0947C26.3263 47.3684 27.1157 46.5789 27.9999 46.0105L17.2631 34.7368L33.9999 18.0316L50.7368 34.7368L39.9999 46.0105C40.8841 46.5789 41.6736 47.3684 42.3368 48.0947L67.1578 22.1053C67.1578 22.1053 55.0947 0 33.9999 0ZM33.9999 13.5474L15.0526 32.4632L4.82099 21.6947C8.16836 16.5789 18.5263 3.15789 33.9999 3.15789C49.4105 3.15789 59.7999 16.6105 63.1789 21.6947L52.9473 32.4632L33.9999 13.5474ZM37.1578 34.7368C37.1578 36.4737 35.7368 37.8947 33.9999 37.8947C32.2631 37.8947 30.842 36.4737 30.842 34.7368C30.842 33 32.2631 31.5789 33.9999 31.5789C35.7368 31.5789 37.1578 33 37.1578 34.7368ZM38.7368 56.8421L33.9999 60L29.2631 56.8421V50.5263H38.7368V56.8421Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default BaseballFieldIcon;
