import { memo, useEffect } from 'react';


import './Http401.scss';

const Http401 = () => {
    useEffect(() => {
    }, []);

    return (
        <>
            <h1 class="px-spc-b-20">401 - Unauthorized.</h1>
            <span class="px-spc-b-20">You are not authorized to access this page.</span>
        </>
    );
};

export default memo(Http401);