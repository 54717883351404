const SoccerField = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="104" height="61" viewBox="0 0 104 61" fill="none">
    <path
      d="M0.978149 0.5V60.5H103.022V0.5H0.978149ZM4.91976 4.44161H50.0292V21.7444C46.0329 22.6499 43.0219 26.2415 43.0219 30.5C43.0219 34.7585 46.0329 38.3504 50.0292 39.2556V56.5584H4.91976V42.9818H18.9344V18.0182H4.91976V4.44161ZM53.9709 4.44161H99.0803V18.0182H85.0657V42.9818H99.0803V56.5584H53.9709V39.2556C57.9672 38.3501 60.9781 34.7585 60.9781 30.5C60.9781 26.2415 57.9672 22.6496 53.9709 21.7444V4.44161ZM4.91976 21.9599H14.9927V39.0401H4.91976V21.9599ZM89.0073 21.9599H99.0803V39.0401H89.0073V21.9599ZM50.0292 25.8561V35.1439C48.2183 34.3847 46.9636 32.6037 46.9636 30.5C46.9636 28.3965 48.2183 26.6153 50.0292 25.8561ZM53.9709 25.8561C55.7818 26.6153 57.0365 28.3963 57.0365 30.5C57.0365 32.6035 55.7818 34.3847 53.9709 35.1439V25.8561Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default SoccerField;
