import { styled } from '@mui/material';
import React from 'react';

const Section3Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: 32,
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 32,
  paddingBottom: 80,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 64,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& div.header': {
    maxWidth: 476,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 16,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
      maxWidth: 700,
    },
    img: {
      height: 100,
      width: 100,
    },
    h4: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 500,
    },
  },
  '& div.image': {
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 400,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        height: '100%',
      },
    },
  },
}));

const Section3 = () => {
  return (
    <Section3Container>
      <div className="image">
        <img
          className="shadow-right"
          src="https://bucket.ywhdev.com/wl/?id=gZsE0GeeEHyLloktByflFQiMLwOINeL7&fmode=open"
          alt="showing a woman smiling while writing in a notebook with a laptop beside her."
        />
      </div>
      <div className="header">
        <img src="https://bucket.ywhdev.com/wl/?id=xLcRfcq8VuG2SSA0d08dZXrXM78Fp98m&fmode=open" alt="" />
        <h4>
          Remember, while these tips can significantly bolster your chances, there's no one-size-fits-all formula in
          real estate. Partner with a trusted realtor, stay informed, and always trust your instincts. Happy house
          hunting!
        </h4>
      </div>
    </Section3Container>
  );
};

export default Section3;
