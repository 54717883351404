import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Avoid the use of overhead lighting that makes rooms look washed out and lifeless.',
  'Be creative and arrange lamps to help smaller rooms seem larger, and large rooms seem more intimate.',
  'Use lighting to highlight the "living areas" of your home, such as a pair of chairs near a fireplace, or a table in a breakfast area.',
];

const Section4Container = styled('section')(({ theme }) => ({
  width: '100%',
  padding: 100,
  paddingBottom: 0,
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  '& div.header': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    textAlign: 'center',
    marginBottom: 32,
    padding: '64px 32px 0 32px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 764,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      color: '#04246B',
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
      },
    },
    h5: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
      },
    },
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: 64,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '64px 0 0 0',
    },
    ul: {
      order: 2,
      maxWidth: 536,
      display: 'flex',
      gap: 24,
      padding: 0,
      flexShrink: 0,
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        order: 1,
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      order: 1,
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        order: 2,
      },
      img: {
        objectFit: 'cover',
        height: 400,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 400,
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section4 = () => {
  return (
    <Section4Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="105" height="101" viewBox="0 0 105 101" fill="none">
          <circle cx="55" cy="51" r="48.5" fill="white" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M33.75 75.7143V49.4958H24L56.5 20L89 49.4958H79.25V75.7143H33.75ZM56.5 28.816L40.25 43.5639V69.1597H72.75V43.5639L56.5 28.816ZM53.25 65.8824V62.605H59.75V65.8824H53.25ZM53.25 59.3277C52.34 59.3277 51.625 58.6067 51.625 57.6891V54.7395C48.7 53.0353 46.75 49.8563 46.75 46.2185C46.75 40.7782 51.105 36.3866 56.5 36.3866C61.895 36.3866 66.25 40.7782 66.25 46.2185C66.25 49.8563 64.3 53.0353 61.375 54.7395V57.6891C61.375 58.6067 60.66 59.3277 59.75 59.3277H53.25Z"
            fill="#CDA07D"
          />
        </svg>
        <h2>Put your Home in the Best Possible Light</h2>
        <h5>
          Strategically lighting your home, even during daytime showings, can create a cozy mood and highlight positive
          attributes of each room.
        </h5>
      </div>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=yDyCloGGkYiwb8GxX3CS2Eee1G1MsaWe&fmode=open" alt="" />
        </div>
      </div>
    </Section4Container>
  );
};

export default Section4;
