import React, { useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import { styled } from '@mui/system';

const Input = styled('input')({
  position: 'absolute',
  cursor: 'pointer',
  width: '25px',
  height: '25px',
});

const Unchecked = styled('div')({
  border: '1px solid #686868',
  display: 'block',
  width: '25px',
  height: '25px',
  margin: 0,
  background: 'white',
  overflow: 'hidden',
});

const Checked = styled(Unchecked)({
  backgroundColor: '#04246b',
  borderColor: '#04246b',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const CheckBox = (props) => {
  const [checked, setChecked] = useState(props.defaultChecked);

  const handleCheck = (e) => {
    setChecked(e.currentTarget.checked);
  };

  return (
    <>
      <Input type="checkbox" {...props} onClick={handleCheck} style={{ opacity: 0 }} />
      {!checked ? (
        <Unchecked />
      ) : (
        <Checked>
          <BiCheck fill="white" size={30} />
        </Checked>
      )}
    </>
  );
};

export default CheckBox;
