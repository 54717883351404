import styled from 'styled-components'
import {  LOAN_OFFICER_PROFILE_URL } from '../../constants/common'
import { 
    AgentPage,
    AgentDetail,
    AgentDetailExtra,
    AgentContactContainer,
    AgentContactWrapper,
    AgentContactListItem,
    AgentAdditionalDetailsContainer,
    AgentOfficeAddress,
    AgentCompanyWebsite,
    AgentCityStateServedContainer,
    AgentCityStateServed,
    AgentDetailsBio,
    AgentDetailsWrapper
} from '../../components/agent';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const AgentImage = styled.img`
    width: 350px;
    height: 350px;
    border-radius: 100%;
    object-fit: cover;
    object-position: top center;

    @media (min-width: 1600px) {
        width: 450px;
        height: 450px; 
    }
`

const AgentDetailsName = styled.div`
    display: flex;
    width: calc(100% - 414px);
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    ${'' /* @media (min-width: 1600px) {
        width: calc(100% - 514px);
    } */}
    width: fit-content;
    & h1 {
        border-bottom: 2px solid var(--ywh-alto, #D8D8D8);
        color: var(--ywh-royal-blue, #04246B);
        /* ywh-h1 */
        font-family: Playfair Display;
        font-size: 59px;
        font-style: normal;
        font-weight: 700;
        line-height: 76.7px; /* 130% */
        padding-bottom: 16px;
    }
`

const AgentDetailsListWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    & label {
        min-width: 150px;
        max-width: 150px;
        flex: 1 0 0;
        color: var(--ywh-raisin-black, #2F2F2F);

        /* ywh-h5-bold */
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 150% */
    }

    & span {
        color: var(--ywh-raisin-black, #2F2F2F);
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px; /* 130% */

        &:last-child {
            font-weight: 400;
        }
    }
    
`

const AgentDetailsListItem= ({label, value}) => {
    return (
        <AgentDetailsListWrapper>
            <label>{label}</label>
            <span>:</span>
            <span>{value || ''}</span>
        </AgentDetailsListWrapper>
    )
}

const EditProfileLinkWrapper = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
position: absolute;
right: 40px;
top: -20px;
& p {
    color: var(--ywh-azure, #5284E0);
text-align: center;
font-family: Montserrat;
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: 19px; /* 100% */
width: 87px;
}
`

const VisitPublicButton = styled(Link)`
  padding: 12px;
  color: white !important;
  background-color: #04246B;
  font-weight: 600;
  border-radius: 8px;
`

const NoPhoto = styled('div')`
  height: 450px;
	width: 450px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid gray;
  flex-shrink: 0;
`

export const OfficerAddressWrapper = styled.div`
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;

    & .office_address-wrapper {
        flex: 0 0 calc(50% - 2rem);
        padding: 0px;
    }
`


const Officer = ({officer, isPublic = false}) => {
		const [imgError, setImgError] = useState(false)

    return (
        <AgentPage>
            <AgentDetail>
                {(!officer?.headshot || imgError) ? <NoPhoto>No Photo</NoPhoto> : <AgentImage src={`${LOAN_OFFICER_PROFILE_URL}${officer.headshot}`} onError={() => setImgError(true)} />}
                <AgentDetailsName>
                    <h1>{officer.first_name} {officer.last_name} </h1>
                    <AgentDetailsWrapper>
                        <AgentDetailsListItem label="NMLS" value={officer.nmls} />
                        <AgentDetailsListItem label="Mobile Phone" value={officer.mobile_phone} />
                       <AgentDetailsListItem label="Office Phone" value={officer.office_phone} />
                        <AgentDetailsListItem label="Direct Phone" value={officer.direct_phone} />
                        <AgentDetailsListItem label="Primary Email" value={officer.email} />
                        <AgentDetailsListItem label="Personal Email" value={officer.personal_email} />
                        {!isPublic && <VisitPublicButton to={'/officer/'+officer.id} target='_blank'>View public profile</VisitPublicButton> }
                    </AgentDetailsWrapper>
                </AgentDetailsName>
            </AgentDetail>
            <AgentDetailExtra>
                {<AgentContactContainer>
                    <AgentContactWrapper>
                        <AgentContactListItem title="Personal Website URL" icon="brokerage" value={officer.personal_website_url} readOnly={true}/>
                        <AgentContactListItem title="Loan Application URL" icon="company-phone" value={officer.loan_application_url} readOnly={true} />
                        <AgentContactListItem title="LinkedIn URL" icon="compensation-plan" value={officer.linkedin_url} readOnly={true} />
                        <AgentContactListItem title="Facebook URL" icon="team-name" value={officer.facebook_url} readOnly={true} />
                        <AgentContactListItem title="Instagram URL" icon="officer-type" value={officer.instagram_url} readOnly={true} />
                        <AgentContactListItem title="Tiktok URL" icon="officer-type" value={officer.tiktok_url} readOnly={true} />
                        <AgentContactListItem title="Twitter URL" icon="officer-type" value={officer.twitter_url} readOnly={true} />
                        <AgentContactListItem title="Branch Name" icon="officer-type" value={officer.branch_name} readOnly={true} />
                        <AgentContactListItem title="Manager Name" icon="officer-type" value={officer.manager_name} readOnly={true} />
                        <AgentContactListItem title="Recruited By" icon="officer-type" value={officer.recruited_by} readOnly={true} />
                        <AgentContactListItem title="Compensation Plan" icon="officer-type" value={officer.compensation_plan} readOnly={true} />
                        <AgentContactListItem title="Onboarded Date" icon="officer-type" value={officer.onboarded_date} readOnly={true} />
                        <AgentContactListItem title="Assistant Name" icon="officer-type" value={`${(officer.assistant_first_name || '' )} ${(officer.assistant_first_name || '' )}` } readOnly={true} />
                        <AgentContactListItem title="Assistant Email" icon="officer-type" value={officer.assistant_email } readOnly={true} />
                        <AgentContactListItem title="Assistant Phone" icon="officer-type" value={officer.assistant_direct_phone } readOnly={true} />
                    
                    </AgentContactWrapper>
                </AgentContactContainer> }

                <AgentAdditionalDetailsContainer>
                    <OfficerAddressWrapper>
                        <AgentOfficeAddress title="Company Name" readOnly={true} value={officer.company_name} />  
                        <AgentOfficeAddress title="Company NMLS" readOnly={true} value={officer.company_nmls} />
                    </OfficerAddressWrapper>
                    <AgentOfficeAddress title="Company Office Address" readOnly={true} 
                        value={[officer.company_office_address, officer.company_office_city, officer.company_office_state, officer.company_office_zip].filter(a => a).join(', ')} />
                    <AgentOfficeAddress title="Office Address" readOnly={true} 
                    value={[officer.office_address, officer.office_city, officer.office_state, officer.office_zip].filter(a => a).join(', ')} />
                 
                    <AgentCompanyWebsite read={true} company={officer.company_url} personal={officer.personal_website_url} />
                    <AgentCityStateServedContainer>
                        {officer.state_licenses && officer.state_licenses.length > 0 &&   
                        <AgentCityStateServed title="state" description="States Licensed In" values={officer.state_licenses || []}  readOnly={true}/>
                        }
                         {officer.assigned_agents && officer.assigned_agents.length > 0 &&   
                        <AgentCityStateServed title="agents" description="Agents Assigned" values={officer.assigned_agents || []}  readOnly={true}/>
                        }
                    </AgentCityStateServedContainer>
                    <AgentOfficeAddress title="Company License Disclosure" readOnly={true} value={officer.company_license_disclosure} /> 
                    <AgentOfficeAddress title="Primary Zip Codes" readOnly={true} value={officer.primary_zip_codes} /> 

                    
                </AgentAdditionalDetailsContainer>
           </AgentDetailExtra>
           <AgentDetailsBio
          title="About"
          icon="bio-name"
          name="description"
          values={officer.description}
          readOnly={true}
          type="text"
          placeholder="Officer's Bio"
        />
        </AgentPage>
        
    )
}

export default Officer;
