import { memo, useEffect, useState } from 'react';
import { getStates } from '../../services/general';
import { DOMAIN } from '../../constants/common';

import './StateList.scss';
import { useMainContext } from '../../context';
import Loader from '../../components/loader';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

const StateContainer = styled('div')(({ theme }) => ({
  padding: '100px 0',
  [theme.breakpoints.down('md')]: {
    padding: '100px 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  maxWidth: 800,
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 auto',
  marginBottom: 64,
  h2: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '48px',
    color: '#04246B',
  },
  h3: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
  },
}));

const StateListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: 32,
  maxWidth: 994,
  margin: '0 auto',
  justifyContent: 'center',
}));

const StateCard = styled('div')(({ theme }) => ({
  flexBasis: '30%',
  [theme.breakpoints.down('md')]: {
    flexBasis: 'calc(50% - 16px)',
  },
  [theme.breakpoints.down('sm')]: {
    flexBasis: '100%',
  },
  a: {
    img: {
      width: '100%',
      height: 221,
      borderRadius: '8px 8px 0 0',
    },
    h3: {
      padding: 20,
      fontSize: 24,
      lineHeight: '36px',
      textAlign: 'center',
      backgroundColor: '#DEDEE0',
      borderRadius: '0 0 8px 8px',
    },
  },
}));

export const STATE_IMAGES = {
  AZ: 'https://bucket.ywhdev.com/wl/?id=95EdcHiTup2m8LVF6eQn2cKYSfi10keL&fmode=open',
  CA: 'https://bucket.ywhdev.com/wl/?id=SFVcOELxw43uCAsyGBJ7txeW3MXaVxR6&fmode=open',
  CO: 'https://bucket.ywhdev.com/wl/?id=IklMeUceebJ5CvJ9nVCvGXwgu4tOer1R&fmode=open',
  DE: 'https://bucket.ywhdev.com/wl/?id=CXh8tax3rmScJvzKhwwjVkIMbzxPan0Z&fmode=open',
  FL: 'https://bucket.ywhdev.com/wl/?id=84LZa1lR0LUQVypUjHcmoBDVYt6It0TW&fmode=open',
  GA: 'https://bucket.ywhdev.com/wl/?id=WK4YQxfzPeWH8EwyndeyILtjFzB1QANw&fmode=open',
  MD: 'https://bucket.ywhdev.com/wl/?id=KdPos6Lo9HfgSdi63B5cz3FfKqvZPav1&fmode=open',
  MI: 'https://bucket.ywhdev.com/wl/?id=uo6THoUW5JwdNkXwhL1gkF3YeIZzZgZ4&fmode=open',
  NJ: 'https://bucket.ywhdev.com/wl/?id=h2cOI2RQIl43b6QuZf0Tu08AeYlMJMvW&fmode=open',
  NY: 'https://bucket.ywhdev.com/wl/?id=226cmzEideQZEouhIbiqRnvgdv8A2kzw&fmode=open',
  NC: 'https://bucket.ywhdev.com/wl/?id=PQRwbUozIG0eGkFInCBVZUzEAjyEObew&fmode=open',
  PA: 'https://bucket.ywhdev.com/wl/?id=mdNhoH4Sve3aYwVtu13NBsFWJ7laBLWI&fmode=open',
  TX: 'https://bucket.ywhdev.com/wl/?id=WbzDn1QLYU0kT4unquWkZRI3q1Cb1gJu&fmode=open',
  UT: 'https://bucket.ywhdev.com/wl/?id=lc3FgKeiInhtpuurebFuGZN3HHQWgHu3&fmode=open',
  WA: 'https://bucket.ywhdev.com/wl/?id=iinH3iHV3SpbvGhwItaoigkXk3ehEbf1&fmode=open',
};


const StateList = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const { loading, setLoading } = useMainContext();

  const fetchStates = async () => {
    setLoading(true);
    try {
      let result = await getStates();
      if (result.result) {
        setStates(result.states);
      } else {
        navigate('/error/404', { replace: true });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <StateContainer>
      <Header>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M84.8946 77.9794C87.0525 74.5954 88.2786 70.5248 88.2786 66.2089C88.2786 53.948 78.4698 44.1393 66.2089 44.1393C53.948 44.1393 44.1393 53.948 44.1393 66.2089C44.1393 78.4698 53.948 88.2786 66.2089 88.2786C70.4757 88.2786 74.4973 87.0525 77.8813 84.9436L93.1829 100L100 93.1829L84.8946 77.9794ZM66.2089 78.4698C62.9571 78.4698 59.8385 77.1781 57.5392 74.8787C55.2398 72.5793 53.948 69.4607 53.948 66.2089C53.948 62.9571 55.2398 59.8385 57.5392 57.5392C59.8385 55.2398 62.9571 53.948 66.2089 53.948C69.4607 53.948 72.5793 55.2398 74.8787 57.5392C77.1781 59.8385 78.4698 62.9571 78.4698 66.2089C78.4698 69.4607 77.1781 72.5793 74.8787 74.8787C72.5793 77.1781 69.4607 78.4698 66.2089 78.4698ZM14.7131 83.3742V44.1393H0L49.0437 0L98.0873 44.1393H89.1614C84.9366 39.7167 79.5187 36.6158 73.5655 35.2133L49.0437 13.1927L24.5218 35.2624V73.5655H35.2133C36.0471 77.0966 37.4693 80.3825 39.382 83.3742H14.7131Z"
            fill="#CDA07D"
          />
        </svg>
        <h2>Explore New Home Communities</h2>
        <h3>
          Research thousands of new home communities from national builders as well as local custom builders. Select
          your state to get started.
        </h3>
      </Header>
      <StateListContainer>
        {states &&
          states.map((st, i) => {
            let randomStateImage = Object.values(STATE_IMAGES)[Math.floor(Math.random() * Object.values(STATE_IMAGES).length)];
            let url = `/community/${st.code}`;
            if (st.has_communities) {
              return (
                <>
                  <StateCard>
                    <Link to={url}>
                      <img src={STATE_IMAGES[st.code] ?? randomStateImage} alt={`showing a housing community in ${st.name}`} />
                      <h3 className="state__name">{st.name}</h3>
                    </Link>
                  </StateCard>
                </>
              );
            }
            return null;
          })}
      </StateListContainer>
    </StateContainer>
  );
};

export default memo(StateList);
