import { styled, useTheme } from '@mui/system';
import React from 'react';
import { SearchBar } from '../../../components/common';
import { useMediaQuery } from '@mui/material';

const NotFoundContainer = styled('div')(({ theme }) => ({
  padding: '120px 24px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& h2': {
    color: '#04246B',
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 500,
    lineHeight: '39px' /* 130% */,
    maxWidth: 600,
  },
  '& h4': {
    textAlign: 'center',
    fontSize: 25,
    fontWeight: 400,
    lineHeight: '37.5px' /* 130% */,
    marginBottom: 48,
  },
  [theme.breakpoints.down('md')]: {
    '& h2': {
      fontSize: 25,
      lineHeight: '37.5px' /* 130% */,
      maxWidth: 500,
    },
    '& h4': {
      fontSize: 21,
      lineHeight: '31.5px' /* 130% */,
    },
  },
}));

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  padding: 32,
  gap: 8,
  alignItems: 'center',
  '& h1': {
    fontSize: 200,
  },
  [theme.breakpoints.down('md')]: {
    '& h1': {
      fontSize: 100,
    },
  },
}));

const Icon = () => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return mediumScreen ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
      <path
        d="M91.9109 6.89356C94.4836 6.89356 96.509 8.91583 96.509 11.4916C96.509 14.0643 94.4867 16.0834 91.9109 16.0834C89.3383 16.0834 87.3129 14.0643 87.3129 11.4916C87.316 8.91583 89.3383 6.89356 91.9109 6.89356Z"
        fill="#04246B"
      />
      <path
        d="M6.98516 83.7334C10.8441 83.7334 13.9703 86.8565 13.9703 90.7154C13.9703 94.5744 10.8441 97.7006 6.98516 97.7006C3.12618 97.7037 0 94.5744 0 90.7186C0 86.8627 3.12618 83.7334 6.98516 83.7334Z"
        fill="#04246B"
      />
      <path
        d="M93.3828 51.3803H83.0891C83.0891 33.1799 68.386 18.2912 50 18.2912V7.99433C73.8961 7.99433 93.3828 27.4842 93.3828 51.3803Z"
        fill="#04246B"
      />
      <path
        d="M50 84.4694V94.7631C26.1039 94.7631 6.61719 75.2795 6.61719 51.3834H16.9109C16.9109 69.5776 31.7996 84.4694 50 84.4694Z"
        fill="#04246B"
      />
      <path
        d="M83.0859 51.3803H93.3797C93.3797 75.2764 73.8961 94.76 49.9969 94.76V84.4662C68.1973 84.4694 83.0859 69.5776 83.0859 51.3803Z"
        fill="#CDA07D"
      />
      <path
        d="M50 7.99433L50 18.2881C31.7996 18.2881 16.9109 32.9943 16.9109 51.3772H6.61719C6.61719 27.4842 26.1008 7.99433 50 7.99433Z"
        fill="#CDA07D"
      />
      <path
        d="M50 18.2912C68.1973 18.2912 83.0891 32.9975 83.0891 51.3803C83.0891 69.76 68.386 84.4662 50 84.4662C31.6172 84.4662 16.9109 69.76 16.9109 51.3803C16.9109 33.1799 31.7996 18.2912 50 18.2912Z"
        fill="#EDF5FF"
      />
      <path d="M61.7656 39.0643L54.2269 51.0123L46.5059 54.3209L31.0668 48.2541L61.7656 39.0643Z" fill="#5284E0" />
      <path
        d="M98.3457 25.2764C99.2578 25.2764 100 26.0186 100 26.9307C100 27.8427 99.2578 28.585 98.3457 28.585C97.4305 28.585 96.6914 27.8427 96.6914 26.9307C96.6914 26.0186 97.4305 25.2764 98.3457 25.2764Z"
        fill="#B5DBFF"
      />
      <path
        d="M89.1527 89.6147C90.0679 89.6147 90.807 90.3569 90.807 91.269C90.807 92.181 90.0679 92.9233 89.1527 92.9233C88.2407 92.9233 87.4953 92.181 87.4953 91.269C87.4984 90.3569 88.2407 89.6147 89.1527 89.6147Z"
        fill="#B5DBFF"
      />
      <path
        d="M24.8144 0.0908203C25.7297 0.0908203 26.4687 0.833052 26.4687 1.74512C26.4687 2.66033 25.7297 3.39941 24.8144 3.39941C23.9024 3.39941 23.1601 2.65718 23.1601 1.74512C23.1601 0.833052 23.9024 0.0908203 24.8144 0.0908203Z"
        fill="#B5DBFF"
      />
      <path
        d="M24.8144 94.2096C25.7297 94.2096 26.4687 94.9487 26.4687 95.8639C26.4687 96.7791 25.7297 97.5182 24.8144 97.5182C23.9024 97.5182 23.1601 96.7791 23.1601 95.8639C23.1601 94.9487 23.9024 94.2096 24.8144 94.2096Z"
        fill="#B5DBFF"
      />
      <path
        d="M2.94062 19.5775C3.85269 19.5775 4.59492 20.3166 4.59492 21.2287C4.59492 22.1439 3.85269 22.883 2.94062 22.883C2.02541 22.883 1.28633 22.1408 1.28633 21.2287C1.28633 20.3198 2.02541 19.5775 2.94062 19.5775Z"
        fill="#B5DBFF"
      />
      <path
        d="M50 19.3951C49.4465 19.3951 48.8961 18.8416 48.8961 18.2912V3.40256C48.8961 2.84903 49.4465 2.29865 50 2.29865C50.5504 2.29865 51.1039 2.84903 51.1039 3.40256V18.2912C51.1039 19.0272 50.5504 19.3951 50 19.3951Z"
        fill="#04246B"
      />
      <path
        d="M50 99.9084C49.4465 99.9084 48.8961 99.358 48.8961 98.8045V84.4662C48.8961 83.9158 49.4465 83.3623 50 83.3623C50.5504 83.3623 51.1039 83.9158 51.1039 84.4662V98.8045C51.1039 99.358 50.5504 99.9084 50 99.9084Z"
        fill="#04246B"
      />
      <path
        d="M97.6098 52.4811H82.9035C82.3531 52.4811 81.7996 51.9307 81.7996 51.3803C81.7996 50.8268 82.3531 50.2764 82.9035 50.2764H97.6098C98.1633 50.2764 98.7137 50.8299 98.7137 51.3803C98.7137 51.9307 98.3457 52.4811 97.6098 52.4811Z"
        fill="#04246B"
      />
      <path
        d="M16.9109 52.4811H2.20782C1.6543 52.4811 1.10391 51.9307 1.10391 51.3803C1.10391 50.8268 1.65744 50.2764 2.20782 50.2764H16.9109C17.4645 50.2764 18.0148 50.8299 18.0148 51.3803C18.0148 51.9307 17.6469 52.4811 16.9109 52.4811Z"
        fill="#04246B"
      />
      <path d="M61.7656 39.0643L52.5727 69.76L46.5059 54.3209L61.7656 39.0643Z" fill="#04246B" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
      <path
        d="M183.822 13.7881C188.967 13.7881 193.018 17.8326 193.018 22.9842C193.018 28.1295 188.973 32.1678 183.822 32.1678C178.677 32.1678 174.626 28.1295 174.626 22.9842C174.632 17.8326 178.677 13.7881 183.822 13.7881Z"
        fill="#04246B"
      />
      <path
        d="M13.9703 167.468C21.6883 167.468 27.9406 173.714 27.9406 181.432C27.9406 189.15 21.6883 195.402 13.9703 195.402C6.25236 195.408 0 189.15 0 181.438C0 173.726 6.25236 167.468 13.9703 167.468Z"
        fill="#04246B"
      />
      <path
        d="M186.766 102.762H166.178C166.178 66.3608 136.772 36.5834 100 36.5834V15.9896C147.792 15.9896 186.766 54.9694 186.766 102.762Z"
        fill="#04246B"
      />
      <path
        d="M100 168.94V189.527C52.2078 189.527 13.2344 150.56 13.2344 102.768H33.8219C33.8219 139.156 63.5992 168.94 100 168.94Z"
        fill="#04246B"
      />
      <path
        d="M166.172 102.762H186.759C186.759 150.554 147.792 189.521 99.9937 189.521V168.933C136.395 168.94 166.172 139.156 166.172 102.762Z"
        fill="#CDA07D"
      />
      <path
        d="M100 15.9896L100 36.5771C63.5992 36.5771 33.8219 65.9896 33.8219 102.755H13.2344C13.2344 54.9694 52.2015 15.9896 100 15.9896Z"
        fill="#CDA07D"
      />
      <path
        d="M100 36.5834C136.395 36.5834 166.178 65.9959 166.178 102.762C166.178 139.521 136.772 168.933 100 168.933C63.2344 168.933 33.8219 139.521 33.8219 102.762C33.8219 66.3608 63.5992 36.5834 100 36.5834Z"
        fill="#EDF5FF"
      />
      <path d="M123.531 78.1295L108.454 102.026L93.0117 108.643L62.1336 96.5092L123.531 78.1295Z" fill="#5284E0" />
      <path
        d="M196.691 50.5537C198.516 50.5537 200 52.0382 200 53.8623C200 55.6865 198.516 57.1709 196.691 57.1709C194.861 57.1709 193.383 55.6865 193.383 53.8623C193.383 52.0382 194.861 50.5537 196.691 50.5537Z"
        fill="#B5DBFF"
      />
      <path
        d="M178.305 179.23C180.136 179.23 181.614 180.715 181.614 182.539C181.614 184.363 180.136 185.848 178.305 185.848C176.481 185.848 174.991 184.363 174.991 182.539C174.997 180.715 176.481 179.23 178.305 179.23Z"
        fill="#B5DBFF"
      />
      <path
        d="M49.6289 0.182617C51.4593 0.182617 52.9375 1.66708 52.9375 3.49121C52.9375 5.32163 51.4593 6.7998 49.6289 6.7998C47.8048 6.7998 46.3203 5.31534 46.3203 3.49121C46.3203 1.66708 47.8048 0.182617 49.6289 0.182617Z"
        fill="#B5DBFF"
      />
      <path
        d="M49.6289 188.42C51.4593 188.42 52.9375 189.898 52.9375 191.729C52.9375 193.559 51.4593 195.037 49.6289 195.037C47.8048 195.037 46.3203 193.559 46.3203 191.729C46.3203 189.898 47.8048 188.42 49.6289 188.42Z"
        fill="#B5DBFF"
      />
      <path
        d="M5.88124 39.1561C7.70537 39.1561 9.18984 40.6342 9.18984 42.4584C9.18984 44.2888 7.70537 45.767 5.88124 45.767C4.05082 45.767 2.57265 44.2825 2.57265 42.4584C2.57265 40.6405 4.05082 39.1561 5.88124 39.1561Z"
        fill="#B5DBFF"
      />
      <path
        d="M100 38.7912C98.8929 38.7912 97.7922 37.6842 97.7922 36.5834V6.80609C97.7922 5.69903 98.8929 4.59827 100 4.59827C101.101 4.59827 102.208 5.69903 102.208 6.80609V36.5834C102.208 38.0553 101.101 38.7912 100 38.7912Z"
        fill="#04246B"
      />
      <path
        d="M100 199.818C98.8929 199.818 97.7922 198.717 97.7922 197.61V168.933C97.7922 167.833 98.8929 166.726 100 166.726C101.101 166.726 102.208 167.833 102.208 168.933V197.61C102.208 198.717 101.101 199.818 100 199.818Z"
        fill="#04246B"
      />
      <path
        d="M195.22 104.963H165.807C164.706 104.963 163.599 103.862 163.599 102.762C163.599 101.655 164.706 100.554 165.807 100.554H195.22C196.327 100.554 197.427 101.661 197.427 102.762C197.427 103.862 196.691 104.963 195.22 104.963Z"
        fill="#04246B"
      />
      <path
        d="M33.8219 104.963H4.41565C3.30859 104.963 2.20782 103.862 2.20782 102.762C2.20782 101.655 3.31488 100.554 4.41565 100.554H33.8219C34.9289 100.554 36.0297 101.661 36.0297 102.762C36.0297 103.862 35.2938 104.963 33.8219 104.963Z"
        fill="#04246B"
      />
      <path d="M123.531 78.1295L105.145 139.521L93.0117 108.643L123.531 78.1295Z" fill="#04246B" />
    </svg>
  );
};

const NotFound = () => {
  return (
    <NotFoundContainer>
      <h2>Don't worry your internet is fine, but we can't find what you are looking for.</h2>
      <IconContainer>
        <h1>4</h1>
        <Icon />
        <h1>4</h1>
      </IconContainer>
      <h4>Would you like to do a search for a house instead?</h4>
      <SearchBar />
    </NotFoundContainer>
  );
};

export default NotFound;
