import { checkIsLoggedIn } from '../../../utils/common';
import { useNavigate } from "react-router-dom";
import routes from '../../../routes';
import { useEffect } from 'react';

const AuthChecker = () => {
    const navigate = useNavigate();
    function checkAuth() {
        if (!checkIsLoggedIn()) {
            navigate(routes.login());
        }
    }

    useEffect(() => {
        checkAuth()
    }, []);
};

export default AuthChecker;