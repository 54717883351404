import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { MainContext } from '../../../context';
import { getAgent } from '../../../services/general';
import { COMMUNITY_AGENT } from './key';

export default function useGetCommunityAgent({ state, market, community, id }) {
  const { subdomainAgent, activeUser } = useContext(MainContext);

  return useQuery({
    queryKey: [COMMUNITY_AGENT, id],
    queryFn: async () => {
      if (subdomainAgent || (activeUser && activeUser.assigned_agent_id)) {
        return subdomainAgent;
      }

      const params = { state, market, community, id };

      const data = await getAgent(params);

      return data;
    },
    enabled: !!id,
  });
}
