import { styled } from '@mui/material';

const Section9Container = styled('section')(() => ({
  padding: '80px 0',
  textAlign: 'center',
  maxWidth: 1500,
  margin: '0 auto',
  h2: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '48px',
    color: '#04246B',
    maxWidth: 700,
    margin: '0 auto	',
  },
  h4: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 500,
  },
}));

const Section9 = () => {
  return (
    <div style={{ backgroundColor: '#ECECEE' }}>
      <Section9Container>
        <h2>80% OF HOME BUYERS NOW BEGIN THE BUYING PROCESS ONLINE</h2>
        <h4>National Association of Realtors</h4>
      </Section9Container>
    </div>
  );
};

export default Section9;
