import { styled } from '@mui/material';

const Section4Container = styled('section')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 1500,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '48px 64px',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '48px 0',
    paddingBottom: 0,
    gap: 64,
  },
  '& div.content': {
    order: 2,
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      order: 1,
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      maxWidth: 526,
      marginBottom: 32,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      maxWidth: 526,
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    order: 1,
    textAlign: 'center',
    flexBasis: '50%',
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
    img: {
      maxWidth: 526,
      borderRadius: 16,
      height: 500,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
        borderRadius: 0,
        height: 400,
      },
    },
  },
}));

const Section4 = () => {
  return (
    <div style={{ backgroundColor: '#F4F4F6' }}>
      <Section4Container>
        <div className="content">
          <div className="header">
            <img src="https://bucket.ywhdev.com/wl/?id=oIWVtQkZ1mZPBcEYai89RBguMgjnTUff&fmode=open" alt="" />
            <h2>Photo Flyer</h2>
          </div>
          <p>
            As quickly as possible after receiving your signed listing contract, we prepare and print a professional
            color flyer. We make a point of having flyers for all of our listings available at each of our open houses.
          </p>
        </div>
        <div className="image">
          <img
            src="https://bucket.ywhdev.com/wl/?id=EqF6PR73hjx2OqGCwfYjjlwvS25Vrv7h&fmode=open"
            alt="showing a beautiful 2-storey house."
          />
        </div>
      </Section4Container>
    </div>
  );
};

export default Section4;
