import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Clear all unnecessary objects from furniture throughout the house.',
  'Keep decorative objects on the furniture restricted to groups of 1, 3, or 5 items.',
  "Clear all unnecessary objects from the kitchen countertops. If it hasn't been used for three months...put it away!",
  'Clear refrigerator fronts of messages, pictures, etc. (A sparse kitchen helps buyers mentally move their own things into your kitchen.)',
  'In the bathroom, remove any unnecessary items from countertops, tubs, shower stalls and commode tops. Keep only your most needed cosmetis, brushes, perfumes, etc., in a one small group on the counter. Coordinate towels to one or two colors only.',
];

const Section5Container = styled('section')(({ theme }) => ({
  width: '100%',
  paddingBottom: 96,
  padding: 100,
  backgroundColor: '#F4F4F6',
  [theme.breakpoints.down('md')]: {
    padding: '64px 0 0 0',
  },
  '& div.header': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 32,
    textAlign: 'center',
    padding: '0 32px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      color: '#04246B',
      maxWidth: 800,
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
      },
    },
    h5: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: 64,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '64px 0 0 0',
    },
    ul: {
      maxWidth: 536,
      display: 'flex',
      gap: 24,
      padding: 0,
      flexShrink: 0,
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      width: '100%',
      textAlign: 'center',
      img: {
        objectFit: 'cover',
        height: 672,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 400,
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section5 = () => {
  return (
    <Section5Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="108" height="109" viewBox="0 0 108 109" fill="none">
          <circle cx="58" cy="59" r="48.5" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M75.7854 53.1005L56.6849 34L33 57.6849H38.2633V76.1064C38.2633 77.5024 38.8178 78.8411 39.8049 79.8282C40.792 80.8152 42.1307 81.3698 43.5266 81.3698H54.0532"
            stroke="#CDA07D"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M48.7896 81.3696V65.5797C48.7896 64.1838 49.3441 62.8451 50.3311 61.858C51.3182 60.8709 52.6569 60.3164 54.0529 60.3164H58.0003M71.9481 80.888L66.2321 83.8829C66.0625 83.9711 65.8717 84.0105 65.681 83.9967C65.4902 83.9828 65.3071 83.9162 65.1521 83.8042C64.997 83.6923 64.8761 83.5395 64.8029 83.3628C64.7297 83.1862 64.707 82.9927 64.7374 82.8039L65.8295 76.459L61.2057 71.9667C61.0677 71.8333 60.97 71.6638 60.9237 71.4775C60.8774 71.2912 60.8845 71.0956 60.944 70.9131C61.0036 70.7307 61.1132 70.5686 61.2605 70.4454C61.4077 70.3223 61.5866 70.243 61.7768 70.2167L68.1664 69.2903L71.0244 63.5191C71.1099 63.3472 71.2416 63.2026 71.4048 63.1015C71.568 63.0004 71.7561 62.9468 71.9481 62.9468C72.1401 62.9468 72.3282 63.0004 72.4914 63.1015C72.6546 63.2026 72.7863 63.3472 72.8718 63.5191L75.7298 69.2903L82.1194 70.2167C82.309 70.244 82.4872 70.3237 82.6338 70.447C82.7804 70.5703 82.8896 70.7321 82.949 70.9142C83.0084 71.0963 83.0157 71.2913 82.97 71.4774C82.9243 71.6634 82.8275 71.8329 82.6905 71.9667L78.0667 76.459L79.1562 82.8012C79.1889 82.9904 79.1679 83.1849 79.0956 83.3627C79.0233 83.5405 78.9026 83.6944 78.7472 83.8071C78.5918 83.9197 78.4079 83.9865 78.2165 83.9999C78.025 84.0133 77.8336 83.9728 77.664 83.8829L71.9481 80.888Z"
            stroke="#CDA07D"
            stroke-width="8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h2>Home Updates to make Inside</h2>
      </div>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=Cun8UQIhA669TqFwFTEvLecXkQPaofPA&fmode=open" alt="" />
        </div>
      </div>
    </Section5Container>
  );
};

export default Section5;
