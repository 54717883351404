import { styled } from '@mui/material';
import React from 'react';

const CARD_ITEMS = [
  {
    title: 'THE FRENCH COUNTRY',
    image: 'https://bucket.ywhdev.com/wl/?id=bmwW06R1siFG0k9KzXJHzmail9H035Yn&fmode=open',
  },
  {
    title: 'THE COLORADO MOUNTAIN',
    image: 'https://bucket.ywhdev.com/wl/?id=SCK5YyzcnX58jN8YrIWUiqHM9YZK1lww&fmode=open',
  },
  {
    title: 'THE FARMHOUSE',
    image: 'https://bucket.ywhdev.com/wl/?id=q7ZDvscWYqVxvm3r4OZ6R3t8PBpgAxrr&fmode=open',
  },
  {
    title: 'THE CRAFTSMAN',
    image: 'https://bucket.ywhdev.com/wl/?id=TOAnAcikmncGcVZYKHnn1mzZcnXTKL7H&fmode=open',
  },
  {
    title: 'THE EUROPEAN',
    image: 'https://bucket.ywhdev.com/wl/?id=bf70oKu3LsvmQ3flwhoBus3uuhtPBPLb&fmode=open',
  },
  {
    title: 'THE MOUNTAIN MODERN',
    image: 'https://bucket.ywhdev.com/wl/?id=qRtUlQRNJdtaCwsVQmavKSXjck1j43QJ&fmode=open',
  },
  {
    title: 'THE EAST COAST MANOR',
    image: 'https://bucket.ywhdev.com/wl/?id=XjHdwx204F4IMq7MnsJCuBfoZ6kBOkp7&fmode=open',
  },
  {
    title: 'THE MODERN PRAIRIE',
    image: 'https://bucket.ywhdev.com/wl/?id=4eXkonuaCtqB7AUYwdyWXRne6H3qydOY&fmode=open',
  },
  {
    title: 'THE MOUNTAIN INDUSTRIAL',
    image: 'https://bucket.ywhdev.com/wl/?id=oVKBRNxiz7X3S7MbrL2roTAFdK0e901e&fmode=open',
  },
];

const Section2Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 100,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '80px 0',
  backgroundColor: '#F4F4F6',
  [theme.breakpoints.down('md')]: {
    paddingBottom: 80,
  },
  '& div.header': {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    alignItems: 'center',
    maxWidth: 700,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '48px',
      color: '#04246B',
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
  '& div.content': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    flexFlow: 'row wrap',
    maxWidth: 832,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
  },
  '& div.subheader': {
    marginBottom: 40,
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    img: {
      height: 60,
      width: 60,
    },
    h6: {
      paddingLeft: 16,
      borderLeft: '2px solid #CDA07D',
    },
  },
}));

const Section2Card = styled('div')(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down('md')]: {
    width: 288,
  },
  img: {
    borderRadius: '16px 16px 0 0',
    width: '100%',
    height: 224,
    objectFit: 'cover',
  },
  p: {
    borderRadius: '0 0 16px 16px',
    padding: '24px 0',
    backgroundColor: '#04246B',
    color: 'white',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '28px',
    textAlign: 'center',
  },
}));

const Card = ({ title, image }) => (
  <Section2Card>
    <img src={image} alt="" />
    <p>{title}</p>
  </Section2Card>
);

const Section2 = () => {
  return (
    <Section2Container>
      <div className="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101" fill="none">
          <circle cx="51" cy="51" r="48.5" stroke="#CDA07D" stroke-width="3" />
          <path
            d="M31.4615 35.9231C30.5435 35.9231 29.663 36.2878 29.0139 36.9369C28.3647 37.5861 28 38.4666 28 39.3846V70.5385C28 71.4565 28.3647 72.337 29.0139 72.9861C29.663 73.6353 30.5435 74 31.4615 74H69.5385C70.4565 74 71.337 73.6353 71.9861 72.9861C72.6353 72.337 73 71.4565 73 70.5385V39.3846C73 38.4666 72.6353 37.5861 71.9861 36.9369C71.337 36.2878 70.4565 35.9231 69.5385 35.9231H62.6154M38.3846 29V42.8462M62.6154 29V42.8462M38.3846 35.9231H55.6923"
            stroke="#CDA07D"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M60.8843 56.6927L50.4997 46.3081L40.1151 56.6927"
            stroke="#CDA07D"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M43.578 53.231V65.3463H57.4242V53.231"
            stroke="#CDA07D"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h2>Take A Look At Our Custom Homes</h2>
        <h4>
          Interior spaces should be as unique as the family that inhabits them and be inspired by that family’s
          individuality. We make good design a hallmark of the spaces we create.
        </h4>
      </div>
      <div className="content">
        {CARD_ITEMS.map(({ title, image }) => (
          <Card title={title} image={image} />
        ))}
      </div>
    </Section2Container>
  );
};

export default Section2;
