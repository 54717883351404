import { styled } from '@mui/material';

const Section2Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '80px 32px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F4F4F6',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& div.content': {
    order: 2,
    width: 526,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      width: 446,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      order: 1,
    },
    '& div.header': {
      marginBottom: 64,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 16,
      padding: '0 64px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    order: 1,
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 676,
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      objectFit: 'cover',
      objectPosition: 'top',
      [theme.breakpoints.down('lg')]: {
        height: 723,
      },
      [theme.breakpoints.down('md')]: {
        width: 500,
        height: 400,
        order: 2,
      },
    },
  },
}));

const Section2 = () => {
  return (
    <Section2Container>
      <div className="content">
        <div className="header">
          <img src="https://bucket.ywhdev.com/wl/?id=rU4Y5yS8HwXPitebPg06Q9syvSZOCWTW&fmode=open" alt="" />
          <h2>Trusted Experts</h2>
          <h4>One of our experienced new home Realtors will represent you through the process.</h4>
        </div>
        <p>
          We work with just about every homebuilder in the United States and the home price is typically the same
          whether you have us as your representative, or you buy directly from the builder's sales agent. <br />
          <br />
          The home buying process often has a unique set of opportunities and challenges and can be stressful. By
          setting up a FREE buyer's consultation with the Your Way Home Team, we'll establish the expectations upfront
          on both sides, laying the foundation for a happy client/agent relationship.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=ykk9PxlZVxQTDrq64Xvw67He8ObfuNyG&fmode=open"
          alt="showing a beautiful 2-storey house."
        />
      </div>
    </Section2Container>
  );
};

export default Section2;
