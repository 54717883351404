import { styled } from '@mui/material';

const LIST_ITEMS = [
  `Try to look at your house "through the buyer's eyes" as though you've never seen it or been there before.`,
  'Any time or money spent on these items will bring you back more money in return, and hopefully a faster sale.',
];

const Section9Container = styled('section')(({ theme }) => ({
  width: '100%',
  maxWidth: 1172,
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    padding: '0 64px',
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  '& div.content': {
    display: 'flex',
    alignItems: 'center',
    gap: 120,
    backgroundColor: '#D8D8D8',
    paddingLeft: 64,
    borderRadius: 16,
    [theme.breakpoints.down('md')]: {
      padding: '0 64px',
      borderRadius: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.list': {
      width: 494,
      height: 496,
      padding: '80px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: 64,
      [theme.breakpoints.down('md')]: {
        flex: 1,
        height: '100%',
      },
      h2: {
        fontSize: 32,
        lineHeight: '48px',
        fontWeight: 700,
        color: '#04246B',
      },
      ul: {
        width: '100%',
        display: 'flex',
        gap: 24,
        padding: 0,
        flexShrink: 0,
        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
          flexShrink: 1,
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: 700,
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: '90%'
        },
        li: {
          fontSize: 20,
          lineHeight: '32px',
          display: 'flex',
          alignItems: 'flex-start',
          gap: 16,
          svg: {
            height: 25,
            width: 25,
            flexShrink: 0,
            marginTop: 4,
          },
        },
      },
    },
    '& div.image': {
      width: 494,
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      img: {
        objectFit: 'cover',
        height: 496,
        maxWidth: '100%',
        borderRadius: '0 16px 16px 0',
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      },
    },
  },
}));

const Section9 = () => {
  return (
    <Section9Container>
      <div className="content">
        <div className="list">
          <h2>In General</h2>
          <ul>
            {LIST_ITEMS.map((item) => (
              <li key={item}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                    fill="#5284E0"
                  />
                </svg>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=Cun8UQIhA669TqFwFTEvLecXkQPaofPA&fmode=open" alt="" />
        </div>
      </div>
    </Section9Container>
  );
};

export default Section9;
