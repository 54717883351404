export const INIT_DATA = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  brokerage: '',
  company_website: '',
  personal_website: '',
  company_phone: '',
  mobile_phone: '',
  photo: '',
  title: '',
  description: '',
  mls: [{ name: '', code: '', office: '' }],
  licenses: [{ license: '', state: '' }],
  ywh_ext_num: '',
  team_name: '',
  agent_type: '',
  cities_served: [],
  states_served: [],
  communities_served: [],
  office_address: '',
  compensation_plan: '90-10',
  showingnew_identifier: '',
  subdomain: '',
  enabled_subdomain: false
};

export const INIT_DATA_OFFICER = {
  first_name: '',
  last_name: '',
  office_phone: '',
  direct_phone: '',
  mobile_phone: '',
  email: '',
  personal_email: '',
  chime_user_id: '',
  nmls: '',
  state_licenses: [],
  personal_website_url: '',
  loan_application_url: '',
  linkedin_url: '',
  facebook_url: '',
  instagram_url: '',
  tiktok_url: '',
  twitter_url: '',
  office_address: '',
  office_city: '',
  office_state: '',
  office_zip: '',
  company_name: '',
  company_nmls: '',
  company_license_disclosure: '',
  company_office_address: '',
  company_office_city: '',
  company_office_state: '',
  company_office_zip: '',
  company_url: '',
  branch_name: '',
  manager_name: '',
  recruited_by: '',
  compensation_plan: '',
  onboarded_date: '',
  headshot: '',
  assistant_first_name: '',
  assistant_last_name: '',
  assistant_email: '',
  assistant_direct_phone: '',
  primary_zip_codes: '',
  assigned_agents: [],
  description: '',
};
