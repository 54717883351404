import { styled } from '@mui/material';

const Section3Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '64px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '0 32px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '0 1rem',
  },
  '& div.content': {
    width: 526,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    [theme.breakpoints.down('lg')]: {
      width: 446,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    h3: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 500,
      textAlign: 'justify',
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 500,
      boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      objectFit: 'cover',
    },
  },
}));

const Section3 = () => {
  return (
    <Section3Container>
      <div className="content">
        <h3>
          Since 1992, Andrew Batson and our team of Realtors has been helping folks with one of the most important
          purchases of their life.
        </h3>
        <p>
          There's a lot more to buying a home than shopping for the perfect kitchen or floorplan. We'll help you analyze
          comparable sales to assist you in making a wise financial decision. Our agents will connect you with
          experienced inspectors to be sure you're buying a dream home and not a maintenance nightmare. There's a lot to
          buying a house that doesn't meet the eye, from expansive soils, settling soils, lead-based paint, easements,
          encroachments, CC&Rs, special taxing districts, and more so it's best to have a professional on your side.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=pWn6RououZVjHnkiBQ2zhEMgkAJLPooW&fmode=open"
          alt="showing Mr. Andrew Batson, owner of YourWayHome LLC."
        />
      </div>
    </Section3Container>
  );
};

export default Section3;
