import { useState } from 'react'
import styled from 'styled-components'
import { AGENT_PROFILE_URL } from '../../constants/common'


export const AgentsContainer = styled.div`
    display: flex;
    padding: 160px 64px;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    flex: 1 0 0;
`

export const AgentsSearchBar = styled.div`
    display: flex;
    min-width: 800px;
    max-width: 800px;
    width: 100%;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    border-radius: 8px;
    border: 1px solid #A8A8A8;
    background: #FFF;
    & .icon-wrapper {
        display: flex;
        padding-right: 16px;
        align-items: center;
        align-self: stretch;
        border-right: 1px solid #A8A8A8;
    }

    & input {
        border: none;
        appearance: none;
        flex: 1;
        &:focus {
            outline: none;
        }
    }

    & button {
        border-radius: 100px;
        background-color: #04246B;
        display: flex;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        color: #FFF;
    }
`

export const AgentsList = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 64px;
    align-self: stretch;
    flex-wrap: wrap;
`
export const AgentCard = styled.div`
    display: flex;
    width: 50%;
    min-height: 300px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 16px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    @media (min-width: 564px) {
        width: 250px;
    }

    & .action-icons-wrapper {
        display: flex;
        padding: 8px 8px 0px 0px;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 8px;
        position: absolute;
        right: 0px;
        top: 0px; 
        & .icon-wrapper {
            display: flex;
            padding: 10px;
            border-radius: 50%;
            justify-content: flex-end;
            align-items: flex-start;
            border-radius: 50%;
            background-color: #fff;
        }
    }
`

export const AgentCardDetails = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    background: #04246B;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    min-height: 75px;

    & h3 {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    & p {
        color: #bcbcbc;
        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;        
    }
  
`

export const AgentImage = styled.div`
    width: 100%;
    min-height: 225px;
    background-color: #A8A8A8;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    & img {
        width: 100%;
        object-fit: cover;
        object-position: top center;
        height: 225px;
    }
`

const Agents = ({agents, handleDelete, showDeleteAgent}) => {
    const [keyword, setKeyword] = useState('')
    return (
        <AgentsContainer>
            <AgentsSearchBar>
                <div className='icon-wrapper'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                    <path d="M16.9988 14.0059C18.8554 14.0059 20.6358 13.2684 21.9486 11.9556C23.2613 10.6429 23.9988 8.86238 23.9988 7.00586C23.9988 5.14934 23.2613 3.36887 21.9486 2.05611C20.6358 0.743357 18.8554 0.00585938 16.9988 0.00585938C15.1423 0.00585938 13.3618 0.743357 12.0491 2.05611C10.7363 3.36887 9.99884 5.14934 9.99884 7.00586C9.99884 8.86238 10.7363 10.6429 12.0491 11.9556C13.3618 13.2684 15.1423 14.0059 16.9988 14.0059ZM16.9988 28.0059C16.6618 28.0059 16.3288 27.9959 15.9988 27.9789C15.9925 27.3253 15.7296 26.7004 15.2668 26.2389L12.7668 23.7389C13.2368 22.7589 13.4988 21.6619 13.4988 20.5059C13.5013 18.8827 12.9747 17.3029 11.9988 16.0059H25.4988C26.4271 16.0059 27.3173 16.3746 27.9737 17.031C28.6301 17.6874 28.9988 18.5776 28.9988 19.5059V20.0059C28.9988 22.3989 27.4758 24.4229 25.3138 25.7989C23.1398 27.1829 20.1968 28.0059 16.9988 28.0059ZM5.99884 26.5059C7.29484 26.5059 8.49484 26.0959 9.47484 25.3959L12.7918 28.7129C12.9804 28.895 13.233 28.9958 13.4952 28.9935C13.7574 28.9913 14.0082 28.8861 14.1937 28.7007C14.3791 28.5153 14.4842 28.2645 14.4865 28.0023C14.4888 27.7401 14.388 27.4875 14.2058 27.2989L10.8898 23.9829C11.6693 22.8867 12.0578 21.5604 11.993 20.2169C11.9283 18.8734 11.414 17.5907 10.5327 16.5746C9.65139 15.5585 8.45425 14.868 7.13342 14.6139C5.8126 14.3598 4.44471 14.5569 3.24934 15.1735C2.05397 15.7901 1.10046 16.7905 0.541876 18.0141C-0.0167086 19.2377 -0.147968 20.6134 0.169171 21.9206C0.486309 23.2277 1.23345 24.3903 2.29065 25.2219C3.34785 26.0534 4.6538 26.5056 5.99884 26.5059ZM5.99884 24.5059C4.93797 24.5059 3.92056 24.0844 3.17041 23.3343C2.42027 22.5841 1.99884 21.5667 1.99884 20.5059C1.99884 19.445 2.42027 18.4276 3.17041 17.6774C3.92056 16.9273 4.93797 16.5059 5.99884 16.5059C7.0597 16.5059 8.07712 16.9273 8.82727 17.6774C9.57741 18.4276 9.99884 19.445 9.99884 20.5059C9.99884 21.5667 9.57741 22.5841 8.82727 23.3343C8.07712 24.0844 7.0597 24.5059 5.99884 24.5059Z" fill="#A8A8A8"/>
                    </svg>
                </div>
                <input type="text" onChange={(e) => setKeyword(e.target.value.toLowerCase())} placeholder='Search'/>
                <button onClick={() => setKeyword(keyword) }>SEARCH</button>
            </AgentsSearchBar>
            {agents && agents.length > 0 && 
            <AgentsList>
                {agents.filter(agent => 
                    agent.first_name.toLowerCase().includes(keyword) ||
                    agent.last_name.toLowerCase().includes(keyword) ||
                    agent.brokerage.toLowerCase().includes(keyword)
                    ).map(agent => {
                    return (
                       <AgentCard key={agent.id}>
                            {showDeleteAgent && 
                            <div className='action-icons-wrapper'>
                            
                            <span className='icon-wrapper' onClick={() => window.location.href='/admin/agent/edit/'+agent.id}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5438 0.630137C13.9474 0.226661 14.4948 0 15.0654 0C15.6361 0 16.1835 0.226661 16.5871 0.630137L19.3699 3.41294C19.7733 3.81654 20 4.36386 20 4.93455C20 5.50524 19.7733 6.05256 19.3699 6.45616L17.6632 8.16286L11.8371 2.33684L13.5438 0.630137ZM10.3155 3.85845L0.630597 13.5434C0.226952 13.9469 0.000121895 14.4942 0 15.065V17.8478C0 18.4186 0.22675 18.966 0.630367 19.3696C1.03398 19.7732 1.58141 20 2.15221 20H4.93501C5.50576 19.9999 6.0531 19.773 6.45662 19.3694L16.1416 9.68447L10.3155 3.85845Z" fill="#04246B"/>
                                </svg>
                            </span> 
                            <span className='icon-wrapper' onClick={() => handleDelete(agent)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8 3.41463H12C12 2.89714 11.7893 2.40084 11.4142 2.03491C11.0391 1.66899 10.5304 1.46341 10 1.46341C9.46957 1.46341 8.96086 1.66899 8.58579 2.03491C8.21071 2.40084 8 2.89714 8 3.41463ZM6.5 3.41463C6.5 2.96622 6.59053 2.52219 6.76642 2.10791C6.94231 1.69363 7.20012 1.3172 7.52513 1.00012C7.85013 0.683045 8.23597 0.431525 8.66061 0.259924C9.08525 0.0883221 9.54037 0 10 0C10.4596 0 10.9148 0.0883221 11.3394 0.259924C11.764 0.431525 12.1499 0.683045 12.4749 1.00012C12.7999 1.3172 13.0577 1.69363 13.2336 2.10791C13.4095 2.52219 13.5 2.96622 13.5 3.41463H19.25C19.4489 3.41463 19.6397 3.49172 19.7803 3.62895C19.921 3.76617 20 3.95228 20 4.14634C20 4.3404 19.921 4.52652 19.7803 4.66374C19.6397 4.80096 19.4489 4.87805 19.25 4.87805H17.93L16.76 16.6937C16.6702 17.599 16.238 18.4393 15.5477 19.0506C14.8573 19.6619 13.9583 20.0003 13.026 20H6.974C6.04186 20.0001 5.1431 19.6615 4.45295 19.0502C3.7628 18.439 3.33073 17.5988 3.241 16.6937L2.07 4.87805H0.75C0.551088 4.87805 0.360322 4.80096 0.21967 4.66374C0.0790175 4.52652 0 4.3404 0 4.14634C0 3.95228 0.0790175 3.76617 0.21967 3.62895C0.360322 3.49172 0.551088 3.41463 0.75 3.41463H6.5ZM8.5 8.04878C8.5 7.85472 8.42098 7.66861 8.28033 7.53139C8.13968 7.39416 7.94891 7.31707 7.75 7.31707C7.55109 7.31707 7.36032 7.39416 7.21967 7.53139C7.07902 7.66861 7 7.85472 7 8.04878V15.3659C7 15.5599 7.07902 15.746 7.21967 15.8833C7.36032 16.0205 7.55109 16.0976 7.75 16.0976C7.94891 16.0976 8.13968 16.0205 8.28033 15.8833C8.42098 15.746 8.5 15.5599 8.5 15.3659V8.04878ZM12.25 7.31707C12.0511 7.31707 11.8603 7.39416 11.7197 7.53139C11.579 7.66861 11.5 7.85472 11.5 8.04878V15.3659C11.5 15.5599 11.579 15.746 11.7197 15.8833C11.8603 16.0205 12.0511 16.0976 12.25 16.0976C12.4489 16.0976 12.6397 16.0205 12.7803 15.8833C12.921 15.746 13 15.5599 13 15.3659V8.04878C13 7.85472 12.921 7.66861 12.7803 7.53139C12.6397 7.39416 12.4489 7.31707 12.25 7.31707Z" fill="#04246B"/>
                                </svg>
                            </span> 
                            </div>}
                            <AgentImage onClick={() => window.location.href = '/admin/agent/'+agent.id}>
                                {agent.photo && <img src={`${AGENT_PROFILE_URL}${agent.photo}`}  onError={(e) => e.target.remove()} alt="agent" /> }
                            </AgentImage>
                            <AgentCardDetails onClick={() => window.location.href = '/admin/agent/'+agent.id}>
                                <h3>{agent.first_name} {agent.last_name}</h3>
                                <p>{agent.mobile_phone}</p>
                                <p>{agent.brokerage}</p>
                    
                            </AgentCardDetails>
                        </AgentCard>
                    )
                })}
            </AgentsList>}
           
        </AgentsContainer>
    )
}
export default Agents
