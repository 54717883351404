import { styled } from '@mui/material';

const Section1Container = styled('div')(({ theme }) => ({
  paddingTop: 80,
  maxWidth: 900,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& div.header': {
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: 120,
    gap: 32,
    [theme.breakpoints.down('md')]: {
      marginBottom: 80,
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    svg: {
      height: 100,
      width: 100,
    },
    h2: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: '40px',
      color: '#04246B',
      textAlign: 'center',
    },
  },
  '& div.description': {
    borderRadius: 16,
    padding: 32,
    backgroundColor: '#04246B',
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '36px',
      color: 'white',
      textAlign: 'center',
    },
  },
}));

const Section1 = () => (
  <Section1Container>
    <div className="header">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
        <circle cx="50" cy="51" r="48.5" stroke="#CDA07D" stroke-width="3" />
        <path
          d="M62.2727 60.0523L70.0727 64.6507L68.0273 67.9702L58.1818 62.093V48.4884H62.2727V60.0523ZM80 59.3721C80 69.9021 71.4636 78.4186 60.9091 78.4186C54.4182 78.4186 48.7182 75.1807 45.2545 70.2558H28.1818V48.4884H20L47.2727 24L66.3636 41.1147C74.2455 43.4547 80 50.7467 80 59.3721ZM42.6091 64.814C42.1182 63.0998 41.8182 61.2767 41.8182 59.3721C41.8182 49.9577 48.6364 42.1758 57.6364 40.6249L47.2727 31.3193L33.6364 43.5635V64.814H42.6091ZM74.5455 59.3721C74.5455 51.8623 68.4364 45.7674 60.9091 45.7674C53.3818 45.7674 47.2727 51.8623 47.2727 59.3721C47.2727 66.8819 53.3818 72.9767 60.9091 72.9767C68.4364 72.9767 74.5455 66.8819 74.5455 59.3721Z"
          fill="#CDA07D"
        />
      </svg>
      <h2>Occasionally you will receive a call to schedule a showing to take place within the next few minutes.</h2>
    </div>
    <div className="description">
      <h4>The following is a checklist for this type of panic:</h4>
    </div>
  </Section1Container>
);

export default Section1;
