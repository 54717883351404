import { styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const CARD_ITEMS = [
  {
    id: 1,
    title: 'Appraisal Fee',
    content:
      'This fee pays for the appraisal of the property. You may already have paid this fee at the beginning of your loan application process.',
    flexBasis: '45%',
  },
  {
    id: 2,
    title: 'Credit Report Fee',
    content:
      'This fee covers the cost of the credit report requested by the lender. This too may already have been paid when you applied for your loan.',
    flexBasis: '35%',
  },
  {
    id: 3,
    title: 'Loan Origination Fee',
    content:
      "This fee covers the lender's loan-processing costs. The fee is typically one percent of the total mortgage.",
    flexBasis: '30%',
  },
  {
    id: 4,
    title: 'Loan Discount',
    content:
      'You will pay this one-time charge if you choose to pay points to lower your interest rate. Each point you purchase equals 1% of the total loan.',
    flexBasis: '35%',
  },
  {
    id: 5,
    title: 'Title Insurance Fees',
    content:
      'These fees generally include costs for the title search, title examination, title insurance, document preparation, and other miscellaneous title fees.',
    flexBasis: '45%',
  },
  {
    id: 6,
    title: 'Credit Report Fee',
    content:
      'If you buy a home under 20% down payment, a lender usually requires that you pay a fee for mortgage insurance. This fee protects the lender against loss due to foreclosure.',
    flexBasis: '35%',
  },
  {
    id: 7,
    title: 'Prepaid Interest Fee',
    content:
      'This fee covers the interest payment from the date you purchase the home to the date of your first mortgage payment.',
    flexBasis: '30%',
  },
  {
    id: 8,
    title: 'Recording Fee & Taxes',
    content:
      'This expense is charged by the most states for recording the purchase documents and transferring ownership of the property.',
    flexBasis: '30%',
  },
  {
    id: 9,
    title: 'Escrow Accounts',
    content:
      "In locations where escrow accounts are common, a mortgage lender will usually start an account that holds funds for future annual property taxes and home insurance. At least one year advance plus two months worth of homeowner's insurance premium will be collected. In addition, taxes equal approximately to two months in excess of the number of months that have elapsed in the year are paid at closing.",
    flexBasis: '55%',
  },
];

const Section3Container = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 80,
  marginBottom: 64,
  padding: '0 80px',
  [theme.breakpoints.down('md')]: {
    padding: '0 1rem',
  },
  '& div.header': {
    display: 'flex',
    width: '100%',
    gap: 32,
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 1000,
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 536,
    },
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      color: '#04246B',
      fontWeight: 700,
    },
    img: {
      height: 100,
      width: 100,
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
}));

const Section3CardContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: 24,
  flexWrap: 'wrap',
  maxWidth: 1500,
  margin: '0 auto',
}));

const Section3Card = styled('div')(() => ({
  borderRadius: 16,
  border: '1px solid #CDA07D',
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  textAlign: 'center',
  '& div.title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h1: {
      fontSize: 96,
      fontWeight: 700,
      lineHeight: '116px',
      color: '#7BA4E9',
    },
    img: {
      height: 54,
      width: 42,
    },
  },
  h3: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 600,
  },
  p: {
    fontSize: 18,
    lineHeight: '28px',
  },
}));

const Section3 = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Section3Container>
      <div className="header">
        <img src="https://bucket.ywhdev.com/wl/?id=1SrYEeNpIp7GDEBwMFhKeooHikJJXl60&fmode=open" alt="" />
        <h2>Common Fees</h2>
        <h4>Although many of the fees may vary by locality, here are some of the common fees.</h4>
      </div>
      <Section3CardContainer>
        {CARD_ITEMS.map((item) => (
          <Section3Card key={item.id} style={{ flexBasis: xsScreen ? '90%' : smScreen ? '45%' : item.flexBasis }}>
            <div className="title">
              <h1>{item.id}</h1>
              <img src="https://bucket.ywhdev.com/wl/?id=gSJ7QLGReaUyzZN0T08tArULrDjI8aai&fmode=open" alt="" />
            </div>
            <h3>{item.title}</h3>
            <p>{item.content}</p>
          </Section3Card>
        ))}
      </Section3CardContainer>
    </Section3Container>
  );
};

export default Section3;
