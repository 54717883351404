export const parseJwt = (token) => {
    if (token) {
        return JSON.parse(atob(token.split('.')[1]));
    }
    return null;
};

export const checkTokenIsExpired = (token) => {
    const decodedJwt = parseJwt(token);
    if (decodedJwt) {
        if (decodedJwt.exp * 1000 < Date.now()) {
            return true;
        }
    }
    return false;
};
