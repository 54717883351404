import { ACTION_TYPES } from './const';

const initialState = {
    authUserInfo: null,
    loading: null
};

function generalReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_AUTH_USER_INFO:
            return { ...state, loading: 'fetching' };
        case ACTION_TYPES.GET_AUTH_USER_INFO_SUCCESS:
            return { ...state, ...action.payload, loading: 'done' };
        case ACTION_TYPES.GET_AUTH_USER_INFO_FAILED:
            return { ...state, loading: 'done' };
        default:
            return state;
    }
}

export default generalReducer;
