import { memo, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MainContext } from '../../../context';
import routes from '../../../routes';
import { registerAgent, registerBuyer} from '../../../services/admin';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { Alert } from 'react-bootstrap';
import { Loader } from '../../../components/loader';
import {
  InputPassword,
  LoginFormContainer,
  LoginFormContents,
  LoginFormFieldWrapper,
  LoginFormFields,
  LoginFormFooter,
  LoginFormFooterCol,
  LoginFormFooterTerms,
  LoginFormForm,
  LoginFormHeading,
  LoginFormIcon,
  LoginFormImage,
  LoginFormWrapper,
} from '../Login/Login';
import { BackToLogin } from '../RequestPasswordResetForm/RequestPasswordResetForm';
import './RegisterUser.scss';

const RegisterUser = ({isBuyer = false}) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(MainContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [buyer, setBuyer] = useState(isBuyer);
  const [phone, setPhone] = useState('');
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const registerUser = async () => {
    setLoading(true);
    let params = {
      name: name,
      email: username,
      password: password,
      phone: phone,
      first_name: name,
    };
    try {
      let result
      if(isBuyer) {
        result = await registerBuyer(params);
      } else {
        result = await registerAgent(params);
      }
      if (result.token != null) {
        setSuccess('Registration successful. redirecting you to login page');
        if(isBuyer) setTimeout(() => navigate(routes.userLogin()), 2000);
        else setTimeout(() => navigate(routes.login()), 2000);
      } else {
        setError(result.message);
      }
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    registerUser();
  };

  useEffect(() => {
    setBuyer(isBuyer)
  }, [isBuyer])
  return (
    <LoginFormContainer>
      <LoginFormWrapper>
        <LoginFormImage src={mediumScreen ? '/login-form__image-md.webp' : '/login-form__image.webp'} />
        <LoginFormForm>
          <LoginFormHeading>
            <h3>Welcome to</h3>
            <h2>
              YOUR<span>WAY</span>HOME
            </h2>
          </LoginFormHeading>
          <LoginFormContents onSubmit={handleSubmit}>
            <LoginFormFields>
              <LoginFormFieldWrapper>
                <LoginFormIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 28" fill="none">
                    <path
                      d="M12 14C13.8565 14 15.637 13.2625 16.9497 11.9497C18.2625 10.637 19 8.85652 19 7C19 5.14348 18.2625 3.36301 16.9497 2.05025C15.637 0.737498 13.8565 0 12 0C10.1435 0 8.36301 0.737498 7.05025 2.05025C5.7375 3.36301 5 5.14348 5 7C5 8.85652 5.7375 10.637 7.05025 11.9497C8.36301 13.2625 10.1435 14 12 14ZM3.5 16C2.57174 16 1.6815 16.3687 1.02513 17.0251C0.368749 17.6815 0 18.5717 0 19.5V20C0 22.393 1.523 24.417 3.685 25.793C5.859 27.177 8.802 28 12 28C15.198 28 18.14 27.177 20.315 25.793C22.477 24.417 24 22.393 24 20V19.5C24 18.5717 23.6313 17.6815 22.9749 17.0251C22.3185 16.3687 21.4283 16 20.5 16H3.5Z"
                      fill="#A8A8A8"
                    />
                  </svg>
                </LoginFormIcon>
                <input type="text" name="name" required placeholder="Name" onChange={handleNameChange} />
              </LoginFormFieldWrapper>

              <LoginFormFieldWrapper>
                <LoginFormIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                    <path
                      d="M10.17 24.5C10.0569 24.1787 9.99944 23.8406 10 23.5V16.5C10 15.732 10.289 15.03 10.764 14.5H3C2.20435 14.5 1.44129 14.8161 0.87868 15.3787C0.316071 15.9413 0 16.7044 0 17.5V18.215C0 21.933 4.21 24.5 10 24.5H10.17ZM16 6.5C16 4.9087 15.3679 3.38258 14.2426 2.25736C13.1174 1.13214 11.5913 0.5 10 0.5C8.4087 0.5 6.88258 1.13214 5.75736 2.25736C4.63214 3.38258 4 4.9087 4 6.5C4 8.0913 4.63214 9.61742 5.75736 10.7426C6.88258 11.8679 8.4087 12.5 10 12.5C11.5913 12.5 13.1174 11.8679 14.2426 10.7426C15.3679 9.61742 16 8.0913 16 6.5ZM13.5 14.5C12.837 14.5 12.2011 14.7634 11.7322 15.2322C11.2634 15.7011 11 16.337 11 17V17.191L17.5 20.441L24 17.191V17C24 16.337 23.7366 15.7011 23.2678 15.2322C22.7989 14.7634 22.163 14.5 21.5 14.5H13.5ZM24 18.309L17.724 21.447C17.6545 21.4818 17.5778 21.5 17.5 21.5C17.4222 21.5 17.3455 21.4818 17.276 21.447L11 18.31V23C11 23.663 11.2634 24.2989 11.7322 24.7678C12.2011 25.2366 12.837 25.5 13.5 25.5H21.5C22.163 25.5 22.7989 25.2366 23.2678 24.7678C23.7366 24.2989 24 23.663 24 23V18.309Z"
                      fill="#A8A8A8"
                    />
                  </svg>
                </LoginFormIcon>
                <input type="email" name="username" required placeholder="Email" onChange={handleUsernameChange} />
              </LoginFormFieldWrapper>
              {isBuyer ? 
              <LoginFormFieldWrapper>
                <LoginFormIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                    <path
                      d="M10.17 24.5C10.0569 24.1787 9.99944 23.8406 10 23.5V16.5C10 15.732 10.289 15.03 10.764 14.5H3C2.20435 14.5 1.44129 14.8161 0.87868 15.3787C0.316071 15.9413 0 16.7044 0 17.5V18.215C0 21.933 4.21 24.5 10 24.5H10.17ZM16 6.5C16 4.9087 15.3679 3.38258 14.2426 2.25736C13.1174 1.13214 11.5913 0.5 10 0.5C8.4087 0.5 6.88258 1.13214 5.75736 2.25736C4.63214 3.38258 4 4.9087 4 6.5C4 8.0913 4.63214 9.61742 5.75736 10.7426C6.88258 11.8679 8.4087 12.5 10 12.5C11.5913 12.5 13.1174 11.8679 14.2426 10.7426C15.3679 9.61742 16 8.0913 16 6.5ZM13.5 14.5C12.837 14.5 12.2011 14.7634 11.7322 15.2322C11.2634 15.7011 11 16.337 11 17V17.191L17.5 20.441L24 17.191V17C24 16.337 23.7366 15.7011 23.2678 15.2322C22.7989 14.7634 22.163 14.5 21.5 14.5H13.5ZM24 18.309L17.724 21.447C17.6545 21.4818 17.5778 21.5 17.5 21.5C17.4222 21.5 17.3455 21.4818 17.276 21.447L11 18.31V23C11 23.663 11.2634 24.2989 11.7322 24.7678C12.2011 25.2366 12.837 25.5 13.5 25.5H21.5C22.163 25.5 22.7989 25.2366 23.2678 24.7678C23.7366 24.2989 24 23.663 24 23V18.309Z"
                      fill="#A8A8A8"
                    />
                  </svg>
                </LoginFormIcon>
                <input type="text" name="phone" required placeholder="Phone Number" onChange={handlePhoneChange} />
              </LoginFormFieldWrapper>
              
              :<InputPassword name="password" required onChange={handlePasswordChange} />}
            </LoginFormFields>
            <LoginFormFooter>
              {success && (
                <Alert className="w-100" variant="success">
                  {success}
                </Alert>
              )}
              {error && (
                <Alert className="w-100" variant="danger">
                  {error}
                </Alert>
              )}
              <LoginFormFooterCol>
                {loading && <Loader />}
                {!loading && <button type="submit">REGISTER</button>}
              </LoginFormFooterCol>
              {isBuyer ?
              <BackToLogin link={'/user/login'} />
              : <BackToLogin />}
              <LoginFormFooterTerms>
                By signing up, you agree to YourWayHome's <br /> <Link>Terms and Conditions</Link> &{' '}
                <Link>Privacy Policy</Link>.
              </LoginFormFooterTerms>
            </LoginFormFooter>
          </LoginFormContents>
        </LoginFormForm>
      </LoginFormWrapper>
    </LoginFormContainer>
  );
};

export default memo(RegisterUser);
