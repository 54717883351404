import { styled } from 'styled-components';
import { IconWrapper } from '../../components/agent';
import { Accordion } from 'react-bootstrap';
import Loader from '../../components/loader';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCommunityList } from '../../services/general';

const CommunitiesContainer = styled.div`
  display: flex;
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 120px;
  margin-top: ${(props) => (props.isLoggedIn ? '165px' : '0px')};
`;

const CommunitiesSearchBarWrapper = styled.div`
  display: flex;
  width: 738px;
  min-height: 50px;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
`;

const CommunitiesSearchFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  position: relative;

  & input {
    padding: 8px 24px;
    border-radius: 16px;
    border: 1px solid var(--ywh-alto, #d8d8d8);
    &::aplaceholder {
      color: var(--ywh-mercury, #e8e8e8);
    }
    /* ywh-caption */
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.54px; /* 162% */
    &:focus {
      outline: none;
    }
  }

  & ul {
    position: absolute;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 0px;
    width: 100%;
    top: 50px;
    z-index: 5;
    display: flex;
    gap: 4px;
    flex-direction: column;
    & li {
      cursor: pointer;
      padding: 4px 16px;
      &:hover {
        background-color: #f4f4f6;
      }
    }
  }
`;

const CommunitiesSearchIconWrapper = styled.div`
  display: flex;
  padding-right: 24px;
  align-items: center;
  align-self: stretch;
  border-right: 1px solid var(--ywh-silver-chalice, #a8a8a8);
`;
const CommunitiesSearchButton = styled.button`
  display: flex;
  padding: 16px 64px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--ywh-royal-blue, #04246b);
  color: #fff;

  /* ywh-button-text-large */
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 100% */
`;

const CommunitiesSearchFieldInput = ({ value, onChange, communities, setSelectedCommunity }) => {
  return (
    <CommunitiesSearchFieldWrapper className="input">
      <input
        type="text"
        className="w-100"
        value={value}
        onChange={onChange}
        placeholder="Search for State/City/Community"
      />
      {communities && communities.length > 0 && (
        <ul>
          {communities.map((community) => (
            <li onClick={() => setSelectedCommunity(community)} key={community.name}>
              {community.name},{community.city},{community.state}
            </li>
          ))}
        </ul>
      )}
    </CommunitiesSearchFieldWrapper>
  );
};
const CommunitiesWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1400px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--ywh-athens-gray, #f4f4f6);
`;

const StateNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 16px 0px 0px 16px;
  background: var(--ywh-azure, #5284e0);
`;
const StateNameWrapper = styled.div`
  display: flex;
  width: 400px;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px 0px 0px 16px;
  overflow: hidden;
`;
const StateName = styled.div`
  display: flex;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  padding-left: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--ywh-vista-blue, #7ba4e9);

  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  whitespace: nowrap;

  /* ywh-h4-bold */
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.5px; /* 150% */
  border-top: 1px solid var(--ywh-vista-blue, #7ba4e9);
  border-bottom: 1px solid var(--ywh-vista-blue, #7ba4e9);

  cursor: pointer;
`;

const StateNameSelected = styled(StateName)`
  overflow: hidden;
  color: var(--ywh-nero, #1f1f1f);
  text-overflow: ellipsis;
  whitespace: nowrap;

  /* ywh-h4-bold */
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.5px; /* 150% */
  background: var(--ywh-athens-gray, #f4f4f6);
  border-top: 1px solid #f4f4f6;
`;

const AccordionWrapper = styled.div`
  display: flex;
  padding: 80px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;

  & .accordion {
    width: 100%;

    & .accordion-item {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

      & .accordion-button {
        display: flex;
        height: 84px;
        padding: 24px 32px;
        align-items: flex-start;
        align-self: stretch;

        /* ywh-h5-bold */
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 150% */
        &:focus {
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
          border: none;
        }
        &:not(.collapsed),
        &:hover {
          color: #fff;
          background: var(--ywh-jet, #333);
        }
        &::after {
          display: none;
        }
      }

      & .accordion-body {
        background: var(--ywh-mercury, #e8e8e8);
      }
    }
  }
`;

const AccordionList = styled.div`
  display: flex;
  padding: 32px;
  align-items: flex-start;
  align-self: stretch;

  & ul li {
    color: var(--ywh-raisin-black, #2f2f2f);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 250% */
    list-style: disc;

    a {
      color: var(--ywh-raisin-black, #2f2f2f) !important;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 250% */
    }
  }
`;

const CityAccordionItem = ({city, state}) => {
  const [toggled, setToggled] = useState(false)
  return (
    <Accordion.Item eventKey={city}>
      <Accordion.Header  onClick={(e) => {
        if(e.target.classList.contains('collapsed') ) {
          setToggled(true)
        } else setToggled(false)
        return 
    } }>{city}</Accordion.Header>
      <Accordion.Body>
          <AccordionList>
            <CommunityList city={city} state={state} toggled={toggled} />
          </AccordionList>
        </Accordion.Body>
      
    </Accordion.Item>
  )
}

const CommunityList = ({ city, state, toggled}) => {
  const [communities, setCommunities] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const fetchCommunity = async () => {
    setIsLoading(true)
    const result = await getCommunityList({ market: city, state})
    if(result.result) {
      setTimeout(() =>  setIsLoading(false), 500 )
      setCommunities(result.communities)
    }
    
  }

  useEffect(() =>  {
    if(toggled && communities.length == 0) {
      fetchCommunity()
    }
  }, [toggled])

  return (
    <>
    {isLoading && <Loader />}
    {!isLoading && (<ul>
      {communities.map((community) => (
        <li>
          <Link
            to={
              '/community/' +
              community.slug.substring(0, 2) +
              '/' +
              _.kebabCase(city) +
              '/' +
              _.kebabCase(community.name) +
              '/' +
              community.id
            }
          >
            {community.name}
          </Link>
        </li>
      ))}
    </ul>)}
  </>)
}

const Communities = ({
  states,
  selectedState,
  setSelectedState,
  cities,
  onChange,
  isLoading,
  selectedCity,
  setSelectedCity,
  isLoggedIn,
  keyword,
  setKeyword,
  communities,
  setSelectedCommunity,
  handleSearch,
}) => {
  return (
    <CommunitiesContainer isLoggedIn={isLoggedIn}>
      <CommunitiesSearchBarWrapper>
        <CommunitiesSearchFieldInput
          value={keyword}
          onChange={setKeyword}
          communities={communities}
          setSelectedCommunity={setSelectedCommunity}
        />
        <CommunitiesSearchButton onClick={handleSearch}>SEARCH</CommunitiesSearchButton>
      </CommunitiesSearchBarWrapper>
      <CommunitiesWrapper>
        <StateNameContainer>
          <StateNameWrapper>
            {states.map((state, index) =>
              state.code === selectedState ? (
                <StateNameSelected key={index}>{state.name}</StateNameSelected>
              ) : (
                <StateName
                  key={index}
                  onClick={() => {
                    setSelectedCity(null);
                    setSelectedState(state.code);
                  }}
                >
                  {state.name}
                </StateName>
              ),
            )}
          </StateNameWrapper>
        </StateNameContainer>
        <AccordionWrapper>
          <Accordion
            activeKey={selectedCity}
            onSelect={(key) => {
              setSelectedCity(key);
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              !!cities.length &&
              cities.map((city, index) => {
                return (
                 <CityAccordionItem city={city.city} state={selectedState} index={index} />
                );
              })
            )}
          </Accordion>
        </AccordionWrapper>
      </CommunitiesWrapper>
    </CommunitiesContainer>
  );
};

export default Communities;
