import { styled } from '@mui/system';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';

const ChooseRealtorContainer = styled('div')(({ theme }) => ({
  padding: '100px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
}));

const HeroSection = styled('section')(({ theme }) => ({
  background: 'url("https://bucket.ywhdev.com/wl/?id=b9M7tiMGUyaMf1XWCrVv8X3AqzJR0Ae8&fmode=open")',
  overflow: 'hidden',
  height: 700,
  [theme.breakpoints.down('sm')]: {
    height: 450,
  },
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 80,
  position: 'relative',
  backgroundPosition: 'top center',
  backgroundSize: 'cover',
  borderBottom: '8px solid #5284e0',
  gap: 8,
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    backgroundPosition: 'center',
  },
  '& div.header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    h1: {
      color: 'white',
      fontFamily: 'Playfair Display',
      zIndex: 2,
      fontSize: 48,
      lineHeight: '60px',
      fontWeight: 700,
    },
    h3: {
      color: '#CDA07D',
      zIndex: 2,
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 600,
    },
  },
}));

const TeamHeader = styled('div')(({ theme }) => ({
  width: 585,
  textAlign: 'center',
  margin: '80px auto 64px',
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  img: {
    height: 100,
    width: 100,
  },
  h2: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '48px',
    color: '#04246B',
  },
}));

const ChooseRealtor = () => {
  return (
    <>
      <HeroSection>
        <div className="header">
          <h1>Choosing A Realtor</h1>
          <h3>Why you should work with the Your Way Home Team</h3>
        </div>
      </HeroSection>
      <ChooseRealtorContainer>
        <Section1 />
        <Section2 />
        <TeamHeader>
          <img src="https://bucket.ywhdev.com/wl/?id=pibImG6H5ZNCDhcoDVAkzyhnC6svf4iM&fmode=open" alt="" />
          <h2>Working With The Your Way Home Team</h2>
        </TeamHeader>
        <Section3 />
        <Section4 />
      </ChooseRealtorContainer>
    </>
  );
};

export default ChooseRealtor;
