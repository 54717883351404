import styled from 'styled-components'
import { AGENT_PROFILE_URL } from '../../constants/common'
import { 
    AgentPage,
    AgentDetail,
    AgentDetailExtra,
    AgentContactContainer,
    AgentContactWrapper,
    AgentContactListItem,
    AgentAdditionalDetailsContainer,
    AgentOfficeAddress,
    AgentCompanyWebsite,
    AgentCityStateServedContainer,
    AgentCityStateServed,
    AgentDetailsBio,
    AgentDetailsWrapper
} from '../../components/agent';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import _ from 'lodash';

const AgentImage = styled.img`
    width: 350px;
    height: 350px;
    border-radius: 100%;
    object-fit: cover;
    object-position: top center;

    @media (min-width: 1600px) {
        width: 450px;
        height: 450px; 
    }
`

const AgentDetailsName = styled.div`
    display: flex;
    width: calc(100% - 414px);
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    ${'' /* @media (min-width: 1600px) {
        width: calc(100% - 514px);
    } */}
    width: fit-content;
    & h1 {
        border-bottom: 2px solid var(--ywh-alto, #D8D8D8);
        color: var(--ywh-royal-blue, #04246B);
        /* ywh-h1 */
        font-family: Playfair Display;
        font-size: 59px;
        font-style: normal;
        font-weight: 700;
        line-height: 76.7px; /* 130% */
        padding-bottom: 16px;
    }
`

const AgentDetailsListWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    & label {
        min-width: 150px;
        max-width: 150px;
        flex: 1 0 0;
        color: var(--ywh-raisin-black, #2F2F2F);

        /* ywh-h5-bold */
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 150% */
    }

    & span {
        color: var(--ywh-raisin-black, #2F2F2F);
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px; /* 130% */

        &:last-child {
            font-weight: 400;
        }
    }
    
`

const AgentDetailsListItem= ({label, value}) => {
    return (
        <AgentDetailsListWrapper>
            <label>{label}</label>
            <span>:</span>
            
            <span>{value || ''}</span>
        </AgentDetailsListWrapper>
    )
}

const EditProfileLinkWrapper = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
position: absolute;
right: 40px;
top: -20px;
& p {
    color: var(--ywh-azure, #5284E0);
text-align: center;
font-family: Montserrat;
font-size: 19px;
font-style: normal;
font-weight: 700;
line-height: 19px; /* 100% */
width: 87px;
}
`

const VisitPublicButton = styled(Link)`
  padding: 12px;
  color: white !important;
  background-color: #04246B;
  font-weight: 600;
  border-radius: 8px;
`

const NoPhoto = styled('div')`
  height: 450px;
	width: 450px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid gray;
  flex-shrink: 0;
`

const EditProfileLink = ({id}) => {
    return (
        <EditProfileLinkWrapper>
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="48" viewBox="0 0 51 48" fill="none">
            <path d="M50.059 26.1795L47.2591 28.9794L41.5192 23.2395L44.3191 20.4396C44.607 20.1574 44.994 19.9994 45.3971 19.9994C45.8002 19.9994 46.1872 20.1574 46.4751 20.4396L50.059 24.0235C50.647 24.6115 50.647 25.5915 50.059 26.1795ZM22.8996 41.8312L39.8672 24.8635L45.6071 30.6034L28.6674 47.599H22.8996V41.8312ZM22.8996 27.9994C10.5238 27.9994 0.5 33.0113 0.5 39.1992V44.7991H17.2997V39.5072L28.4994 28.3074C26.6515 28.0834 24.7755 27.9994 22.8996 27.9994ZM22.8996 0C19.9292 0 17.0805 1.17997 14.9801 3.28034C12.8797 5.3807 11.6998 8.22941 11.6998 11.1998C11.6998 14.1701 12.8797 17.0188 14.9801 19.1192C17.0805 21.2196 19.9292 22.3996 22.8996 22.3996C25.8699 22.3996 28.7186 21.2196 30.819 19.1192C32.9194 17.0188 34.0993 14.1701 34.0993 11.1998C34.0993 8.22941 32.9194 5.3807 30.819 3.28034C28.7186 1.17997 25.8699 0 22.8996 0Z" fill="#5284E0"/>
        </svg>
        <p><a href={`/admin/agent/edit/${id}`}>EDIT PROFILE</a></p>
        </EditProfileLinkWrapper>
    )
}

const Agent = ({agent, isPublic = false}) => {
		const [imgError, setImgError] = useState(false)

    return (
        <AgentPage>
            <AgentDetail>
                {(!agent?.photo || imgError) ? <NoPhoto>No Photo</NoPhoto> : <AgentImage src={`${AGENT_PROFILE_URL}${agent.photo}`} onError={() => setImgError(true)} />}
                <AgentDetailsName>
                    <h1>{agent.first_name} {agent.last_name} </h1>
                    <AgentDetailsWrapper>
                        <AgentDetailsListItem label="MLS #" value={agent.mls.map( m => `${m.mls_name}-${m.mls_code}-${m.office}`).join(', ')} />
                        <AgentDetailsListItem label="License #" value={agent.licenses.map(l => `${l.license}-${l.state}`).join(', ')} />
                        <AgentDetailsListItem label="Mobile"  value={(<a href={`tel:${agent.mobile_phone}`}>{agent.mobile_phone}</a>)}  />
                        {isPublic && <AgentDetailsListItem label="Email" value={(<a href={`mailto:${agent.user.email}`}>{agent.user.email}</a>)}  /> }
                        
                        {agent.ywh_ext_num && <AgentDetailsListItem label="Extension" value={agent.ywh_ext_num} /> }
                       <div style={{display: 'flex', gap: '1rem'}}>
                        { agent.subdomain && (agent.enable_subdomain == 1 || agent.enable_subdomain == true) &&
                        <VisitPublicButton to={`//${agent.subdomain}.${window.location.host.replace('www.', '')}`} target='_blank'>View agent site</VisitPublicButton> }
                        { isPublic ? null :<VisitPublicButton to={`/agent/${agent.id}/${_.kebabCase(agent.first_name + ' ' + agent.last_name)}`} target='_blank'>View public profile</VisitPublicButton> }
                        </div>
                    </AgentDetailsWrapper>
                </AgentDetailsName>
            </AgentDetail>
            <AgentDetailExtra>
                {!isPublic &&<AgentContactContainer>
                    <AgentContactWrapper>
                        <AgentContactListItem title="Brokerage" icon="brokerage" value={agent.brokerage} readOnly={true}/>
                        <AgentContactListItem title="Company Phone" icon="company-phone" value={agent.company_phone} readOnly={true} />
                        <AgentContactListItem title="Compensation Plan" icon="compensation-plan" value={agent.compensation_plan} readOnly={true} />
                        <AgentContactListItem title="Team Name" icon="team-name" value={agent.team_name} readOnly={true} />
                        <AgentContactListItem title="Agent Type" icon="agent-type" value={agent.agent_type} readOnly={true} />
                    </AgentContactWrapper>
                </AgentContactContainer> }

                <AgentAdditionalDetailsContainer>
                    <AgentOfficeAddress title="Office Address" readOnly={true} value={`${agent.office_address || ''} ${agent.office_city_st_zip || ''}`} />
                    <AgentCompanyWebsite read={true} company={agent.company_website} personal={agent.personal_website} />
                    <AgentCityStateServedContainer>
                        {agent.states_served && agent.states_served.length > 0 &&   
                        <AgentCityStateServed title="state" description="Serving the following States" values={agent.states_served || []}  readOnly={true}/>
                        }
                        {agent.cities_served && agent.cities_served.length > 0 &&                      
                        <AgentCityStateServed title="city" description="Serving Cities and Surrounding Towns" values={agent.cities_served || []}  readOnly={true} />
                        }
                        {agent.communities_served && agent.communities_served.length > 0 &&
                        <AgentCityStateServed title="community" description="Serving the following Communities" values={agent.communities_served || []}  readOnly={true} />
                        }
                    </AgentCityStateServedContainer>
                </AgentAdditionalDetailsContainer>
           </AgentDetailExtra>

           <AgentDetailsBio title={`${agent.first_name} ${agent.last_name}`} icon="bio-name" subtitle="GET TO KNOW" type="text" values={agent.description} readOnly={true}/>
           {agent.communities_served && agent.communities_served.length > 0 &&
           <AgentDetailsBio title="COMMUNITY" icon="bio-community" communities={agent.communities_served} subtitle="EXPLORE THE" type="gallery"  readOnly={true} /> }
        </AgentPage>
        
    )
}

export default Agent;