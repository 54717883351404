import DOMPurify from 'dompurify';
import { useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { MediaPreview } from '../../../Admin/addCommunity';
import { StarIcon } from '../../icons';
import { getRandomItems } from '../../utils';
import SectionTitle from '../SectionTitle';
import HighlightIcon from './HighlightIcon';

const HighlightsContainer = styled.div`
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1200px;
  margin-top: 7.5rem;

  @media (min-width: 993px) {
    padding: 0;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  & .text {
    margin: 4rem auto 0 auto;
    max-width: 950px;
    text-align: center;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.5rem;
    max-width: 950px;
  }

  & .highlights-container {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding: 4rem 0;

    & .highlights {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 2px;

      & .body {
        height: 140px;
        width: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        & .icon-container {
          height: 70px;
        }

        & .icon-text {
          color: #494949;
          text-align: center;
          font-size: 1.125rem;
          font-weight: 500;
          line-height: 1.75rem;
        }
      }
    }
  }
`;

const HighlightImagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  margin-top: 4rem;
  flex-direction: column;

  @media (min-width: 993px) {
    flex-direction: row;
    padding: 0 32px;
  }
`;

const HighlightImages = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (min-width: 993px) {
    overflow-x: visible;
    overflow-y: visible;
    min-height: 870px;
    width: 50%;
    flex-direction: column;
    justify-content: ${(props) => (props.imagesLength < 3 ? 'center' : 'space-between')};
  }
`;

const HightlightImage = styled.img`
  flex-shrink: 0;
  height: 365px;
  width: 100%;
  border: 8px solid white;
  position: relative;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  align-self: stretch;
  max-width: 400px;
  overflow-x: scroll;

  @media (min-width: 993px) {
    max-width: 600px;
  }

  ${(props) =>
    props.index === 0 &&
    css`
      transform: rotate(2deg);
    `}
  ${(props) =>
    props.index === 1 &&
    css`
      transform: rotate(9deg);
    `}
    ${(props) =>
    props.index >= 2 &&
    css`
      transform: rotate(-6deg);
    `}
    ${(props) =>
    props.index > 0 &&
    css`
      margin-left: -190px !important;

      @media (min-width: 993px) {
        margin-top: -190px;
        margin-left: 0 !important;
      }
    `}
`;

const HighlightTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 993px) {
    width: 50%;
  }

  & .text {
    color: #404041;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
`;

const OtherHighlightsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
  margin-top: 64px;
`;

const OtherHighlightsPhotos = styled.div`
  display: flex;
  height: 200px;
  max-width: 800px;
  max-height: 200px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  @media (min-width: 993px) {
    max-width: 1000px;
  }

  & img {
    width: 250px !important;
    height: 180px !important;

    border-radius: 8px;
    border: 8px solid var(--primary-b, #fff);
    background: url(<path-to-image>) lightgray -52.832px -20.856px / 156.8% 140.83% no-repeat;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

    &:first-child {
      transform: rotate(-4.79deg);
    }

    &:not(:first-child) {
      margin-left: -300px;
    }

    &:nth-child(2) {
      transform: rotate(6.969deg);
    }

    &:nth-child(3) {
      transform: rotate(-4.243deg);
    }

    &:last-child {
      transform: rotate(6.327deg);
    }
  }
`;

const OtherHighlightsMore = styled.div`
  display: flex;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  & .__heading {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: var(--content-light-ct-secondary-light, #636363);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  & .__bulleted-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    & .__bulleted-item {
      display: flex;
      padding: 10px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-left: 1px solid #dadada;
      border-right: 1px solid #dadada;

      &:first-child {
        border-radius: 8px 8px 0px 0px;
        border-top: 1px solid #dadada;
      }

      &:nth-child(even) {
        background: #e5f1fd;
      }

      &:last-child {
        border-radius: 0px 0px 8px 8px;
        border-bottom: 1px solid #dadada;
      }

      & p {
        color: #404041;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
      }
    }
  }
`;

const Highlights = ({ description, highlights, communityDescription, images, additionalHighlights }) => {
  const communityContainerRef = useRef(null);

  const _description = useMemo(
    () => communityDescription?.split('\n').filter((s) => s !== '') ?? [],
    [communityDescription],
  );

  const _images = useMemo(() => getRandomItems(images, _description.length), [_description.length, images]);

  const otherHighlightsImages = useMemo(
    () => images.filter((f) => f.location && f.location.startsWith('highlights_')),
    [images],
  );

  return (
    <HighlightsContainer>
      <SectionTitle title="Highlights" />
      <DescriptionContainer>
        {description && <p className="text">{description}</p> }
        <div className={`highlights-container ${highlights && highlights.length == 0 ? 'border-0 p-0': ''}`}>
        {highlights && highlights.length > 0 &&<div className="highlights">
            {highlights.map((highlight) => (
              <div key={highlight.id} className="body">
                <div className="icon-container">
                  <HighlightIcon highlights={highlight.name} />
                </div>
                <p className="icon-text">{highlight.name}</p>
              </div>
            ))}
          </div> }
          <OtherHighlightsSection>
            {!!otherHighlightsImages?.length && (
              <OtherHighlightsPhotos>
                {otherHighlightsImages?.map((img) => (
                  <MediaPreview file={img} />
                ))}
              </OtherHighlightsPhotos>
            )}
            {additionalHighlights && (
              <OtherHighlightsMore>
                <p className="__heading">You're going to love this community even more!</p>
                <div className="__bulleted-items">
                  {JSON.parse(additionalHighlights).map((ah) => (
                    <div className="__bulleted-item">
                      <StarIcon />
                      <p>{ah.value}</p>
                    </div>
                  ))}
                </div>
              </OtherHighlightsMore>
            )}
          </OtherHighlightsSection>
        </div>
      </DescriptionContainer>
      <HighlightImagesContainer>
        <HighlightImages imagesLength={_images.length}>
          {_images.map((img, index) => (
            <HightlightImage
              alt=""
              index={index}
              key={img?.id ?? index}
              src={img?.url}
              alt=""
              style={{ zIndex: 10 - index }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = 'https://bucket.ywhdev.com/wl/?id=IgqYC6AWWscCgRIG0jB8u57GBWOK1J2K&fmode=open';
              }}
            />
          ))}
        </HighlightImages>
        <HighlightTextContainer ref={communityContainerRef}>
          {_description?.map((desc, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc ?? '') }} className="text" />
          ))}
        </HighlightTextContainer>
      </HighlightImagesContainer>
    </HighlightsContainer>
  );
};

export default Highlights;
