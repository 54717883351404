import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import Footer from '../../components/layouts/Footer';
import Header from '../../components/layouts/Header';
import { MainContext } from '../../context';
import AgentList from '../../pages/Admin/AgentList';
import AgentProfile from '../../pages/Admin/AgentProfile';
import AssignCommunity from '../../pages/Admin/AssignCommunity';
import CreateCommunity from '../../pages/Admin/CreateCommunity';
import EditAgentProfile from '../../pages/Admin/EditAgentProfile/EditAgentProfile';
import EditCommunity from '../../pages/Admin/EditCommunity/EditCommunity';
import Login from '../../pages/Admin/Login';
import Logout from '../../pages/Admin/Logout';
import PasswordResetForm from '../../pages/Admin/PasswordResetForm';
import RegisterAgent from '../../pages/Admin/RegisterAgent';
import RegisterUser from '../../pages/Admin/RegisterUser';
import RequestPasswordResetForm from '../../pages/Admin/RequestPasswordResetForm';
import SelectCommunity from '../../pages/Admin/SelectCommunity/SelectCommunity';
import Community from '../../pages/Community/NewCommunity';
import CommunityList from '../../pages/CommunityList';
import Http401 from '../../pages/Error/Http401';
import HomePage from '../../pages/HomePage';
import UserLogin from '../../pages/Login';
import MarketList from '../../pages/MarketList';
import BePrepared from '../../pages/Menu/BePrepared';
import ChooseRealtor from '../../pages/Menu/ChooseRealtor';
import ClosingCost from '../../pages/Menu/ClosingCost';
import CustomHomes from '../../pages/Menu/CustomHomes';
import MarketingYourHome from '../../pages/Menu/MarketingYourHome';
import NineMinuteDrill from '../../pages/Menu/NineMinuteDrill';
import OurTeam from '../../pages/Menu/OurTeam';
import PricingYourHome from '../../pages/Menu/PricingYourHome';
import routes from '../../routes';
// import SellYourHome from '../../pages/Menu/SellYourHome';
import { STORAGE_KEYS, US_STATES } from '../../constants/common';
import Accessibility from '../../pages/Accessibility';
import CreateLoanOfficer from '../../pages/Admin/CreateLoanOfficer';
import EditLoanOfficer from '../../pages/Admin/EditLoanOfficer';
import LoanOfficerProfile from '../../pages/Admin/LoanOfficerProfile/LoanOfficerProfile';
import LoanOfficers from '../../pages/Admin/LoanOfficers';
import NotFound from '../../pages/Error/Http404/404';
import ShowingYourHome from '../../pages/Menu/ShowingYourHome';
import Policy from '../../pages/Policy';
import StateList from '../../pages/StateList';
import UserDashboard from '../../pages/User/Dashboard';
import UserFavorites from '../../pages/User/Favorites';
import PublicAgent from '../../pages/agent';
import PublicLoanOfficer from '../../pages/officer';
import { loginChime } from '../../services/admin';
import { getPublicAgent, getPublicAgentBySubdomain } from '../../services/general';
import { checkIsLoggedIn, setAccessToken, setUserType } from '../../utils/common';
import getSubdomain from '../../utils/getSubdomain';
import { removeLocalStorage } from '../../utils/storage';
import './AppLayout.scss';
import Loader from '../../components/loader';

const LazyCommunity = lazy(() => import('../../pages/Community/NewCommunity'));
const LazyCommunityComponent = () => (<Suspense fallback={<Loader />}><LazyCommunity /></Suspense>)
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst',
      staleTime: 60 * 1000 * 10,
      cacheTime: 60 * 1000 * 15,
    },
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 601, // corresponds to SM size range (320px to 600px)
      md: 993, // corresponds to MD size range (601px to 992px)
      lg: 1201, // corresponds to LG size range (993px to 1200px)
      xl: 1501, // corresponds to XL size range (1201px to 1500px)
    },
  },
});

const AppLayout = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState('agents');
  const [isLoggedIn, setIsLoggedIn] = useState(checkIsLoggedIn());
  const [activeAgent, setActiveAgent] = useState({});
  const [activeOfficer, setActiveOfficer] = useState({});
  const [activeUser, setActiveUser] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [subdomainAgent, setSubdomainAgent] = useState(null);
  const [communityData, setCommunityData] = useState({
    photos: [],
    builder_website: '',
    deleted_images: [],
    state: US_STATES[0].value,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [showDeleteAgent, setShowDeleteAgent] = useState(false);

  const fetchAgentData = async (subdomain) => {
    try {
      const data = await getPublicAgentBySubdomain({ subdomain });

      setSubdomainAgent(data.agent);
    } catch (error) {}
  };

  const fetchAgent = async (id) => {
    try {
      const data = await getPublicAgent({ agent_id: id });

      setSubdomainAgent(data.agent);
    } catch (error) {}
  };

  useEffect(() => {
    const subdomain = getSubdomain();

    if (subdomain) {
      fetchAgentData(subdomain);
    }
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes('communities')) {
      setActivePage('view-community');
    }

    if (location?.pathname.includes('new')) {
      if (location?.pathname?.includes('community')) {
        setActivePage('new-community');
      } else {
        setActivePage('new-agent');
      }
    }
    /* temporary disable
    // if (!location?.pathname.includes('admin')) {
    //   //add live agent script
    //   const liveAgent = document.createElement('script');
    //   liveAgent.type = 'text/javascript';
    //   liveAgent.innerHTML = `(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.defer=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
    //   'https://yourwayhome.ladesk.com/scripts/track.js',
    //   function(e){ LiveAgent.createButton('g0bld8po', e); });`;
    //   document.body.appendChild(liveAgent);
    // }
    */
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setOpenSidebar(false);
  }, [location]);

  const loginUser = async (username, password) => {
    let params = {
      email: username,
      is_chime_user: true,
    };

    if (password) {
      params.password = password;
    }
    setLoading(true);
    try {
      let result = await loginChime(params);
      setLoading(false);
      if (result.token) {
        setAccessToken(result);
        setIsLoggedIn(true);
        setUserType(result.user.user_role_id);
        setActiveUser(result.user);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.get('email') && params.get('phone')) {
      removeLocalStorage(STORAGE_KEYS.USER_TYPE);
      removeLocalStorage('YWH_REMEMBER_ME_LOGIN');
      setIsLoggedIn(false);

      const email = params.get('email');
      const password = params.get('phone');

      loginUser(email, password);
    }
  }, [params]);

  useEffect(() => {
    if (activeUser?.assigned_agent_id && activeUser?.user_role_id === 3) {
      fetchAgent(activeUser?.assigned_agent_id);
    }
  }, [activeUser?.assigned_agent_id, activeUser?.user_role_id]);

  const observerTarget = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setLoadMore(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <MainContext.Provider
          value={{
            loading,
            setLoading,
            activePage,
            setActivePage,
            isLoggedIn,
            setIsLoggedIn,
            openSidebar,
            setOpenSidebar,
            activeAgent,
            setActiveAgent,
            communityData,
            setCommunityData,
            showDeleteAgent,
            setShowDeleteAgent,
            setLoadMore,
            loadMore,
            activeOfficer,
            setActiveOfficer,
            setActiveUser,
            activeUser,
            subdomainAgent,
            setSubdomainAgent,
          }}
        >
          <Header />
          <div className="content-body">
            <Routes>
              <Route exact path={routes.app()} element={<HomePage />} />

              <Route exact path={routes.chooseRealtor()} element={<ChooseRealtor />} />
              {/* <Route exact path={routes.makingOffer()} element={<MakingOffer />} /> */}
              <Route exact path={routes.closingCost()} element={<ClosingCost />} />

              <Route exact path={routes.community()} element={<LazyCommunityComponent />} />
              <Route exact path={routes.states()} element={<StateList />} />
              <Route exact path={routes.markets()} element={<MarketList />} />
              <Route exact path={routes.city()} element={<CommunityList />} />
              <Route exact path={routes.pricingYourHome()} element={<PricingYourHome />} />
              <Route exact path={routes.marketingYourHome()} element={<MarketingYourHome />} />
              <Route exact path={routes.makingOffer()} element={<BePrepared />} />
              <Route exact path={routes.showingYourHome()} element={<ShowingYourHome />} />
              <Route exact path={routes.nineMinuteDrill()} element={<NineMinuteDrill />} />
              {/* <Route exact path={routes.sellYourHome()} element={<SellYourHome />} /> */}
              <Route exact path={routes.customHomes()} element={<CustomHomes />} />
              <Route exact path={routes.ourTeam()} element={<OurTeam />} />

              <Route exact path={routes.publicAgentProfile()} element={<PublicAgent />} />
              <Route exact path={routes.publicAgentProfileSlug()} element={<PublicAgent />} />
              <Route exact path={routes.publicLoanOfficerProfile()} element={<PublicLoanOfficer />} />

              <Route exact path={routes.register()} element={<RegisterUser />} />
              <Route exact path={routes.registerAgent()} element={<RegisterAgent />} />
              <Route exact path={routes.login()} element={<Login />} />
              <Route exact path={routes.userLogin()} element={<UserLogin />} />
              <Route exact path={routes.userRegister()} element={<RegisterUser isBuyer={true} />} />
              <Route exact path={routes.userDashboard()} element={<UserDashboard />} />
              <Route exact path={routes.userFavorites()} element={<UserFavorites />} />

              {/*  admin routes */}
              <Route exact path={routes.editAgentProfile()} element={<EditAgentProfile />} />
              <Route exact path={routes.agentList()} element={<AgentList />} />
              <Route exact path={routes.agentProfile()} element={<AgentProfile />} />
              <Route exact path={routes.assignCommunity()} element={<AssignCommunity />} />
              <Route exact path={routes.selectCommunity()} element={<SelectCommunity />} />
              <Route exact path={routes.editCommunity()} element={<EditCommunity />} />
              <Route exact path={routes.createCommunity()} element={<CreateCommunity />} />
              <Route exact path={routes.requestPasswordReset()} element={<RequestPasswordResetForm />} />
              <Route exact path={routes.passwordReset()} element={<PasswordResetForm />} />
              <Route exact path={routes.loanOfficers()} element={<LoanOfficers />} />
              <Route exact path={routes.loanOfficer()} element={<LoanOfficerProfile />} />
              <Route exact path={routes.addLoanOfficer()} element={<CreateLoanOfficer />} />
              <Route exact path={routes.editLoanOfficer()} element={<EditLoanOfficer />} />

              {/* end of admin routes */}

              <Route exact path={routes.policy()} element={<Policy />} />
              <Route exact path={routes.accessibility()} element={<Accessibility />} />
              <Route exact path={routes.logout()} element={<Logout />} />
              <Route exact path={routes.http401()} element={<Http401 />} />
              <Route exact path={routes.http404()} element={<NotFound />} />
              <Route exact path="*" element={<NotFound />} />
            </Routes>
          </div>
          <div ref={observerTarget} />
          <Footer />
        </MainContext.Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default AppLayout;
