import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Input, Textarea } from '../../../../components/Form';
import { sendAgentMessage } from '../../../../services/general';
import { Alert } from 'react-bootstrap';
import { S3_URL } from '../../../../constants/common';
const AgentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;

  @media (min-width: 993px) {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  flex-shrink: 0;

  @media (min-width: 993px) {
    width: 50%;
  }

  & .agent-details_section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 2rem;

    @media (min-width: 993px) {
      flex-direction: row;
    }

    & img {
      margin: 0 auto;
      height: 300px;
      width: 100%;
      max-width: 300px;
      border-radius: 0.5rem;
      object-fit: cover;
      object-position: top;

      @media (min-width: 1201px) {
        flex-shrink: 0;
      }
    }

    & .agent-details {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: 993px) {
        text-align: left;
      }

      & .header {
        & .agent-name {
          color: #2f2f2f;
          font-size: 1.75rem;
          font-weight: 500;
          line-height: 2.5rem;
        }

        & .community-specialist {
          color: #2f2f2f;
          font-size: 1.125rem;
          font-weight: 500;
          line-height: 1.75rem; /* 155.556% */
        }
      }

      & .content {
        padding: 0;
        border: none;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-radius: 0;
        box-shadow: none;
        text-align: left;
        margin-top: 1rem;

        @media (min-width: 993px) {
          margin-top: 0;
        }

        & .details {
          min-height: 45px;

          & .label {
            color: #757575;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25rem;
            border-bottom: 1px solid #eaeaea;
          }

          & .value {
            color: #2f2f2f;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  & .agent-description {
    color: #2f2f2f;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.75rem;
    margin-top: 2rem;
  }
`;

const RightColumn = styled.div`
  border-radius: 0.75rem;
  border: 1px solid #dadada;
  padding: 2rem 1rem;

  @media (min-width: 993px) {
    width: 50%;
    padding: 2rem;
  }

  & p {
    margin-bottom: 2rem;
    color: #2f2f2f;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.5rem; /* 150% */

    & a {
      color: #5069af;
      text-decoration: underline;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormSubmit = styled.button`
  display: flex;
  padding: 0.75rem 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 1.875rem;
  background: #3c4d7d;
  width: fit-content;
  margin: 2rem auto 0 auto;
  color: #eaeaea;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem; /* 155.556% */
`;

const INIT_SEND_AGENT = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

const Agent = ({ agent, communityName, state, market, community, subdomainAgent, community_id }) => {
  const [messageSubmitting, setMessageSubmitting] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [sendAgent, setSendAgent] = useState(INIT_SEND_AGENT);
  const handleSendAgentChange = (e) => {
    setMessageError(false);
    setMessageSuccess(false);
    const { name, value } = e.target;
    setSendAgent({
      ...sendAgent,
      [name]: value,
    });
  };

  const handleAgentFormSubmit = async (e) => {
    console.log('wat', community);
    e.preventDefault();
    setMessageSubmitting(true);
    let params = {
      state: state,
      market: market,
      community: community,
      id: community_id,
    };
    try {
      if (subdomainAgent) {
        sendAgent.agent_id = subdomainAgent.id;
      }
      await sendAgentMessage(params, sendAgent);
      setMessageSuccess(true);
      setMessageSubmitting(false);
      setSendAgent(INIT_SEND_AGENT);
      setTimeout(() => setMessageSuccess(false), 3000);
    } catch (e) {
      setMessageError(true);
      setMessageSubmitting(false);
    }
  };

  const getAgent = subdomainAgent ?? agent;

  return (
    <AgentContainer>
      <LeftColumn>
        <div className="agent-details_section">
          <img src={`${S3_URL}/images/agent_profile/${getAgent?.photo}`} alt="" />
          <div className="agent-details">
            <div className="header">
              <p className="agent-name">
                {getAgent?.first_name} {getAgent?.last_name}
              </p>
              <p className="community-specialist">{communityName} Community Specialist</p>
            </div>
            <div className="content">
              <div className="details">
                <p className="label">License:</p>
                <p className="value">
                  {getAgent?.licenses &&
                    getAgent?.licenses.length > 0 &&
                    getAgent.licenses.map((license) => license.state + '-' + license.license).join(', ')}
                </p>
              </div>
              <div className="details">
                <p className="label">Phone:</p>
                <a className="value" href={`tel:${getAgent?.company_phone || ''}`}>{getAgent?.company_phone || ''}</a>
              </div>
              <div className="details">
                <p className="label">Email:</p>
                <a className="value" href={`mailto:${getAgent?.user?.email || getAgent?.email || ''}`}>{getAgent?.user?.email || getAgent?.email || ''}</a>
              </div>
            </div>
          </div>
        </div>
        <p className="agent-description">{getAgent?.description || ''}</p>
      </LeftColumn>
      <RightColumn>
        <p>
          We respect your privacy! Your information WILL NOT BE SHARED, SOLD, or RENTED to anyone, for any reason
          outside the course of normal real estate exchange. By submitting, you agree to our{' '}
          <Link to="/policy?tab=TermsOfService">Terms of Use</Link> and{' '}
          <Link to="/policy?tab=PrivacyPolicy">Privacy Policy.</Link>
        </p>
        <Form onSubmit={handleAgentFormSubmit}>
          <label htmlFor="name" style={{width: '100%', fontSize: '1.15rem', fontWeight: 500}}>Name*</label>
          <Input
            name="name" 
            placeholder="Name*"
            onChange={handleSendAgentChange}
            value={sendAgent.name}
            id="name"
            required/>
          <label htmlFor="email" style={{width: '100%', fontSize: '1.15rem', fontWeight: 500}}>Email Address*</label>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="Email*"
            onChange={handleSendAgentChange}
            value={sendAgent.email}
            required/>
          <label htmlFor="phone" style={{width: '100%', fontSize: '1.15rem', fontWeight: 500}}>Phone Number*</label>
          <Input
            id="phone"
            name="phone"
            placeholder="Phone*"
            onChange={handleSendAgentChange}
            value={sendAgent.phone}
            required/>
          <label htmlFor="message" style={{width: '100%', fontSize: '1.15rem', fontWeight: 500}}>Message</label>
          <Textarea 
            id="message"
            name="message"
            placeholder="Message"
            value={sendAgent.message}
            onChange={handleSendAgentChange}/>

          {messageSuccess && (
            <Alert variant="success" className="w-100">
              Request successfully submitted.
            </Alert>
          )}
          {messageError && (
            <Alert variant="danger" className="w-100">
              Something went wrong, please try again
            </Alert>
          )}
          <FormSubmit type="submit" disabled={messageSubmitting}>
            {messageSubmitting ? 'Submitting...' : 'SEND MESSAGE'}
          </FormSubmit>
        </Form>
      </RightColumn>
    </AgentContainer>
  );
};

export default Agent;
