import { memo, useEffect, useRef, useState } from 'react';
import { deleteOfficer, getOfficers } from '../../../services/admin';
import { checkIsLoggedIn, getUserType } from '../../../utils/common';
import { useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/loader';
import { MainContext } from '../../../context';
import Officers from '../officers';
import Layout from '../layout';
import { useNavigate } from 'react-router-dom';

const LoanOfficers = () => {
  const navigate = useNavigate();
  const [officers, setOfficers] = useState([]);
  const { 
    loading,
    setLoading,
    activePage,
    setActivePage,
    showDeleteAgent
  } = useContext(MainContext);

  const fetchOfficers = async () => {
    setLoading(true);
    try {
      let data = await getOfficers();
      if (data.results) {
        setOfficers(data.results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkIsLoggedIn()) {
      if (getUserType() === '1') {
        navigate('/error/401', { replace: true });
      } else {
        fetchOfficers();
      }
    } else {
      navigate('/error/401', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastId = useRef();
  const handleDelete = async (officer) => {
    if (window.confirm(`Are you sure you want to delete this officer ${officer.first_name} ${officer.last_name || ''}`)) {
      try {
        toastId.current = toast.loading('Deleting...');
        await deleteOfficer({ id: officer.id });
        setOfficers([...officers.filter((a) => a.id !== officer.id)]);
        toast.update(toastId.current, {
          render: 'Officer successfully deleted',
          type: 'success',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
        });
      } catch (e) {
        toast.update(toastId.current, {
          render: e.message,
          type: 'error',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
        });
      }
    }
  };
  return (
    <div>
      <Layout active={activePage} setActive={(page) => setActivePage(page)}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Officers showDeleteOfficer={showDeleteAgent} officers={officers} handleDelete={handleDelete} />
            <ToastContainer />
          </>
        )}
      </Layout>
    </div>
  );
};

export default memo(LoanOfficers);
