const routes = {
  app: () => '/',
  community: () => '/community/:state/:market/:community',
  community: () => '/community/:state/:market/:community/:id',
  states: () => '/community',
  markets: () => '/community/:state/',
  city: () => '/community/:state/:market',

  userLogin: () => '/user/login',
  userRegister: () => '/user/register',
  userDashboard: () => '/user/dashboard',
  userFavorites: () => '/user/favorites',

  chooseRealtor: () => '/choose-realtor',
  makingOffer: () => '/making-offer',
  closingCost: () => '/closing-cost',
  pricingYourHome: () => '/pricing-your-home',
  marketingYourHome: () => '/marketing-your-home',
  showingYourHome: () => '/showing-your-home',
  nineMinuteDrill: () => '/nine-minute-drill',
  sellYourHome: () => '/sell-your-home',
  customHomes: () => '/custom-homes',
  ourTeam: () => '/our-team',

  register: () => '/admin/register',
  registerAgent: () => '/admin/register/agent',
  login: () => '/admin/login',

  agentList: () => '/admin/agents',
  agentProfile: () => '/admin/agent/:agent_id',
  assignCommunity: () => '/admin/community/assign',
  editAgentProfile: () => '/admin/agent/edit/:agent_id',
  selectCommunity: () => '/admin/communities',
  editCommunity: () => '/admin/community/edit/:state/:market/:community',
  editCommunity: () => '/admin/community/edit/:state/:market/:community/:id',
  createCommunity: () => '/admin/community/new',
  logout: () => '/admin/logout',
  passwordReset: () => '/admin/reset-password/:email/:token',
  requestPasswordReset: () => '/admin/reset/request',
  loanOfficers: () => '/admin/loan-officers',
  loanOfficer: () => '/admin/officer/:id',
  addLoanOfficer: () => '/admin/add/loan-officer',
  editLoanOfficer: () => '/admin/officer/edit/:id',

  publicAgentProfile: () => '/agent/:agent_id',
  publicAgentProfileSlug: () => '/agent/:agent_id/:agent_name',
  publicLoanOfficerProfile: () => '/officer/:id',

  policy: () => '/policy',
  accessibility: () => '/accessibility',
  http401: () => '/error/401',
  http404: () => '/error/404',
};

export default routes;
