import { styled } from '@mui/material';
import React from 'react';

const Section1Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '112px 64px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& div.content': {
    width: 526,
    [theme.breakpoints.down('lg')]: {
      width: 446,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& div.header': {
      marginBottom: 72,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 16,
      padding: '0 64px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    '& div.subheader': {
      marginBottom: 40,
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      img: {
        height: 60,
        width: 60,
      },
      h6: {
        paddingLeft: 16,
        borderLeft: '2px solid #CDA07D',
      },
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
    },
  },
  '& div.image': {
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 708,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('lg')]: {
        height: 560,
      },
      [theme.breakpoints.down('md')]: {
        height: 600,
        width: 500,
      },
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="content">
        <div className="header">
          <img src="https://bucket.ywhdev.com/wl/?id=Psa4Vh98PS6l4hu78JNwYPpr2fcNFpig&fmode=open" alt="" />
          <div className="ywh-section__header-text">
            <div className="ywh-section__header-text--heading">
              <h2>Making An Offer</h2>
            </div>
          </div>
        </div>
        <div className="subheader">
          <img src="https://bucket.ywhdev.com/wl/?id=xLcRfcq8VuG2SSA0d08dZXrXM78Fp98m&fmode=open" alt="" />
          <h6>
            Before the offer to purchase is created, it is very important that you have been at least pre-qualified or
            better yet pre-approved by a lender.
          </h6>
        </div>
        <p>
          This is one of the best negotiating tools a buyer can have. It shows the seller that you are financially able
          to purchase the home. After you have found the right home, it is time to prepare the offer.
          <br />
          <br />
          When you are buying a home, there are many problems that the seller is obligated to disclose. For example, in
          most states, it is illegal to withhold information about major physical defects on the property, but, these
          disclosures don't always paint the entire picture of the home.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=H5tMqYS0DLBWFwsKNepYVN8gve8iyKDZ&fmode=open"
          alt="showing a man shake hands with a woman."
        />
      </div>
    </Section1Container>
  );
};

export default Section1;
