import styled, { css } from 'styled-components';

const DetailsCardContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid #b7b7b7;
  padding: 1.5rem;
  display: flex;
  width: ${(props) => (props.no_label1 ? '250px' : '350px')};
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%;

  @media (max-width: 992px) {
    width: 350px;
  }

  .title {
    color: #494949;
    text-align: center;
    font-size: 1.25rem !important;
    line-height: 2rem !important;
    font-weight: 500;
  }

  & .details {
    display: flex;
    align-items: center;
    justify-content: center;

    & .label-1-container {
      margin-left: 1rem;
      padding-right: 2rem;
    }
  }
`;

const LabelText = styled.p`
  color: #757575;
  text-align: center;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5rem !important;
  text-align: left;
`;

const ValueText = styled.p`
  color: #494949;
  text-align: left;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;

  ${(props) =>
    props.large &&
    css`
      font-size: 2.5rem !important;
      line-height: 3.5rem !important;
      font-weight: 400;
    `};
`;

const DetailsCard = ({ icon, label1, value1, label2, value2, title }) => (
  <DetailsCardContainer no_label1={!label1}>
    <p className="title">{title}</p>
    <div className="details">
      {icon}
      <div className="label-1-container">
        {label1 && <LabelText>{label1}</LabelText>}
        <ValueText large={!label1}>{value1}</ValueText>
      </div>
      {value2 && (
        <div className="border-l border-black-400 pl-2">
          <LabelText>{label2}</LabelText>
          <ValueText>{value2}</ValueText>
        </div>
      )}
    </div>
  </DetailsCardContainer>
);

export default DetailsCard;
