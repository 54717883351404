import { useTheme } from '@emotion/react';
import { styled, useMediaQuery } from '@mui/material';
import React from 'react';
import { HomePageButton, HomepageSectionHeading } from './HomePage';

const HomePageSection = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  [theme.breakpoints.down(1201)]: {
    maxWidth: 1200,
  },
}));

const HomepageSection1 = styled(HomePageSection)(({ theme }) => ({
  width: '100%',
  padding: '160px 0 60px 96px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 120,
  alignSelf: 'stretch',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    padding: '100px 64px',
    gap: 64,
  },
  [theme.breakpoints.down('md')]: {
    padding: '80px 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& div.left-col': {
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 64,
    '& p': {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const HomepageSection1RightCol = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 16,
  width: 884,
  [theme.breakpoints.down('lg')]: {
    height: 600,
    gap: 64,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '& img': {
    maxWidth: 'calc(50% - 8px)',
    height: 'auto',
    borderRadius: 16,
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.40)',
    [theme.breakpoints.down('lg')]: {
      maxHeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      height: 600,
      width: 600,
    },
  },
}));

const Section1 = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <HomepageSection1>
      <div className="left-col">
        <HomepageSectionHeading>
          <img src="https://bucket.ywhdev.com/wl/?id=xjXmK63kyilKglSCuVnZBVJRJngJl1A8&fmode=open" alt="" />
          <h2>We are here to help you find the home of your dreams</h2>
        </HomepageSectionHeading>
        <p>
          Whether you are looking for a brand new construction or a charming pre-owned home, we have a vast selection of
          options to fit your budget and lifestyle. With our expertise and knowledge of the local real estate market, we
          can help you make informed decisions and negotiate the best deals.
        </p>
        <HomePageButton onClick={() => window.open('/community', '_blank')}>
          <span>Browse New Homes</span>
          <i className="fa-sharp fa-solid fa-circle-chevron-right"></i>
        </HomePageButton>
      </div>
      <HomepageSection1RightCol>
        <img
          src={
            smallScreen
              ? 'https://bucket.ywhdev.com/wl/?id=jGxL5bbYAcsVTdQpuXnnEHFTckjRF07O&fmode=open'
              : 'https://bucket.ywhdev.com/wl/?id=KlnrvE4yg849cYFmRurolL5zxS3aPZAl&fmode=open'
          }
          alt="An showing a house under construction."
        />
        <img
          src={
            smallScreen
              ? 'https://bucket.ywhdev.com/wl/?id=jGxL5bbYAcsVTdQpuXnnEHFTckjRF07O&fmode=open'
              : 'https://bucket.ywhdev.com/wl/?id=I38H54yS8UoJdbdCWcOsIIcEqmKrtwD3&fmode=open'
          }
          alt="An showing a beautiful house."
        />
      </HomepageSection1RightCol>
    </HomepageSection1>
  );
};

export default Section1;
