import { styled } from '@mui/material';
import React from 'react';

const Section1Container = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 80,
  marginBottom: 64,
  padding: '0 80px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
  },
  '& div.header': {
    display: 'flex',
    width: '100%',
    gap: 32,
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 840,
    margin: '0 auto',
    h2: {
      fontSize: 32,
      lineHeight: '48px',
      color: '#04246B',
      fontWeight: 700,
    },
    img: {
      height: 100,
      width: 100,
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="header">
        <img src="https://bucket.ywhdev.com/wl/?id=hqfK1ENxZsHKn1eLYFIYMfGtQnstlW29&fmode=open" alt="" />
        <h2>A Breakdown of Your Closing Costs</h2>
        <h4>
          You've found your dream home, the seller has accepted your offer, your loan has been approved and you're eager
          to move into your new home. But before you get the key, there's one more step--the closing.
        </h4>
      </div>
    </Section1Container>
  );
};

export default Section1;
