import { styled } from '@mui/material';
import Section1 from './Section1';
import Section2 from './Section2';

import './BePrepared.scss';
import Section3 from './Section3';

const HeroSection = styled('section')(({ theme }) => ({
  background: 'url("https://bucket.ywhdev.com/wl/?id=nMyjOOOVbRWLb3TC3YBEzPXyF3J4AlpS&fmode=open")',
  overflow: 'hidden',
  height: 700,
  [theme.breakpoints.down('sm')]: {
    height: 450,
  },
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 80,
  position: 'relative',
  backgroundPosition: 'top center',
  backgroundSize: 'cover',
  borderBottom: '8px solid #5284e0',
  gap: 8,
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    backgroundPosition: 'center',
  },
  '& div.header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    h1: {
      color: 'white',
      fontFamily: 'Playfair Display',
      zIndex: 2,
      fontSize: 48,
      lineHeight: '60px',
      fontWeight: 700,
    },
    h3: {
      color: '#CDA07D',
      zIndex: 2,
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 600,
    },
    img: {
      width: '100%',
      bottom: 0,
      position: 'absolute',
    },
  },
}));

const BePrepared = () => {
  return (
    <>
      <HeroSection>
        <div className="header">
          <h1>Be Prepared</h1>
          <h3>Six questions to ask before making an offer</h3>
          <img src="https://bucket.ywhdev.com/wl/?id=77XjplxGIrz5KaCYRvJVDvdIa2HtaLYM&fmode=open" alt="" />
        </div>
      </HeroSection>
      <div>
        <Section1 />
        <Section2 />
        <Section3 />
      </div>
    </>
  );
};

export default BePrepared;
