const MarinaIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="61" viewBox="0 0 56 61" fill="none">
    <path
      d="M30.7292 5.95455V8.68182H25.2747V5.95455H30.7292ZM28.0019 25.3455L42.6747 30.1727L49.1928 32.3L46.1383 43.1273C44.6656 42.3091 43.5747 41.1909 43.0292 40.5636L38.911 35.8455L34.7928 40.5364C33.8656 41.6273 31.3019 44.1364 28.0019 44.1364C24.7019 44.1364 22.1383 41.6273 21.211 40.5364L17.0928 35.8455L12.9747 40.5364C12.4292 41.1636 11.3383 42.2545 9.86556 43.0727L6.78374 32.2727L13.3292 30.1182L28.0019 25.3455ZM36.1837 0.5H19.8201V8.68182H11.6383C8.63829 8.68182 6.18374 11.1364 6.18374 14.1364V26.7364L2.66556 27.8818C2.3158 27.9893 1.99103 28.1655 1.71033 28.4002C1.42963 28.6349 1.19866 28.9233 1.03097 29.2485C0.86329 29.5737 0.76228 29.9291 0.733875 30.2939C0.70547 30.6587 0.750242 31.0255 0.865563 31.3727L6.04738 49.5909H6.18374C10.5474 49.5909 14.4201 47.1909 17.0928 44.1364C19.7656 47.1909 23.6383 49.5909 28.0019 49.5909C32.3656 49.5909 36.2383 47.1909 38.911 44.1364C41.5837 47.1909 45.4565 49.5909 49.8201 49.5909H49.9565L55.111 31.3727C55.3292 30.6636 55.2747 29.9 54.9474 29.2455C54.6201 28.5909 54.0201 28.1 53.311 27.8818L49.8201 26.7364V14.1364C49.8201 11.1364 47.3656 8.68182 44.3656 8.68182H36.1837V0.5ZM11.6383 24.9636V14.1364H44.3656V24.9636L28.0019 19.5909L11.6383 24.9636ZM38.911 51.4455C35.5837 53.7636 31.7928 54.9364 28.0019 54.9364C24.211 54.9364 20.4201 53.7636 17.0928 51.4455C13.7656 53.7636 9.97465 55.0455 6.18374 55.0455H0.7292V60.5H6.18374C9.94738 60.5 13.6565 59.5455 17.0928 57.8C20.5292 59.5455 24.2656 60.4455 28.0019 60.4455C31.7383 60.4455 35.4747 59.5727 38.911 57.8C42.3474 59.5727 46.0565 60.5 49.8201 60.5H55.2747V55.0455H49.8201C46.0292 55.0455 42.2383 53.7636 38.911 51.4455Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default MarinaIcon;
