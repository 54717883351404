import React from 'react';
import { styled } from '@mui/system';

const PrivacyPolicyContainer = styled('div')(({ theme }) => ({
  padding: '80px 64px',
  maxWidth: 1000,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    padding: '80px 32px',
  },
  div: {
    marginBottom: 40,
    h3: {
      color: '#494949',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '28px',
      marginBottom: 8,
    },
    p: {
      fontSize: 14,
      lineHeight: '20px',
      a: {
        color: '#2E50A2',
      },
    },
    ul: {
      padding: '0 0 0 24px',
      margin: '8px 0',
      listStyleType: 'circle',
      li: {
        fontSize: 14,
        lineHeight: '20px',
        marginBottom: 8,
      },
    },
  },
}));

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <div>
        <h3>Last updated: Aug 30, 2021</h3>
        <p>
          This notice describes Privacy Policy for YourWayHome. Our privacy policy is designed to advise you about how
          we collect, use, and protect the personal information you provide. By visiting this website, you are accepting
          the practices described in this Privacy Policy.
        </p>
      </div>
      <div>
        <h3>What information do we collect?</h3>
        <p>
          Information you provide to us: We may collect any information when you register on our website, or that you
          give us in any other way. When ordering or registering on our website, as appropriate, you may be asked to
          enter your name, email address, mailing address, phone number or credit card information. You can choose not
          to provide certain information, but you may not be able to take advantage of our services and features. The
          information we collect may include the type of computer and browser you are using, Uniform Resource Locators
          ("URLs") used by you to access the Website, your Internet Protocol ("IP") address, your Machine Access Control
          ("MAC") address, and what web service you are using. We may also collect the date, time, and length of visit,
          and the pages you visit. Collecting this information helps us design the site and the services to best suit
          your needs. This information may be compiled and analyzed on both an individual and an aggregate basis.
        </p>
      </div>
      <div>
        <h3>Do we use cookies?</h3>
        <p>
          We employ the use of cookies. By using YourWayHome’s website you consent to the use of cookies in accordance
          with YourWayHome’s privacy policy. Most of the modern day interactive websites use cookies to enable us to
          retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality
          of this area and ease of use for those people visiting. Some of our affiliate/advertising partners may also
          use cookies.
        </p>
      </div>
      <div>
        <h3>How and when will the information be used?</h3>
        <p>
          The information we collect is used to (i) process transactions. Your information, whether public or private,
          will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever without your
          consent, other than for the express purpose of keeping a record on our database; (ii) The email address you
          provide for membership registration will only be used to send you information regarding your membership,
          activities of the Society and, occasionally, information from Society' sponsors that is relevant to research
          areas specified in your profile; (iii) to be shared with the third party. Any information you provide for
          membership registration on our website (including but not limited to: name, email address, and phone number)
          might be shared with the third party. The third party includes, but is not limited to YourWayHome’s
          co-marketing lenders.
          <br />
          <br />
          By providing us with your phone number, you consent to our using your phone number to deliver or cause to be
          delivered to you at that phone number telemarketing calls (which may use an automatic telephone dialing
          system) and artificial or prerecorded voice or text messages. If you do not wish to receive such calls or
          messages, you may opt out. You further consent to our sharing your phone number to our partners. You consent
          to our partners using your phone number to deliver or cause to be delivered to you at that phone number
          telemarketing calls (which may use an automatic telephone dialing system) and artificial or prerecorded voice
          or text messages. You release us from any possible liability or claim stemming from how our partners use your
          phone number, included but not limited to claims arising from the Telephone Consumer Protection Act. You
          understand that you are not required to provide this consent and authorization and that it is not a condition
          to quality for a loan or to receive any good or service
        </p>
      </div>
      <div>
        <h3>How do we protect your information?</h3>
        <p>
          The privacy and protection of your information is important to us. We do not make any personal information
          available to third parties without your permission, unless said information is the subject of a valid subpoena
          or other court order. Your access to some services and content may be password protected. We advise that you
          do not disclose your password to anyone.
          <br />
          <br />
          However, no data transmission over the Internet or any other wireless communication method can be guaranteed
          to be totally secure. As a result, while we will make all reasonable efforts to protect the privacy of your
          information during transmission, we make no guarantees, representations, or warranties with respect to the
          privacy of such transmitted information. Our obligations with respect to the security of your stored
          information are governed by the Terms and Conditions. State laws may also apply impose obligations to secure
          stored information.
        </p>
      </div>
      <div>
        <h3>Which methods do we use to protect your information?</h3>
        <p>
          We use security software to protect the confidentiality of your personal information. In addition, our
          business practices are reviewed periodically for compliance with policies and procedures governing the
          security and confidentiality of our information. Our business practices limit employee access to confidential
          information, and limit the use and disclosure of such information to authorized persons.
        </p>
      </div>
      <div>
        <h3>What about advertisements and linked sites?</h3>
        <p>
          This Privacy Policy covers information collected on this website and through our services only. It does not
          apply to other sites you reach through links on our website. We encourage you to read the privacy policies of
          those other websites to learn how they collect and use information about you. If advertisements appear on this
          website, the advertisers may employ cookies and other tracking technologies in connection with those
          advertisements. Your accessing any of those advertisements from our webpage may cause your exposure to those
          tracking technologies. Third party websites that you visit may use the cookies placed by third party vendors
          for similar purposes. We have no responsibility for any such uses. We have no responsibility for any such
          uses.
        </p>
      </div>
      <div>
        <h3>What about Children?</h3>
        <p>
          We are in compliance with the requirements of COPPA (Children's Online Privacy Protection Act); we do not
          collect any information from anyone under 13 years of age. Our website, products and services are all directed
          to people who are at least 13 years old or older. If we learn that we have mistakenly collected personal
          information from a child under age 13, we will delete that information as soon as possible. If you believe
          that we might have information from or about a child under age 13, please contact us via{' '}
          <a href="mailto:ywhteam@yourwayhome.com">ywhteam@yourwayhome.com</a>.
        </p>
      </div>
      <div>
        <h3>California Consumer Privacy Policy Compliance</h3>
        <p>
          If you are a California resident, California law may provide you with additional rights regarding our use of
          your personal information.
        </p>
      </div>
      <div>
        <h3 style={{ marginBottom: 16 }}>Your Rights and Choices</h3>
        <p style={{ marginBottom: 8, fontWeight: 500 }}>Right to Know</p>
        <p style={{ marginBottom: 16 }}>
          Consumers have a right to know about personal information that is collected, used, disclosed, or sold.
          Consumers have the right to request for YourWayHome to disclose what personal information they have collected,
          used, and disclosed in the past 12 months.
        </p>
        <p style={{ marginBottom: 8, fontWeight: 500 }}>Sale of Personal Information</p>
        <p style={{ marginBottom: 16 }}>
          In the last 12 months, YourWayHome has not sold any personal information. YourWayHome does not sell personal
          information.
        </p>
        <p style={{ marginBottom: 8, fontWeight: 500 }}>Right to Non-Discrimination</p>
        <p style={{ marginBottom: 16 }}>
          Consumers have the right to not be discriminated against by exercising their consumer privacy rights. We will
          not discriminate against Consumers for exercising any of their California Privacy Rights.
          <br />
          <br />
          To make a request for additional information regarding your rights under the CCPA, please contact us by e-mail
          at <a href="mailto:ywhteam@yourwayhome.com">ywhteam@yourwayhome.com</a> or by phone at 303-554-7652.
        </p>
      </div>
      <div>
        <h3>Consent</h3>
        <p>By using our website, you consent to our Privacy Policy.</p>
      </div>
      <div>
        <h3>Policy</h3>
        <p>
          If our Privacy Policy or procedures change, we will immediately post those changes to our website. Any such
          changes will be effective immediately upon being posted, unless otherwise stated in the change.
        </p>
      </div>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
