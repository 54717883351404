import { styled } from '@mui/material';
import React from 'react';
import { HomePageButton } from './HomePage';

const HomePageSection = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  // [theme.breakpoints.down(1201)]: {
  //   maxWidth: 1200,
  // },
}));

const HomepageSection3 = styled(HomePageSection)(({ theme }) => ({
  padding: '120px 0 0 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 120,
  alignSelf: 'stretch',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    padding: '0 0 0 64px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: 0,
    gap: 0,
  }
}));

const HomepageSection3RightCol = styled('div')(({ theme }) => ({
  borderRadius: '0 32px 32px 0',
  padding: '64px 240px 64px 120px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 830,
  gap: 32,
  backgroundColor: '#EDF2F4',
  [theme.breakpoints.down('lg')]: {
    width: 496,
    margin: 0,
    padding: '100px 0px',
    borderRadius: 0,
    backgroundColor: 'transparent',
    textAlign: 'center',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '80px 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& p': {
    fontSize: 18,
    lineHeight: '28px',
  },
  '& p.title': {
    borderBottom: '2px solid #CDA07D',
    paddingBottom: 8,
  },
}));

const HomepageSection3LeftCol = styled('div')(({ theme }) => ({
  zIndex: 2,
  maxWidth: 800,
  marginLeft: -240,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 0,
    marginLeft: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flex: 1,
    maxWidth: '100%',
  },
  '& img': {
    borderRadius: '16px 0 0 16px',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.40)',
    height: 600,
    maxWidth: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: 692,
      width: 576,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: 0,
      height: 300,
      boxShadow: 'none',
    },
  },
}));

const HomepageSection3Heading = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: 16,
  '& img': {
    width: 48,
    height: 48,
  },
  '& h2': {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: 700,
    color: '#04246B',
    textAlign: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Section3 = () => {
  return (
    <HomepageSection3>
      <HomepageSection3RightCol>
        <div>
          <p className="title">We'll help you find the home of your dreams.</p>
        </div>
        <HomepageSection3Heading>
          <img src="https://bucket.ywhdev.com/wl/?id=SDkHTGBi2fBLRBgTR4axEKTEePAQ6KVj&fmode=open" alt="" />
          <h2>SELL A HOME</h2>
        </HomepageSection3Heading>
        <p>
          Our experienced real estate professionals are here to guide you through the process, from selecting the right
          property to securing financing and closing the deal. With our extensive knowledge of the local real estate
          market and access to the latest listings, we'll help you find the home of your dreams at a price you can
          afford. Whether you're a first-time buyer or a seasoned investor, we have the resources and expertise to help
          you achieve your real estate goals.
        </p>
        <p>Start browsing our inventory today and take the first step towards owning your dream home!</p>
        <HomePageButton onClick={() => window.open('https://www.yourwayhome.net/evaluation', '_blank')}>
          <span>What’s My Home Worth?</span>
          <i className="fa-sharp fa-solid fa-circle-chevron-right"></i>
        </HomePageButton>
      </HomepageSection3RightCol>
      <HomepageSection3LeftCol>
        <img
          src="https://bucket.ywhdev.com/wl/?id=Po5yfCVBFL3F4rxdS1I1DNeM5gIAtNQz&fmode=open"
          alt="A beautiful showing a deck of a house."
        />
      </HomepageSection3LeftCol>
    </HomepageSection3>
  );
};

export default Section3;
