import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import { GalleryModal } from '../../Community';
import { ModalBody } from 'react-bootstrap';
import Slider from 'react-slick';
import { MediaPreview } from '../../../Admin/addCommunity';
import { useMemo, useRef, useState } from 'react';
// import { DraggableScrollX } from '@/app/_components/common';

const GalleryContainer = styled.div`
	margin: 0 auto;
	padding: 0 2rem;
	margin-top: 5rem;

	@media (min-width: 993px) {
		max-width: 1280px
		padding: 0;
		margin-top: 7.5rem;
	}
`;

const VideoContainer = styled.div`
  margin: 5rem auto 0 auto;
  max-width: 1050px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  & .video {
    height: 200px;
    width: 33%;
    padding: 1px;
    align-items: center;
    justify-content: center;
    display: flex;

    & video {
      height: 100%;
      width: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  }
`;

const ImagesContainer = styled.div`
  margin: 5rem auto 0 auto;
  max-width: 1050px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  & .image {
    height: 200px;
    width: 33%;
    padding: 1px;

    & img {
      height: 100%;
      width: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  }
`;

const VideoPlayButton = styled.div`
  position: absolute;
  cursor: pointer;
`;

const gallerySliderSettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  initialSlide: 3,
  prevArrow: (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <path
        d="M32 64C38.329 64 44.5159 62.1232 49.7782 58.607C55.0406 55.0908 59.1421 50.0931 61.5641 44.2459C63.9861 38.3986 64.6198 31.9645 63.3851 25.7571C62.1504 19.5497 59.1027 13.8479 54.6274 9.37259C50.1521 4.89731 44.4503 1.84961 38.2429 0.614882C32.0355 -0.619847 25.6014 0.0138622 19.7541 2.43587C13.9069 4.85787 8.90917 8.95939 5.39296 14.2218C1.87676 19.4841 -3.35083e-06 25.671 -2.79753e-06 32C0.00895485 40.4842 3.38325 48.6183 9.38247 54.6175C15.3817 60.6167 23.5158 63.991 32 64ZM22.8738 30.2585L35.1815 17.9508C35.4102 17.7221 35.6817 17.5407 35.9805 17.4169C36.2794 17.2931 36.5996 17.2294 36.9231 17.2294C37.2465 17.2294 37.5668 17.2931 37.8656 17.4169C38.1644 17.5407 38.4359 17.7221 38.6646 17.9508C38.8933 18.1795 39.0747 18.451 39.1985 18.7498C39.3223 19.0486 39.386 19.3689 39.386 19.6923C39.386 20.0157 39.3223 20.336 39.1985 20.6348C39.0747 20.9336 38.8933 21.2052 38.6646 21.4339L28.0954 32L38.6646 42.5662C39.1265 43.028 39.386 43.6545 39.386 44.3077C39.386 44.9609 39.1265 45.5874 38.6646 46.0492C38.2027 46.5111 37.5763 46.7706 36.9231 46.7706C36.2699 46.7706 35.6434 46.5111 35.1815 46.0492L22.8738 33.7415C22.645 33.5129 22.4634 33.2415 22.3395 32.9426C22.2157 32.6438 22.1519 32.3235 22.1519 32C22.1519 31.6765 22.2157 31.3562 22.3395 31.0574C22.4634 30.7586 22.645 30.4871 22.8738 30.2585Z"
        fill="#CDA07D"
      />
      <path
        d="M22.8738 30.2585L35.1815 17.9508C35.4102 17.7221 35.6817 17.5407 35.9805 17.4169C36.2794 17.2931 36.5996 17.2294 36.9231 17.2294C37.2465 17.2294 37.5668 17.2931 37.8656 17.4169C38.1644 17.5407 38.4359 17.7221 38.6646 17.9508C38.8933 18.1795 39.0747 18.451 39.1985 18.7498C39.3223 19.0486 39.386 19.3689 39.386 19.6923C39.386 20.0157 39.3223 20.336 39.1985 20.6348C39.0747 20.9336 38.8933 21.2052 38.6646 21.4339L28.0954 32L38.6646 42.5662C39.1265 43.028 39.386 43.6545 39.386 44.3077C39.386 44.9609 39.1265 45.5874 38.6646 46.0492C38.2027 46.5111 37.5763 46.7706 36.9231 46.7706C36.2699 46.7706 35.6434 46.5111 35.1815 46.0492L22.8738 33.7415C22.645 33.5129 22.4634 33.2415 22.3395 32.9426C22.2157 32.6438 22.1519 32.3235 22.1519 32C22.1519 31.6765 22.2157 31.3562 22.3395 31.0574C22.4634 30.7586 22.645 30.4871 22.8738 30.2585Z"
        fill="white"
      />
    </svg>
  ),
  nextArrow: (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <path
        d="M32 0C25.671 0 19.4841 1.87677 14.2218 5.39297C8.95939 8.90918 4.85787 13.9069 2.43587 19.7541C0.013865 25.6014 -0.619842 32.0355 0.614885 38.2429C1.84961 44.4503 4.89732 50.1521 9.3726 54.6274C13.8479 59.1027 19.5497 62.1504 25.7571 63.3851C31.9645 64.6199 38.3986 63.9861 44.2459 61.5641C50.0931 59.1421 55.0908 55.0406 58.607 49.7782C62.1232 44.5159 64 38.329 64 32C63.991 23.5158 60.6167 15.3817 54.6175 9.38248C48.6183 3.38326 40.4842 0.00895944 32 0ZM41.1262 33.7415L28.8185 46.0492C28.5898 46.2779 28.3183 46.4593 28.0194 46.5831C27.7206 46.7069 27.4004 46.7706 27.0769 46.7706C26.7535 46.7706 26.4332 46.7069 26.1344 46.5831C25.8356 46.4593 25.5641 46.2779 25.3354 46.0492C25.1067 45.8205 24.9253 45.549 24.8015 45.2502C24.6777 44.9514 24.614 44.6311 24.614 44.3077C24.614 43.9843 24.6777 43.664 24.8015 43.3652C24.9253 43.0664 25.1067 42.7948 25.3354 42.5661L35.9046 32L25.3354 21.4338C24.8735 20.972 24.614 20.3455 24.614 19.6923C24.614 19.0391 24.8735 18.4127 25.3354 17.9508C25.7973 17.4889 26.4237 17.2294 27.0769 17.2294C27.7301 17.2294 28.3566 17.4889 28.8185 17.9508L41.1262 30.2585C41.355 30.4871 41.5366 30.7585 41.6605 31.0574C41.7843 31.3562 41.8481 31.6765 41.8481 32C41.8481 32.3235 41.7843 32.6438 41.6605 32.9426C41.5366 33.2414 41.355 33.5129 41.1262 33.7415Z"
        fill="#CDA07D"
      />
      <path
        d="M41.1262 33.7415L28.8185 46.0492C28.5898 46.2779 28.3183 46.4593 28.0194 46.5831C27.7206 46.7069 27.4004 46.7706 27.0769 46.7706C26.7535 46.7706 26.4332 46.7069 26.1344 46.5831C25.8356 46.4593 25.5641 46.2779 25.3354 46.0492C25.1067 45.8205 24.9253 45.549 24.8015 45.2502C24.6777 44.9514 24.614 44.6311 24.614 44.3077C24.614 43.9843 24.6777 43.664 24.8015 43.3652C24.9253 43.0664 25.1067 42.7948 25.3354 42.5661L35.9046 32L25.3354 21.4338C24.8735 20.972 24.614 20.3455 24.614 19.6923C24.614 19.0391 24.8735 18.4127 25.3354 17.9508C25.7973 17.4889 26.4237 17.2294 27.0769 17.2294C27.7301 17.2294 28.3566 17.4889 28.8185 17.9508L41.1262 30.2585C41.355 30.4871 41.5366 30.7585 41.6605 31.0574C41.7843 31.3562 41.8481 31.6765 41.8481 32C41.8481 32.3235 41.7843 32.6438 41.6605 32.9426C41.5366 33.2414 41.355 33.5129 41.1262 33.7415Z"
        fill="white"
      />
    </svg>
  ),
};

const Gallery = ({ images, videos }) => {
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [showVideoGalleryModal, setShowVideoGalleryModal] = useState(false);
  const [gsettings, setGsettings] = useState(gallerySliderSettings);
  const gallerySliderRef = useRef(null);

  const filteredImage = useMemo(() => images.filter((img) => !img.location) ?? [], [images]);

  const handleClickPreview = (index) => {
    setGsettings({
      ...gsettings,
      initialSlide: index,
    });
    setShowVideoGalleryModal(!showVideoGalleryModal);
  }

  return (
    <>
      <GalleryContainer>
        <SectionTitle title="Gallery" />
        <ImagesContainer>
          {filteredImage.map((img, index) => (
            <div key={img?.id ?? index} className="image">
              <MediaPreview
                file={img}
                handleClick={() => {
                  setGsettings({
                    ...gsettings,
                    initialSlide: index,
                  });
                  setShowGalleryModal(!showGalleryModal);
                }}
                hasCursor
              />
            </div>
          ))}
        </ImagesContainer>
      </GalleryContainer>
      <GalleryContainer>
        <VideoContainer>
          {videos.map((video, index) => (
            <div key={video?.id ?? index} className="video" onClick={() => handleClickPreview(index)}>
              <VideoPlayButton>
                <svg xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 105 105" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M53.0758 90.4666C57.9691 90.4666 62.8145 89.5028 67.3353 87.6302C71.8561 85.7577 75.9639 83.013 79.424 79.5529C82.884 76.0928 85.6288 71.985 87.5013 67.4642C89.3739 62.9434 90.3377 58.098 90.3377 53.2047C90.3377 48.3113 89.3739 43.466 87.5013 38.9451C85.6288 34.4243 82.884 30.3166 79.424 26.8565C75.9639 23.3964 71.8561 20.6517 67.3353 18.7791C62.8145 16.9065 57.9691 15.9427 53.0758 15.9427C43.1933 15.9427 33.7155 19.8685 26.7276 26.8565C19.7396 33.8444 15.8138 43.3222 15.8138 53.2047C15.8138 63.0872 19.7396 72.5649 26.7276 79.5529C33.7155 86.5408 43.1933 90.4666 53.0758 90.4666ZM53.0758 98.7471C78.2276 98.7471 98.6182 78.3565 98.6182 53.2047C98.6182 28.0528 78.2276 7.66224 53.0758 7.66224C27.9239 7.66224 7.53333 28.0528 7.53333 53.2047C7.53333 78.3565 27.9239 98.7471 53.0758 98.7471Z" fill="#FAFAFA"/><path d="M53.0758 90.4666C57.9691 90.4666 62.8145 89.5028 67.3353 87.6302C71.8561 85.7577 75.9639 83.013 79.424 79.5529C82.884 76.0928 85.6288 71.985 87.5013 67.4642C89.3739 62.9434 90.3377 58.098 90.3377 53.2047C90.3377 48.3113 89.3739 43.466 87.5013 38.9451C85.6288 34.4243 82.884 30.3166 79.424 26.8565C75.9639 23.3964 71.8561 20.6517 67.3353 18.7791C62.8145 16.9065 57.9691 15.9427 53.0758 15.9427C43.1933 15.9427 33.7155 19.8685 26.7276 26.8565C19.7396 33.8444 15.8138 43.3222 15.8138 53.2047C15.8138 63.0872 19.7396 72.5649 26.7276 79.5529C33.7155 86.5408 43.1933 90.4666 53.0758 90.4666Z" fill="#CDA07D"/><path d="M69.6366 53.2047L44.7953 71.1318V35.2775L69.6366 53.2047Z" fill="white"/></svg>
              </VideoPlayButton>
              <MediaPreview
                file={video}
                // handleClick={() => {
                //   setGsettings({
                //     ...gsettings,
                //     initialSlide: index,
                //   });
                //   setShowVideoGalleryModal(!showVideoGalleryModal);
                // }}
                hasCursor
              />
            </div>
          ))}
        </VideoContainer>
      </GalleryContainer>
      <GalleryModal centered show={showGalleryModal} onHide={() => setShowGalleryModal(!showGalleryModal)}>
        <ModalBody>
          <span className="close" onClick={() => setShowGalleryModal(!showGalleryModal)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
              <path
                d="M26 0C11.6637 0 0 11.6637 0 26C0 40.3363 11.6637 52 26 52C40.3363 52 52 40.3363 52 26C52 11.6637 40.3363 0 26 0ZM36.8288 34L34 36.8288L26 28.8288L18 36.8288L15.1712 34L23.1712 26L15.1712 18L18 15.1712L26 23.1712L34 15.1712L36.8288 18L28.8288 26L36.8288 34Z"
                fill="#04246B"
              />
              <path
                d="M36.8288 34L34 36.8288L26 28.8288L18 36.8288L15.1712 34L23.1712 26L15.1712 18L18 15.1712L26 23.1712L34 15.1712L36.8288 18L28.8288 26L36.8288 34Z"
                fill="white"
              />
            </svg>
          </span>
          <Slider {...gsettings} ref={gallerySliderRef}>
            {filteredImage.map((image, index) => (
              <MediaPreview file={image} key={index} />
            ))}
          </Slider>
        </ModalBody>
      </GalleryModal>
      <GalleryModal centered show={showVideoGalleryModal} onHide={() => setShowVideoGalleryModal(!showVideoGalleryModal)}>
        <ModalBody>
          <span className="close" onClick={() => setShowVideoGalleryModal(!showVideoGalleryModal)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
              <path
                d="M26 0C11.6637 0 0 11.6637 0 26C0 40.3363 11.6637 52 26 52C40.3363 52 52 40.3363 52 26C52 11.6637 40.3363 0 26 0ZM36.8288 34L34 36.8288L26 28.8288L18 36.8288L15.1712 34L23.1712 26L15.1712 18L18 15.1712L26 23.1712L34 15.1712L36.8288 18L28.8288 26L36.8288 34Z"
                fill="#04246B"
              />
              <path
                d="M36.8288 34L34 36.8288L26 28.8288L18 36.8288L15.1712 34L23.1712 26L15.1712 18L18 15.1712L26 23.1712L34 15.1712L36.8288 18L28.8288 26L36.8288 34Z"
                fill="white"
              />
            </svg>
          </span>
          <Slider {...gsettings} ref={gallerySliderRef}>
            {videos.map((video, index) => (
              <MediaPreview file={video} key={index} />
            ))}
          </Slider>
        </ModalBody>
      </GalleryModal>
    </>
  );
};

export default Gallery;
