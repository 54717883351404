import { memo, useEffect, useState, useCallback } from 'react';
import { getOfficer } from '../../../services/admin';
import { Link, useParams } from 'react-router-dom';
import { LOAN_OFFICER_PROFILE_URL } from '../../../constants/common';

import { checkIsLoggedIn } from '../../../utils/common';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes';

import AuthChecker from '../AuthChecker/AuthChecker';
import Agent from '../agent';
import { useContext } from 'react';
import { MainContext } from '../../../context';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import { getUserType } from '../../../utils/common';
import { StaticNavigation } from '../layout';
import { AgentProfileWrapper } from '../AgentProfile/AgentProfile';
import Officer from '../officer';

const AgentListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  padding: 24px 0;
  width: 250px;
  cursor: pointer;
  border-bottom: 0.5px solid #bcbcbc;

  & h5 {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.3px;
  }

  &.active {
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
  }
`;

const AgentListItemImage = styled.div`
  border-radius: 50px;
  border: 4px solid #cda07d;
  width: 80px;
  height: 80px;
  overflow: hidden;

  & img {
    width: 100%;
  }
`;


const LoanOfficerProfile = () => {
  let { id } = useParams();
  const [officer, setOfficer] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const navigate = useNavigate();

  const [mls, setMls] = useState([]);
  const [licenses, setLicenses] = useState([]);

  const { loading, setLoading, setActiveOfficer, setActivePage } = useContext(MainContext);
  const [agents, setAgents] = useState(false);
  const isAdmin = getUserType() == 2;

  const fetchOfficer = async () => {
    setLoading(true);
    let data = await getOfficer({id});
    if (data.officer) {
      setLoading(false);
      setOfficer(data.officer);
      setActiveOfficer(data.officer)
    }
  };


  useEffect(() => {
    setActivePage('officer-profile');
    fetchOfficer();
  }, []); 
  return (
    <div className="relative">
      <AuthChecker />
      {checkIsLoggedIn && (
        <>
          <StaticNavigation page="officer-profile" />
          {!officer && loading && <Loader />}
          {
            officer && (
              <AgentProfileWrapper>
                {loading && <Loader />}
                {!loading && <Officer officer={officer} />}
              </AgentProfileWrapper>
            )
          }
        </>
      )}
      {!checkIsLoggedIn && <h1>401: Forbidden</h1>}
    </div>
  );
};

export default memo(LoanOfficerProfile);
