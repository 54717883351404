import { styled } from '@mui/material';

const Section3Container = styled('section')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 1500,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '48px 64px',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '48px 0',
    gap: 64,
  },
  '& div.content': {
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      maxWidth: 526,
      marginBottom: 32,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
        maxWidth: 526,
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      maxWidth: 526,
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    img: {
      maxWidth: 526,
      borderRadius: 16,
      height: 756,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: 0,
        height: 400,
      },
    },
  },
}));

const Section3 = () => {
  return (
    <Section3Container>
      <div className="content">
        <div className="header">
          <img src="https://bucket.ywhdev.com/wl/?id=4CbwTACJ5CGOi49i69wwTBbcnhqwS4Oq&fmode=open" alt="" />
          <h2>For Sale Signs / Brochure Boxes</h2>
        </div>
        <p style={{ marginBottom: 40 }}>
          Even in our digitally dominated era, the traditional "For Sale" sign planted firmly in the front yard of a
          property remains a pivotal tool in the home-selling process. These signs serve as immediate, local
          advertisements, capturing the attention of passersby, whether they are casual strollers, interested neighbors,
          or serious buyers actively scouting the area. For local residents, seeing a "For Sale" sign often prompts
          word-of-mouth promotion, as neighbors discuss available properties within their community.
        </p>
        <p>
          Furthermore, for many potential buyers, there's an undeniable allure in the tangibility and immediacy of
          seeing a home in person, paired with the direct information from the sign. It invites spontaneous property
          inquiries and can lead to impromptu viewings. In essence, while digital platforms cast a wider net, the
          traditional "For Sale" sign remains a testament to direct, community-focused marketing, ensuring that the
          property remains visible and top-of-mind in its immediate locale.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=riWjaRSu6R2xbZxRRctL13Mm5uaFj53T&fmode=open"
          alt="showing a beautiful 2-storey house."
        />
      </div>
    </Section3Container>
  );
};

export default Section3;
