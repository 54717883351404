import { FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';
import { S3_URL } from '../../../../constants/common';

const LoanOfficerContainer = styled.div`
  display: flex;
  gap: 5rem;
  flex-direction: column;
  color: #2f2f2f;

  @media (min-width: 993px) {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  flex-shrink: 0;
  min-height: 448px;

  @media (min-width: 993px) {
    width: 50%;
  }

  & .loan-officer-details_section {
    display: flex;
    gap: 2rem;
    height: 100%;
    flex: 1;
    flex-direction: column;

    @media (min-width: 993px) {
      flex-direction: row;
    }

    & img {
      width: 100%;
      max-width: 300px;
      flex-shrink: 0;
      border-radius: 0.5rem;
      object-fit: cover;
      object-position: top;
      margin: 0 auto;

      @media (min-width: 993px) {
        height: 100%;
      }
    }

    & .loan-officer-details {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & .loan-officer-name {
        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
      }

      & .loan-officer-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 32px;
      }

      & .content {
        padding: 0;
        border: none;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-radius: 0;
        box-shadow: none;
        text-align: left;
        margin-top: 1rem;

        @media (min-width: 993px) {
          margin-top: 0;
        }

        & .details {
          min-height: 45px;

          & .label {
            color: #979797;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.25rem;
            border-bottom: 1px solid #eaeaea;
          }

          & .value {
            color: #2f2f2f;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
`;

const RightColumn = styled.div`
  border-radius: 0.5rem;
  padding: 2rem;

  @media (min-width: 993px) {
    width: 50%;
  }

  & p {
    color: #2f2f2f;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
`;

const LoanOfficerButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-top: 48px;

  @media (min-width: 993px) {
    margin-top: 0;
  }
`;

const LoanOfficerButton = styled.button`
  display: flex;
  width: 200px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 30px;
  background-color: #3c4d7d;
  color: #eaeaea;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  width: 100%;
`;

const LoanOfficer = ({ loanOfficer }) => {
  return (
    <LoanOfficerContainer>
      <LeftColumn>
        <div className="loan-officer-details_section">
          <img src={`${S3_URL}/images/loan_officer/${loanOfficer?.headshot}`} alt="" />
          <div className="loan-officer-details">
            <div>
              <p className="loan-officer-name">
                {loanOfficer?.first_name} {loanOfficer?.last_name}
              </p>
              <p className="loan-officer-title">Loan Officer</p>
              <div className="content">
                <div className="details">
                  <p className="label">License:</p>
                  <p className="value">{loanOfficer?.nmls}</p>
                </div>
                <div className="details">
                  <p className="label">Phone:</p>
                  <p className="value">{loanOfficer?.mobile_phone}</p>
                </div>
                <div className="details">
                  <p className="label">Email:</p>
                  <p className="value">{loanOfficer?.email}</p>
                </div>
              </div>
            </div>
            <LoanOfficerButtonsContainer>
              <LoanOfficerButton>
                Apply for loan <FaChevronRight />
              </LoanOfficerButton>
              <LoanOfficerButton>
                Check Rates <FaChevronRight />
              </LoanOfficerButton>
            </LoanOfficerButtonsContainer>
          </div>
        </div>
      </LeftColumn>
      {loanOfficer?.description && (
        <RightColumn>
          <p>{loanOfficer?.description}</p>
        </RightColumn>
      )}
    </LoanOfficerContainer>
  );
};

export default LoanOfficer;
