const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18ZM9.2835 3.4515C9.21978 3.41136 9.14832 3.38511 9.07376 3.37446C8.99921 3.36381 8.92325 3.36901 8.85085 3.38972C8.77844 3.41042 8.71122 3.44616 8.65356 3.49461C8.5959 3.54305 8.54912 3.60312 8.51625 3.67088L7.16625 6.40688L4.14675 6.84562C4.04696 6.85935 3.95304 6.9009 3.87575 6.9655C3.79846 7.0301 3.74091 7.11515 3.70969 7.21093C3.67847 7.3067 3.67484 7.40933 3.69921 7.50707C3.72358 7.60481 3.77497 7.69372 3.8475 7.76363L6.0345 9.89213L5.517 12.9004C5.50039 12.9992 5.51169 13.1008 5.54964 13.1936C5.58758 13.2864 5.65066 13.3668 5.73178 13.4257C5.8129 13.4846 5.90884 13.5197 6.00883 13.5271C6.10881 13.5344 6.20887 13.5138 6.29775 13.4674L9 12.0476L11.7 13.4663C11.7888 13.5131 11.889 13.5341 11.9892 13.527C12.0894 13.5198 12.1855 13.4847 12.2668 13.4257C12.3481 13.3667 12.4112 13.2861 12.449 13.193C12.4868 13.1 12.4978 12.9982 12.4808 12.8993L11.9655 9.89213L14.1503 7.7625C14.2216 7.69226 14.2719 7.60354 14.2957 7.50629C14.3194 7.40904 14.3156 7.3071 14.2846 7.2119C14.2537 7.1167 14.1968 7.03201 14.1204 6.96731C14.044 6.90262 13.9511 6.86048 13.8521 6.84562L10.8315 6.40688L9.4815 3.67088C9.43754 3.58052 9.36889 3.50446 9.2835 3.4515Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default StarIcon;
