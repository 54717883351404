import React from 'react';
import { styled } from '@mui/system';

const AccessibilityHeader = styled('div')(() => ({
  backgroundColor: '#F7F7F7',
  padding: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h2: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '36px',
  },
}));

const AccessibilityContent = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 1000,
  padding: '80px 64px',
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  [theme.breakpoints.down('md')]: {
    padding: '80px 32px',
  },
  p: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#636363',
  },
  ul: {
    padding: '0 0 0 16px',
    li: {
      listStyle: 'disc',
      fontSize: 16,
      lineHeight: '24px',
      color: '#636363',
    },
  },
}));

const Accessibility = () => {
  return (
    <div>
      <AccessibilityHeader>
        <h2>Our Commitment for ADA</h2>
      </AccessibilityHeader>
      <AccessibilityContent>
        <p>
          We actively work to maintain a website with content that is accessible to all visitors. This site is updated
          on a regular basis in order for it to be as accessible as possible. To the extent possible, we aim to meet the
          Level AA Success Criteria found in the Web Content Accessibility Guidelines 2.1 (also known as WCAG 2.1).
        </p>
        <p>
          If you are in need of assistance to navigate this website or access real estate information and property data,
          please contact us using any of the options listed below and we will make every reasonable effort to assist
          you. Please provide as much information as possible so that we can address your specific needs:
        </p>
        <ul>
          <li>Phone Number: +1(303) 554-7652</li>
          <li>Email Address: ywhteam@yourwayhome.com</li>
        </ul>
      </AccessibilityContent>
    </div>
  );
};

export default Accessibility;
