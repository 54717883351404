import { useRef, useState, useEffect } from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(8px);
  z-index: 99;
`;

const ModalContent = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    align-items: stretch;
  }

  & button {
    position: absolute;
    top: 22px;
    right: 22px;
    color: white;
    z-index: 999;
    background-color: transparent;
  }

  & iframe {
    width: 100vw;
    max-width: 768px;
    height: ${(props) => props.$height};
    max-height: 1153px;
    position: relative;

    @media (max-width: 1000px) {
      max-height: 100vh;
    }
  }
`;

const FormModal = ({ isOpen, onClose, form }) => {
  const iframeRef = useRef(null);
  const [height, setHeight] = useState('100vh');

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;

    if (iframe && iframe.contentWindow) {
      const iframeContent = iframe.contentWindow.document;
      if (iframeContent) {
        const contentHeight = iframeContent.body.scrollHeight;
        setHeight(`${contentHeight}px`);
      }
    }
  };

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
    };
  }, []);

  return isOpen ? (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()} $height={height}>
        <button onClick={onClose}>
          <IoCloseCircle size={30} />
        </button>
        <iframe title="form" ref={iframeRef} src={form} />
      </ModalContent>
    </ModalOverlay>
  ) : null;
};

export default FormModal;
