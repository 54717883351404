import { styled } from '@mui/material';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import { useContext } from 'react';
import { MainContext } from '../../../context';
import SectionAgent from './SectionAgent';

const HeroSection = styled('section')(({ theme }) => ({
  background: 'url("https://bucket.ywhdev.com/wl/?id=jP766zIFt9jQ8PoPkTmGv7MCU4kgfw19&fmode=open")',
  overflow: 'hidden',
  height: 700,
  [theme.breakpoints.down('sm')]: {
    height: 450,
  },
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 80,
  position: 'relative',
  backgroundPosition: 'top center',
  backgroundSize: 'cover',
  borderBottom: '8px solid #5284e0',
  gap: 8,
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    backgroundPosition: 'center',
  },
  '& div.header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    h1: {
      color: 'white',
      fontFamily: 'Playfair Display',
      zIndex: 2,
      fontSize: 48,
      lineHeight: '60px',
      fontWeight: 700,
    },
    h3: {
      color: '#CDA07D',
      zIndex: 2,
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 600,
    },
  },
}));

const OurTeam = () => {
  const { subdomainAgent } = useContext(MainContext);
  return (
    <div>
      {subdomainAgent ? (
        <SectionAgent />
      ) : (
        <>
          <HeroSection>
            <div className="header">
              <h1>Your Way Home Team</h1>
              <h3>We are here to help you discover a place you'll love to live.</h3>
            </div>
          </HeroSection>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
        </>
      )}
    </div>
  );
};

export default OurTeam;
