import { styled } from '@mui/material';

const CARD_ITEMS = [
  {
    image: 'https://bucket.ywhdev.com/wl/?id=Lhw2mmdB6r41bXTlSShdIfRONM7TkO0h&fmode=open',
    year: '1996',
    description: 'Andrew & Kaly opened a boutique real estate brokerage in Boulder, CO.',
  },
  {
    image: 'https://bucket.ywhdev.com/wl/?id=0wfIqv9YWmbBog4fkqHkhjq1xLfTt4Hq&fmode=open',
    year: '1997',
    description:
      'Loan Origination Center - company and technology founded by Andrew; pre-Internet, innovative machines that delivered real time loan scenarios, credit resorts, etc. on a remote kiosk.',
  },
  {
    image: 'https://bucket.ywhdev.com/wl/?id=XDgQSTBVzBtcgQyPT0N0KaN12UYEd34m&fmode=open',
    year: '1999',
    description:
      'Technology and company for Loan Origination Center acquired, forming LenderLive Network, hiring Andrew as CTO and eventually becoming one of the largest tech/service providers to the mortgage industry.',
  },
  {
    image: 'https://bucket.ywhdev.com/wl/?id=zbcQSqitFxpZJKtELW75unjnIFOlkkC9&fmode=open',
    year: '2010',
    description:
      'Copper Homes formed — a custom home building company, quickly established as a premier home builder in Northern Colorado.',
  },
  {
    image: 'https://bucket.ywhdev.com/wl/?id=RKpKs3Q8AP1puYAgTL2Db1d7kMSGbo8B&fmode=open',
    year: '2014',
    description:
      'Your Way Home real estate team established to capitalize on unique niche leveraging new home communities and custom builds.',
  },
  {
    image: 'https://bucket.ywhdev.com/wl/?id=81gNkKkICVqsFrtXNgNronoTAp7yXkg1&fmode=open',
    year: '2020',
    description:
      'YourWayHome team was incorporated into a national brokerage. Managed the rebranding and recruitment of nearly 200 agents.',
  },
  {
    image: 'https://bucket.ywhdev.com/wl/?id=KwEwtSGUfKKCpwhACElqUpOp2uKUclBp&fmode=open',
    year: '2023',
    description:
      'Launching the Your Way Home team nationally replicating their proven formula to help agents accelerate their career.',
  },
];

const Section4Container = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: '0 auto',
  paddingBottom: 120,
  [theme.breakpoints.down('md')]: {
    maxWidth: 500,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 0 2.5rem 0',
  },
}));

const Section4Card = styled('div')(({ theme, index }) => ({
  width: '100%',
  position: 'relative',
  marginTop: index !== 0 ? -40 : 0,
  '& div.content': {
    borderRadius: index === 0 ? '16px 16px 0 0' : index + 1 === CARD_ITEMS.length ? '0 0 16px 16px' : 0,
    display: 'flex',
    alignItems: 'center',
    gap: 64,
    padding: '64px 64px 80px 64px',
    backgroundColor: index % 2 !== 0 ? '#5284E0' : '#DCE7F9',
    [theme.breakpoints.down('md')]: {
      padding: '64px 32px 80px 32px',
      flexDirection: 'column',
      alignItems: 'start',
    },
    img: {
      width: 100,
      height: '100%',
    },
    div: {
      [theme.breakpoints.down('md')]: {
        maxWidth: 436,
      },
      h2: {
        color: index % 2 !== 0 ? 'white' : '#04246B',
        fontSize: 39,
        lineHeight: '50.7px',
        marginBottom: 16,
      },
      h5: {
        fontSize: 24,
        fontWeight: 400,
        lineHeight: '36px',
        color: index % 2 !== 0 ? 'white' : 'black',
      },
    },
  },
  '& div.image': {
    bottom: 0,
    zIndex: 10,
    position: 'absolute',
    left: index % 2 !== 0 ? 'unset' : 300,
    right: index % 2 !== 0 ? 200 : 'unset',
    [theme.breakpoints.down('md')]: {
      left: 'unset',
      right: -12,
    },
    img: {
      width: 155,
      height: 40,
    },
  },
}));

const Section4 = () => (
  <Section4Container>
    {CARD_ITEMS.map((item, index) => (
      <Section4Card index={index} key={index}>
        <div className="content">
          <img src={item.image} alt="" />
          <div>
            <h2>{item.year}</h2>
            <h5>{item.description}</h5>
          </div>
        </div>
        {index + 1 !== CARD_ITEMS.length && (
          <div className="image">
            <img
              src={
                index % 2 !== 0
                  ? 'https://bucket.ywhdev.com/wl/?id=4DaCka0hKuq2KYgExz2cQ8pTezaxi2fJ&fmode=open'
                  : 'https://bucket.ywhdev.com/wl/?id=z37srgzbwWrasn6ChuCj9Acrggqk9kQA&fmode=open'
              }
              alt=""
            />
          </div>
        )}
      </Section4Card>
    ))}
  </Section4Container>
);

export default Section4;
