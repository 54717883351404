import styled from 'styled-components';
import formatNumber from '../../../../utils/formatNumber';
import { DollarIcon, HomeCogIcon, HomePlusIcon, HomeUpIcon, SizeIcon } from '../../icons';
import DetailsCard from './DetailsCard';

const DetailsCardContainer = styled.div`
  display: flex;
  max-width: 900px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin: 7.5rem auto 0 auto;
`;

const Details = ({ communityDetails }) => {
  return (
    <DetailsCardContainer>
      <DetailsCard
        title="Property Price"
        icon={<DollarIcon />}
        label1="Min:"
        value1={`$${formatNumber(communityDetails?.community.price_min)}`}
        label2="Max:"
        value2={`$${formatNumber(communityDetails?.community.price_max)}`}
      />
      <DetailsCard
        title="Property Size"
        icon={<SizeIcon />}
        label1="Min:"
        value1={`${communityDetails?.community.area_min} sq. ft.`}
        label2="Max:"
        value2={`${communityDetails?.community.area_max} sq. ft.`}
      />
      <DetailsCard title="New Homes" icon={<HomePlusIcon />} value1={communityDetails?.community.homes ?? 0} />
      <DetailsCard
        title="Quick Move-ins"
        icon={<HomeUpIcon />}
        value1={communityDetails?.community.quick_move_ins ?? 0}
      />
      <DetailsCard title="Builder" icon={<HomeCogIcon />} value1={communityDetails?.builder ? 1 : 0} />
    </DetailsCardContainer>
  );
};

export default Details;
