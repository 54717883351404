import { baseFetch, baseNodeApiFetch, deleteRequest, getRequest, postRequest } from './base';

export const register = async (params) => {
    let url = `/api/admin/register`;
    return postRequest(url, params);
};

export const registerBuyer = async (params) => {
    let url = `/api/user/register/buyer`;
    return postRequest(url, params);
};
export const registerAgent = async (params) => {
    let url = `/api/admin/register/agent`;
    return postRequest(url, params);
};
export const registerAgentv2 = async (params) => {
    let url = `/api/admin/register/agent/v2`;
    return postRequest(url, params);
};

export const editAgentProfile = async (params) => {
    let url = `/api/admin/agent/edit`;
    return postRequest(url, params);
};

export const forgotPassword = async (params) => {
    let url = `/api/forgot-password`;
    return postRequest(url, params);
};

export const resetPassword = async (params) => {
    let url = `/api/reset-password`;
    return postRequest(url, params);
};

export const login = async (params) => {
    let url = `/api/admin/login`;
    return postRequest(url, params);
};

export const loginChime = async (params) => {
    let url = `/api/login`;
    return postRequest(url, params);
};

export const getAgents = async (params) => {
    let url = `/api/admin/agents`;
    return getRequest(url, params);
};

export const getAgent = async (params) => {
    let url = `/api/admin/agent`;
    return postRequest(url, params);
};

export const searchAgent = async (params) => {
    let url = `/api/admin/agents/search`;
    return getRequest(url, params);
};

export const searchCommunities = async (params) => {
    let url = `/api/admin/communities/search`;
    return getRequest(url, params);
};

export const assignCommunities = async (params) => {
    let url = `/api/admin/communities/assign`;
    return postRequest(url, params);
};

export const editCommunity = async (params) => {
    let url = `/api/admin/community/edit`;
    return postRequest(url, params);
};

export const createCommunity = async (params) => {
    let url = `/api/admin/community/new`;
    return postRequest(url, params);
};

export const updateCommunityPhotos = async (params) => {
    let url = `/api/admin/community/edit/photos`;
    return postRequest(url, params);
}

export const deleteAgent = async (params) => {
    let url = `/api/admin/agent/${params.agent_id}`;
    return deleteRequest(url, params);
}

export const checkEmail = async (params) => {
    let url = `/api/admin/check-email`;
    return baseFetch(url, {
        method: 'POST',
        data: params
    });
};

export const getOfficers = async () => {
    return baseNodeApiFetch('/loan-officers')
}

export const getOfficer = async (params) => {
    return baseNodeApiFetch(`/loan-officers/${params.id}`)
}

export const addLoanOfficer = async (data) => {
    return baseNodeApiFetch(
        '/loan-officers', 
        {
            method: 'post',
            data: data,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
           
            }
            
        })
            
}

export const updateLoanOfficer = async (data, id) => {
    return baseNodeApiFetch(
        `/loan-officers/${id}`, 
        {
            method: 'patch',
            data: data,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
           
            }
            
        })
}
export const deleteOfficer = async (params) => {
    return baseNodeApiFetch(`/loan-officers/${params.id}`, { method: 'DELETE'})
}
