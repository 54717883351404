import { styled } from '@mui/material';

const Section1Container = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: 1500,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  '& div.image': {
    flexBasis: '50%',
    img: {
      objectFit: 'cover',
      maxWidth: '100%',
      height: 1000,
      [theme.breakpoints.down('md')]: {
        height: 500,
        width: '100%',
        objectPosition: 'top center',
        // objectFit: 'contain',
      },
    },
  },
  '& div.content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#2F2F2F',
    flexBasis: '50%',
    textAlign: 'center',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '64px 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2.5rem 1rem',
    },
    svg: {
      height: 100,
      width: 100,
      marginBottom: 32,
    },
    h2: {
      color: 'white',
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 700,
      marginBottom: 64,
      maxWidth: 500,
    },
    h3: {
      fontSize: 24,
      lineHeight: '36px',
      color: 'white',
      fontWeight: 500,
      maxWidth: 500,
    },
  },
}));

const Section1 = () => (
  <Section1Container>
    <div className="image">
      <img
        src="https://bucket.ywhdev.com/wl/?id=DBzdTdzv5IwM9KsRPOhTaE41578BODin&fmode=open"
        alt="showing Mr. and Mrs. Andrew Batson, founder of YourWayHome.com LLC"
      />
    </div>
    <div className="content">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="102" viewBox="0 0 100 102" fill="none">
        <circle cx="50" cy="51.5" r="48" stroke="#DAC5A5" stroke-width="4" />
        <path
          d="M68.0006 45.0001L50.5004 27.5L28.0002 50.0002H33.0003V67.5003C33.0003 68.8264 33.5271 70.0982 34.4648 71.0359C35.4025 71.9736 36.6742 72.5004 38.0003 72.5004H53.0004"
          stroke="#DAC5A5"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.0002 72.5004V57.5003C43.0002 56.1742 43.527 54.9024 44.4647 53.9647C45.4024 53.027 46.6742 52.5002 48.0003 52.5002H53.0003C53.9678 52.5002 54.8703 52.7752 55.6353 53.2503M73.0005 57.5003H66.7504C65.7559 57.5003 64.802 57.8954 64.0988 58.5986C63.3955 59.3019 63.0004 60.2557 63.0004 61.2503C63.0004 62.2449 63.3955 63.1987 64.0988 63.902C64.802 64.6053 65.7559 65.0003 66.7504 65.0003H69.2505C70.245 65.0003 71.1989 65.3954 71.9021 66.0987C72.6054 66.802 73.0005 67.7558 73.0005 68.7504C73.0005 69.7449 72.6054 70.6988 71.9021 71.402C71.1989 72.1053 70.245 72.5004 69.2505 72.5004H63.0004M68.0004 72.5004V75.0004M68.0004 55.0003V57.5003"
          stroke="#DAC5A5"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h2>Simply put - we help sellers sell, buyers buy, and agents thrive - and we're good at what we do.</h2>
      <h3 className="body-font-weight-regular">
        Looking at the bigger picture, we are versatile specialists, experienced in all facets of homeownership and
        beyond. Our unique background and keen eye for opportunity adds value to our collective team - and we have the
        funding, diligence, tactics, and network to ensure you achieve superior results.
      </h3>
    </div>
  </Section1Container>
);

export default Section1;
