const WaterfrontIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="86" height="60" viewBox="0 0 86 60" fill="none">
    <path
      d="M0.104126 60V55.3943C2.55747 54.9338 4.47649 54.0804 5.8612 52.8341C7.24591 51.5909 9.57884 50.9693 12.86 50.9693C16.6469 50.9693 19.3937 51.7219 21.1005 53.227C22.8073 54.7321 25.065 55.4846 27.8735 55.4846C30.9079 55.4846 33.2649 54.7321 34.9446 53.227C36.6273 51.7219 39.3124 50.9693 43 50.9693C46.6875 50.9693 49.3726 51.7219 51.0554 53.227C52.7351 54.7321 55.0921 55.4846 58.1264 55.4846C60.935 55.4846 63.1926 54.7321 64.8994 53.227C66.6062 51.7219 69.3531 50.9693 73.1399 50.9693C76.5957 50.9693 78.9286 51.5623 80.1387 52.7483C81.3489 53.9344 83.2679 54.8179 85.8958 55.3989V60C83.1114 60 80.9139 59.2474 79.3034 57.7423C77.6959 56.2372 75.6038 55.4846 73.0271 55.4846C70.0861 55.4846 67.7757 56.2372 66.096 57.7423C64.4193 59.2474 61.7251 60 58.0135 60C54.2116 60 51.546 59.2474 50.0168 57.7423C48.4876 56.2372 46.1487 55.4846 43 55.4846C39.8513 55.4846 37.5123 56.2372 35.9831 57.7423C34.4539 59.2474 31.7884 60 27.9864 60C24.2748 60 21.5806 59.2474 19.9039 57.7423C18.2242 56.2372 15.9139 55.4846 12.9729 55.4846C10.3389 55.4846 8.24681 56.2372 6.69654 57.7423C5.14326 59.2474 2.94579 60 0.104126 60ZM18.2558 41.9386C16.7357 41.9386 15.2787 41.6722 13.885 41.1394C12.4882 40.6066 11.2661 39.7878 10.2185 38.683L5.65801 34.0367L8.7736 30.9302L13.4154 35.4591C14.0415 36.0852 14.767 36.5698 15.5918 36.913C16.4166 37.2532 17.3046 37.4232 18.2558 37.4232H24.9386V18.8832L18.9557 23.2857L16.2555 19.7095L43 0L69.6586 19.6237L66.9584 23.3128L61.0614 18.9961V37.4232H67.7441C68.6953 37.4232 69.5834 37.2532 70.4082 36.913C71.233 36.5698 71.9584 36.0852 72.5846 35.4591L77.2309 30.9302L80.3374 34.0367L75.7769 38.5972C74.6722 39.702 73.438 40.5343 72.0743 41.0942C70.7077 41.6571 69.2658 41.9386 67.7486 41.9386H18.2558ZM29.4539 37.4232H40.7423V28.3925H45.2576V37.4232H56.546V15.6367L43 5.61258L29.4539 15.5464V37.4232Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default WaterfrontIcon;
