const GolfCourseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="60" viewBox="0 0 56 60" fill="none">
    <path
      d="M45.3551 40.7542C40.4215 38.9781 33.9029 38 27 38C23.6533 37.9921 20.3107 38.2342 17 38.7241V24.3946L36 17.3946V12.6283L17 3.72825V0H13V50H17V42.7707C20.3074 42.2469 23.6514 41.9892 27 42C33.4526 42 39.4901 42.8941 44 44.5179C48.4287 46.1125 50 47.9287 50 49C50 50.0713 48.4287 51.8875 44 53.4821C39.4901 55.1059 33.4526 56 27 56C20.5474 56 14.5099 55.1059 10 53.4821C5.57125 51.8875 4 50.0713 4 49C4 48.0137 5.33362 46.396 9 44.9015V40.6295C8.881 40.6707 8.7625 40.7121 8.64487 40.7545C1.5 43.3265 0 46.6581 0 49C0 51.3419 1.5 54.6735 8.64487 57.2458C13.5785 59.0219 20.0971 60 27 60C33.9029 60 40.4215 59.0219 45.3551 57.2458C52.5 54.6735 54 51.3419 54 49C54 46.6581 52.5 43.3265 45.3551 40.7542ZM17 8.14538L31.3232 14.8547L17 20.1318V8.14538Z"
      fill="#3C4D7D"
    />
    <path
      d="M52 37C54.2091 37 56 35.2091 56 33C56 30.7909 54.2091 29 52 29C49.7909 29 48 30.7909 48 33C48 35.2091 49.7909 37 52 37Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default GolfCourseIcon;
