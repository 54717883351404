import { COMMUNITY_IMAGE_URL } from '../../../constants/common';
import { CF_URL } from '../../../constants/common';

const getRandomImageUrl = (location, arr) => {
  if (arr?.length > 0) {
    let locationImage = arr.find((img) => img.location === location);
    if (locationImage) {
      if (locationImage.url.includes('http'))
        return locationImage.url.replace('nhs-dynamic.secure.footprint.net', 'nhs-dynamic-secure.akamaized.net');
      return COMMUNITY_IMAGE_URL + locationImage.url;
    }
    const randomIndex = Math.floor(Math.random() * arr.length);
    if (arr[randomIndex]?.url.startsWith('http'))
      return arr[randomIndex]?.url.replace('nhs-dynamic.secure.footprint.net', 'nhs-dynamic-secure.akamaized.net');
    return (
      COMMUNITY_IMAGE_URL +
      arr[randomIndex]?.url.replace('nhs-dynamic.secure.footprint.net', 'nhs-dynamic-secure.akamaized.net')
    );
    //return 'https://picsum.photos/700?random='+randomIndex;
  }
  return `${CF_URL}/web-assets/yourwayhome/community/assets/for-sale-default-logo.webp`;
};

export default getRandomImageUrl;
