import { styled } from '@mui/material';

const LIST_ITEMS = [
  'Rearrange or remove some of the furniture if necessary. As owners, many times we have too much furniture in a room. This is wonderful for our personal enjoyment, but when it comes to selling, we need to thin out as much as possible to make rooms appear larger.',
  'Take down/rearrange certain pictures/object on walls. Patch and paint if needed.',
  'Review the house inside room by room. Paint any room needing paint, clean carpets or drapes that need it, clean windows.',
  'Leave on certain lights during the day. For "showings" turn on all lights and lamps.',
  'Have stereo FM on during the day for all viewings.',
  `Lockbox--#1 Importance: "If we don't have it, they won't show it."`,
];

const Section6Container = styled('section')(({ theme }) => ({
  width: '100%',
  padding: '0 100px',
  backgroundColor: '#F4F4F6',
  [theme.breakpoints.down('md')]: {
    padding: '80px 0 0 0',
  },
  '& div.content': {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    padding: '0 64px 64px 64px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 0,
    },
    ul: {
      order: 2,
      maxWidth: 536,
      display: 'flex',
      gap: 24,
      padding: 0,
      flexShrink: 0,
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        flexShrink: 1,
      },
      [theme.breakpoints.down('md')]: {
        order: 1,
        maxWidth: 700,
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
      li: {
        fontSize: 20,
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 16,
        svg: {
          height: 25,
          width: 25,
          flexShrink: 0,
          marginTop: 4,
        },
      },
    },
    '& div.image': {
      order: 1,
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        order: 2,
      },
      img: {
        objectFit: 'cover',
        height: 728,
        maxWidth: '100%',
        borderRadius: 16,
        boxShadow: '-16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 400,
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
    },
  },
}));

const Section6 = () => {
  return (
    <Section6Container>
      <div className="content">
        <ul>
          {LIST_ITEMS.map((item) => (
            <li key={item}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5ZM17.5375 8.7125C17.7131 8.88828 17.8117 9.12656 17.8117 9.375C17.8117 9.62344 17.7131 9.86172 17.5375 10.0375L11.2875 16.2875C11.1117 16.4631 10.8734 16.5617 10.625 16.5617C10.3766 16.5617 10.1383 16.4631 9.9625 16.2875L7.4625 13.7875C7.37039 13.7017 7.29651 13.5982 7.24527 13.4832C7.19403 13.3682 7.16648 13.244 7.16426 13.1182C7.16204 12.9923 7.1852 12.8672 7.23235 12.7505C7.2795 12.6338 7.34968 12.5277 7.4387 12.4387C7.52773 12.3497 7.63377 12.2795 7.7505 12.2323C7.86724 12.1852 7.99227 12.162 8.11815 12.1643C8.24403 12.1665 8.36817 12.194 8.48317 12.2453C8.59817 12.2965 8.70167 12.3704 8.7875 12.4625L10.625 14.3L13.4187 11.505L16.2125 8.7125C16.3883 8.53694 16.6266 8.43832 16.875 8.43832C17.1234 8.43832 17.3617 8.53694 17.5375 8.7125Z"
                  fill="#5284E0"
                />
              </svg>
              {item}
            </li>
          ))}
        </ul>
        <div className="image">
          <img src="https://bucket.ywhdev.com/wl/?id=MfvWE8IUi3V5f0ozkTDfxxll8BWr5R4v&fmode=open" alt="" />
        </div>
      </div>
    </Section6Container>
  );
};

export default Section6;
