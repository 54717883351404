import { memo, useEffect, useState, useCallback } from 'react';
import { searchAgent, searchCommunities, assignCommunities } from '../../../services/admin';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AGENT_PROFILE_URL } from '../../../constants/common';

import Alert from 'react-bootstrap/Alert';

import { checkIsLoggedIn } from '../../../utils/common';
import { useNavigate } from "react-router-dom";
import routes from '../../../routes';

import './AssignCommunity.scss';
import AdminChecker from '../AdminChecker/AdminChecker';

const AssignCommunity = () => {
    const navigate = useNavigate();
    const [agentPage, setAgentPage] = useState(1);
    const [communityPage, setCommunityPage] = useState(1);
    const [agentKey, setAgentKey] = useState('')
    const [communityKey, setCommunityKey] = useState('')

    const [agents, setAgents] = useState([]);
    const [communities, setCommunities] = useState([]);

    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedCommunities, setSelectedCommunities] = useState([]);

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [showLoadingAlert, setShowLoadingAlert] = useState(false);

    const handleAgentPrevPage = () => {
        if (agentPage !== 1) {
            setAgentPage(agentPage - 1);
        }
        findAgents();
    }

    const handleAgentNextPage = () => {
        setAgentPage(agentPage + 1);
        findAgents();
    }

    const handleCommunityPrevPage = () => {
        if (communityPage !== 1) {
            setCommunityPage(communityPage - 1)
        }
    }

    const handleCommunityNextPage = () => {
        setCommunityPage(communityPage + 1)
    }

    const handleAgentSearchChange = (elem) => {
        setAgentKey(elem.target.value)
    };

    const handleCommunitySearchChange = (elem) => {
        setCommunityKey(elem.target.value);
    };

    const findAgents = async () => {
        let param = {
            key: agentKey,
            page: agentPage
        }
        let ax = await searchAgent(param);

        if (ax.result) {
            setAgents(ax.agents)
        }
    }

    const findCommunity = async () => {
        let param = {
            key: communityKey,
            page: communityPage
        }
        let ax = await searchCommunities(param);

        if (ax.result) {
            setCommunities(ax.communities)
        }
    }

    const handleAgentClick = useCallback(
        async (item) => {
            // Check if the item is already selected
            const isSelected = selectedAgents.includes(item);

            if (isSelected) {
                // Item is already selected, remove it from the selectedItems array
                setSelectedAgents(selectedAgents.filter((selectedItem) => selectedItem.id !== item.id));
            } else {
                // Item is not selected, add it to the selectedItems array
                setSelectedAgents([item]);
            }
        }
    );

    const handleCommunityClick = useCallback(
        async (item) => {
            // Check if the item is already selected
            const isSelected = selectedCommunities.includes(item);

            if (isSelected) {
                // Item is already selected, remove it from the selectedItems array
                setSelectedCommunities(selectedCommunities.filter((selectedItem) => selectedItem.id !== item.id));
            } else {
                // Item is not selected, add it to the selectedItems array
                setSelectedCommunities([...selectedCommunities, item]);
            }
        }
    );

    const handleAssignCommunities = async () => {
        setShowSuccessAlert(false);
        setShowFailAlert(false);
        setShowLoadingAlert(true);
        if (selectedAgents.length > 0 && selectedCommunities.length > 0) {
            let agent_id = selectedAgents[0].id;
            let community_ids = [];
            selectedCommunities.forEach(cm => {
                community_ids.push(cm.id)
            });
            community_ids = JSON.stringify(community_ids);

            let param = {
                agent_id: agent_id,
                community_ids: community_ids
            }

            let ax = await assignCommunities(param);
            if (ax.result) {
                setShowSuccessAlert(true);
                setShowLoadingAlert(false);
            } else {
                setShowFailAlert(true);
                setShowLoadingAlert(false);
            }
        }
    }

    const imageUrl = (url) => {
        return `${AGENT_PROFILE_URL}${url}`;
    }

    const handleImageError = (event) => {
        let avatar = 'avatar-user-profile.svg'
        event.target.src = imageUrl(avatar); // Set the placeholder image source
    };

    useEffect(() => {
        findAgents()
    }, [agentKey]);

    useEffect(() => {
        findCommunity();
    }, [communityKey]);

    useEffect(() => {
        findAgents()
    }, [agentPage]);

    useEffect(() => {
        findCommunity();
    }, [communityPage]);

    return (
        <div className='admin-content'>
            <AdminChecker/>
            {showSuccessAlert &&
                <Alert variant='success'>
                    Agent Assigned to Community.
                </Alert>
            }
            {showLoadingAlert &&
                <Alert variant='info'>
                    Saving Profile...
                </Alert>
            }
            {showFailAlert &&
                <Alert variant='danger'>
                    Assign failed.
                </Alert>
            }
            <Container>
                <Row>
                    <Col>
                        <input placeholder='Search Agents...' onChange={handleAgentSearchChange} />
                        <hr />
                        <ul>
                            {agents.map((agent) => (
                                <li
                                    key={agent.id}
                                    className={selectedAgents.includes(agent) ? 'selected agent-li' : 'agent-li'}
                                    onClick={() => handleAgentClick(agent)}
                                >
                                    <Container>
                                        <Row>
                                            <Col xs={2}>
                                                <img className='profile-image' src={imageUrl(agent.photo)} onError={handleImageError} />
                                            </Col>
                                            <Col>
                                                <p>{agent.first_name} {agent.last_name} | <strong>{agent.brokerage}</strong></p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </li>
                            ))}
                        </ul>
                        <p className='page-label'>Page {agentPage}</p>
                        <button onClick={handleAgentPrevPage}>Prev</button> <button onClick={handleAgentNextPage}>Next</button>
                    </Col>
                    <Col>
                        <input placeholder='Search Communities...' onChange={handleCommunitySearchChange} />
                        <hr />
                        <ul>
                            {communities.map((item) => (
                                <li
                                    key={item.id}
                                    className={selectedCommunities.includes(item) ? 'selected' : ''}
                                    onClick={() => handleCommunityClick(item)}
                                >
                                    <p>{item.name}</p>
                                </li>
                            ))}
                        </ul>
                        <p className='page-label'>Page {communityPage}</p>
                        <button onClick={handleCommunityPrevPage}>Prev</button> <button onClick={handleCommunityNextPage}>Next</button>
                    </Col>
                </Row>
                <Row>
                    <button id='assign-btn' onClick={handleAssignCommunities}>Assign Agent to Communities</button>
                </Row>
            </Container>
        </div>
    );
};

export default memo(AssignCommunity);