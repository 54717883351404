import { useQuery } from '@tanstack/react-query';
import { COMMUNITY } from './key';
import { getCommunity } from '../../../services/general';

export default function useGetCommunityDetails({ state, market, community, id }) {
  return useQuery({
    queryKey: [COMMUNITY, id],
    queryFn: async () => {
      const params = { state, market, community, id };

      const data = await getCommunity(params);

      return data;
    },
    enabled: !!id,
  });
}
