import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 993px) {
    max-width: 1280px;
  }
`;

const BackgroundTitle = styled.p`
  display: none;
  position: absolute;
  bottom: 0;
  left: -8px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 5rem !important;
  color: rgba(230, 233, 243, 0.6);
  font-weight: 700 !important;
  line-height: normal !important;

  @media (min-width: 993px) {
    display: block;
  }
`;

const MainTitle = styled.h2`
  color: #404041;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3.25rem;
  z-index: 10;
  position: relative;
`;

const Divider = styled.div`
  height: 0.25rem;
  width: 100%;
  border-radius: 4px;
  background-color: #c3b59b;
`;

const SectionTitle = ({ title, backgroundTitle }) => (
  <Container>
    <BackgroundTitle>{backgroundTitle ?? title}</BackgroundTitle>
    <MainTitle>{title}</MainTitle>
    <Divider />
  </Container>
);

export default SectionTitle;
