import { memo, useEffect, useState, useCallback, useContext, useRef } from 'react';
import { editCommunity } from '../../../services/admin';
import { getImages, getCommunityDescription, getCommunity, getHighlights, getAllHighlights, getAgent } from '../../../services/general';
import { useParams } from "react-router-dom";
import { COMMUNITY_IMAGE_URL } from '../../../constants/common';
import './EditCommunity.scss';
import AuthChecker from '../AuthChecker/AuthChecker';
import EditCommunityNew from '../editCommunity';
import { ToastContainer, toast } from 'react-toastify';
import { StaticNavigation } from '../layout';
import { MainContext } from '../../../context';
import _ from 'lodash';

const EditCommunity = () => {

    let { state, market, community, id } = useParams();
    const [description, setDescription] = useState(null);
    const [communityObj, setCommunity] = useState(null)

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [showLoadingAlert, setShowLoadingAlert] = useState(false);

    const [selectedImages, setSelectedImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    const [videos, setVideos] = useState([]);
    const [images, setImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);

    const [highlights, setHighlights] = useState([]);
    const [allHighlights, setAllHighlights] = useState([]);

    const { setActivePage, communityData } = useContext(MainContext);
    const [agent, setAgent] = useState(null);

    const fetchAllHighlights = async () => {
        let result = await getAllHighlights();
        if (result.result) {
            setAllHighlights(result.highlights)
        }
    }

    const fetchHighlights = async () => {
        let params = {
            state: state,
            market: market,
            community: community,
            id
        }
        let result = await getHighlights(params);
        if (result.result) {
            setHighlights(result.highlights)
        }
    }

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(files);

        const previews = files.map((file) => URL.createObjectURL(file));
        setImagePreviews(previews);
    };

    const handleImageClick = async (item) => {
        // Check if the item is already selected
        const isDeleted = deletedImages.includes(item);

        if (isDeleted) {
            // Item is already selected, remove it from the selectedItems array
            setDeletedImages(deletedImages.filter((deletedItem) => deletedItem.id !== item.id));
        } else {
            // Item is not selected, add it to the selectedItems array
            setDeletedImages([...deletedImages, item]);
        }
    };

    const handleHighlightsClick = async (item) => {
        console.log(highlights)
        console.log(allHighlights)
        // Check if the item is already selected
        const isSelected = highlights.some(obj => obj.name === item.name);
        console.log("Selected? " + isSelected)
        if (isSelected) {
            // Item is already selected, remove it from the selectedItems array
            setHighlights(highlights.filter((selectedItem) => selectedItem.id !== item.id));
        } else {
            // Item is not selected, add it to the selectedItems array
            setHighlights([...highlights, item]);
        }
    };

    const fetchImages = async () => {
        let params = {
            state: state,
            market: market,
            community: community,
            id
        }
        let result = await getImages(params);
        if (result.result) {
            if ((result.images?.length ?? 0) > 0 || (result.videos?.length ?? 0) > 0) {
                result.images.forEach(img => {
                    if (img.is_user_uploaded) {
                        img.url = imageUrl(img.image_name)
                    }
                });
                setImages(result.images ?? [])
                setVideos(result.videos ?? [])
                setCommunity({
                    ...community,
                    photos: images,
                    videos: videos
                })
            }
        }
    }

    const imageUrl = (url) => {
        return `${COMMUNITY_IMAGE_URL}${url}`;
    }

    const fetchDescription = async () => {
        let params = {
            state: state,
            market: market,
            community: community,
            id
        }
        let result = await getCommunityDescription(params);
        if (result.result) {
            setDescription(result.community_description.replace(/\n+/g, '\n').replace(/\r+/g, ''))
        }
    }

    const fetchCommunity = async () => {
        let params = {
            state: state,
            market: market,
            community: community,
            id
        }
        let result = await getCommunity(params);
        if (result.result) {
            setCommunity({
                ...result.community,
                loan_officer: result.community.loan_officer ? {value:  result.community.loan_officer.id, label:  result.community.loan_officer.first_name + ' ' + result.community.loan_officer.last_name}: null,
                address: result.address,
                market: result.market,
                builder: result.builder
            })
            setActivePage('edit-community')
        }
    }

    const fetchAgent = async () => {
        let params = {
          state: state,
          market: market,
          community: community,
          id,
        };
        const result = await getAgent(params);
        if (result.result) {
            setAgent(result.agent);
        }
      };
    const toastId = useRef();
    const [fieldErrors, setFieldErrors] = useState({})
    
    const handleEditCommunity = async (data) => {
        toastId.current = toast.loading('Saving...');
        try {
            const _data = {...data};

            if (_data.url && _data.url.startsWith('https://www.showingnew.com')) {
                const url = _data.url.split('/community')
                _data.url = ['https://www.newhomesource.com', url[1]].join('/community');
            }
            
            let result = await editCommunity(_data);
            //setShowLoadingAlert(false)
            if (result.result) {
                //setShowSuccessAlert(true);
                toast.update(toastId.current,{
                    render: result.message,
                    type: 'success',
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 2000,
                    onClose: () => {
                        window.location.href = `/community/${state}/${_.kebabCase(market)}/${_.kebabCase(communityData.name)}/${communityData.id}`
                        
                    }
                
                })
            } else {
                //setShowFailAlert(true)
                toast.update(toastId.current,{
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 2000,
                })
                if(result.errors) {
                    setFieldErrors(result.errors)
                }
            }
        } catch(e) {
            toast.update(toastId.current,{
                render: e.response.data.message,
                type: 'error',
                isLoading: false,
                closeOnClick: true,
                autoClose: 2000
            })
        }
    }

    const handleSubmit = async (values) => {
        values.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setShowSuccessAlert(false);
        setShowFailAlert(false);
        setShowLoadingAlert(true);
        let target = values.target;
        let data = {
            community_id: communityObj.id,
            name: target.name.value,
            price_min: target.price_min.value,
            price_max: target.price_max.value,
            area_min: target.area_min.value,
            area_max: target.area_max.value,
            homes: target.homes.value,
            quick_move_ins: target.quick_move_ins.value,
            description: target.description.value,
            photos: target.photos.files,
            deleted_images: deletedImages,
            highlights: highlights
        }

        handleEditCommunity(data);
    };

    useEffect(() => {
        fetchCommunity()
        fetchDescription()
        fetchImages()
        fetchHighlights()
        fetchAllHighlights()
        fetchAgent()
    }, []);


    const handleSave = () => {
        delete communityData.address
        delete communityData.builder
        handleEditCommunity({
            ...communityData,
            loan_officer: communityData.loan_officer && communityData.loan_officer.hasOwnProperty('value') ?  communityData.loan_officer.value : null,
            community_id: communityObj.id || communityData.id,
            
        })
    }
    return (
        <div>
            <AuthChecker/>
            <StaticNavigation handleSave={handleSave} page='edit-community'/>
            <EditCommunityNew
                community_description={description}
                community={communityObj}
                highlights={highlights}
                allHighlights={allHighlights}
                images={images}
                videos={videos}
                fieldErrors={fieldErrors}
                agent={agent}
             />
            <ToastContainer />
        </div>
    );
};

export default memo(EditCommunity);