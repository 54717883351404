import { styled, useMediaQuery, useTheme } from '@mui/material';
import CheckboxIcon from './CheckboxIcon';

const Section3Container = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  margin: '0 auto',
  marginBottom: 64,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '64px',
  paddingTop: 0,
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 64,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  '& div.content': {
    maxWidth: 526,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 672,
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    ul: {
      marginTop: 64,
      marginBottom: 40,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      li: {
        svg: {
          flexShrink: 0,
        },
        textAlign: 'left',
        display: 'flex',
        gap: 16,
        alignItems: 'center',
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 500,
      },
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 880,
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('lg')]: {
        height: 400,
      },
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        height: 'auto',
        boxShadow: 'none',
      },
    },
  },
}));

const Section3 = () => {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Section3Container>
      <div className="content">
        <div className="header">
          <img
            className="shadow-left"
            src="https://bucket.ywhdev.com/wl/?id=VbhP2hHfDysQkQK7HRWKaG6R1OmpiZuJ&fmode=open"
            alt=""
          />
          <h2>Common reasons for overpricing</h2>
        </div>
        <ul>
          <li>
            <CheckboxIcon />
            Over-improvement
          </li>
          <li>
            <CheckboxIcon />
            Need
          </li>
          <li>
            <CheckboxIcon />
            Purchasing in higher-priced area
          </li>
          <li>
            <CheckboxIcon />
            Original purchase price too high
          </li>
          <li>
            <CheckboxIcon />
            Lack of factual data
          </li>
          <li>
            <CheckboxIcon />
            Bargaining room
          </li>
          <li>
            <CheckboxIcon />
            The move isn't necessary
          </li>
          <li>
            <CheckboxIcon />
            Asessed value
          </li>
          <li>
            <CheckboxIcon />
            Emotional attachment
          </li>
          <li>
            <CheckboxIcon />
            An opinion of family and neighbors
          </li>
        </ul>
        <p>
          What really matters is how your home stacks up against the others currently offered for sale and recently sold
          in your neighborhood. Buyers will be comparing.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src={
            mdScreen
              ? 'https://bucket.ywhdev.com/wl/?id=0EZSGqxra7aaAWkdpE4gQlDlqxxE23gD&fmode=open'
              : 'https://bucket.ywhdev.com/wl/?id=3bnoPp4lg16h438JytZ8B7hL5Tluytbp&fmode=open'
          }
          alt=""
        />
      </div>
    </Section3Container>
  );
};

export default Section3;
