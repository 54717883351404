import styled from 'styled-components';

const StyledInput = styled.input.attrs({
  placeholderTextColor: '#b7b7b7',
})`
  display: flex;
  padding: 1rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid #eaeaea;
  background: #fcfcfc;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;

  &:focus {
    outline: none;
  }
`;

const Input = (props) => {
  return <StyledInput {...props} />;
};

export default Input;
