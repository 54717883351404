const PlaygroundIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="67" viewBox="0 0 64 67" fill="none">
    <path
      d="M3.66675 50.1667L20.3334 53.5L22.8334 48.5M40.3334 63.5V50.1667L27.0001 40.1667L30.3334 20.1667M30.3334 20.1667L13.6667 23.5V33.5M30.3334 20.1667L40.3334 30.1667L50.3334 33.5M30.3334 6.83333C30.3334 7.71739 30.6846 8.56523 31.3097 9.19036C31.9348 9.81548 32.7827 10.1667 33.6667 10.1667C34.5508 10.1667 35.3987 9.81548 36.0238 9.19036C36.6489 8.56523 37.0001 7.71739 37.0001 6.83333C37.0001 5.94928 36.6489 5.10143 36.0238 4.47631C35.3987 3.85119 34.5508 3.5 33.6667 3.5C32.7827 3.5 31.9348 3.85119 31.3097 4.47631C30.6846 5.10143 30.3334 5.94928 30.3334 6.83333ZM58.6668 60.1667C59.1088 60.1667 59.5327 59.9911 59.8453 59.6785C60.1578 59.366 60.3334 58.942 60.3334 58.5C60.3334 58.058 60.1578 57.634 59.8453 57.3215C59.5327 57.0089 59.1088 56.8333 58.6668 56.8333C58.2247 56.8333 57.8008 57.0089 57.4882 57.3215C57.1757 57.634 57.0001 58.058 57.0001 58.5C57.0001 58.942 57.1757 59.366 57.4882 59.6785C57.8008 59.9911 58.2247 60.1667 58.6668 60.1667Z"
      stroke="#3C4D7D"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default PlaygroundIcon;
