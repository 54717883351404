import { memo, useEffect, useRef, useState } from 'react';
import { AGENT_PROFILE_URL } from '../../../constants/common';
import { deleteAgent, getAgents } from '../../../services/admin';

import { checkIsLoggedIn, getUserType } from '../../../utils/common';

import { useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../components/loader';
import { MainContext } from '../../../context';
import Agents from '../agents';
import Layout from '../layout';
import './AgentList.scss';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage } from '../../../utils/storage';

const AgentList = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState(null);
  const { loading, setLoading, activePage, setActivePage, showDeleteAgent } = useContext(MainContext);

  const fetchAgent = async () => {
    setLoading(true);
    try {
      let data = await getAgents();
      if (data.result) {
        setAgents(data.agents);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const imageUrl = (url) => {
    return `${AGENT_PROFILE_URL}${url}`;
  };

  const handleImageError = (event) => {
    let avatar = 'avatar-user-profile.svg';
    event.target.src = imageUrl(avatar); // Set the placeholder image source
  };

  useEffect(() => {
    if (checkIsLoggedIn()) {
      if (getUserType() === '1') {
        const agentId = getLocalStorage('agent_id');

        if (!agentId) {
          navigate('/error/401', { replace: true });
        }

        navigate(`/admin/agent/${agentId}`, { replace: true });
      } else {
        fetchAgent();
      }
    } else {
      navigate('/error/401', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastId = useRef();
  const handleDelete = async (agent) => {
    if (window.confirm(`Are you sure you want to delete this agent ${agent.first_name} ${agent.last_name || ''}`)) {
      try {
        toastId.current = toast.loading('Deleting...');
        await deleteAgent({ agent_id: agent.id });
        setAgents([...agents.filter((a) => a.id !== agent.id)]);
        toast.update(toastId.current, {
          render: 'Agent successfully deleted',
          type: 'success',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
        });
      } catch (e) {
        toast.update(toastId.current, {
          render: e.message,
          type: 'error',
          isLoading: false,
          closeOnClick: true,
          autoClose: 2000,
        });
      }
    }
  };
  return (
    <div>
      <Layout active={activePage} setActive={(page) => setActivePage(page)}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Agents showDeleteAgent={showDeleteAgent} agents={agents} handleDelete={handleDelete} />
            <ToastContainer />
          </>
        )}
      </Layout>
    </div>
  );
};

export default memo(AgentList);
