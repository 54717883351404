import { AgentDetailsBio } from '../../../components/agent';
import { useMainContext } from '../../../context';
import { Layout } from '../components';
import { styled } from '@mui/material';

const FavoritesContainer = styled('section')(({ theme }) => ({
  padding: 32,
}));

const Favorites = () => {
  const { activeUser } = useMainContext();

  return (
    <Layout>
      <FavoritesContainer>
        {/* <h2>Favorites</h2> */}
        <div>
          <AgentDetailsBio
            title="COMMUNITIES"
            icon="bio-community"
            communities={activeUser?.communities.map(c => c.community) || []}
            subtitle="MY FAVORITE"
            type="gallery"
            readOnly={true}
          />
        </div>
      </FavoritesContainer>
    </Layout>
  );
};

export default Favorites;
