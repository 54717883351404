import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getLocalStorage = (key) => {
    return window.localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
    window.localStorage.setItem(key, value);
};

export const removeLocalStorage = (key) => {
    window.localStorage.removeItem(key);
};

export const getCookie = (key) => {
    return cookies.get(key);
};

export const setCookie = (key, value, options) => {
    return cookies.set(key, value, options);
};

export const removeCookie = (key) => {
    return cookies.remove(key);
};
