import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useSearchParams } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

const PolicyTabsContainer = styled('div')(() => ({
  backgroundColor: '#F7F7F7',
  padding: 32,
  display: 'flex',
  gap: 48,
  justifyContent: 'center',
}));

const PolicyTab = styled('button')(({ theme, selected }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: selected ? '#C3B59B' : 'transparent',
  backgroundColor: 'transparent',
  color: '#494949',
  fontSize: 18,
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const Policy = () => {
  const [params] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState('PrivacyPolicy');

  useEffect(() => {
    if (params.get('tab')) {
      setSelectedTab(params.get('tab'));
    }
  }, [params]);

  return (
    <div>
      <PolicyTabsContainer>
        <PolicyTab onClick={() => setSelectedTab('PrivacyPolicy')} selected={selectedTab === 'PrivacyPolicy'}>
          Privacy Policy
        </PolicyTab>
        <PolicyTab onClick={() => setSelectedTab('TermsOfService')} selected={selectedTab === 'TermsOfService'}>
          Terms of Service
        </PolicyTab>
      </PolicyTabsContainer>
      {selectedTab === 'PrivacyPolicy' ? <PrivacyPolicy /> : <TermsOfService />}
    </div>
  );
};

export default Policy;
