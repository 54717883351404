import { styled } from '@mui/material';

const Section1Container = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  margin: '0 auto',
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '48px 64px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
  },
  '& div.content': {
    flexBasis: '50%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& div.header': {
      maxWidth: 526,
      marginBottom: 32,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      maxWidth: 526,
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    flexBasis: '50%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 408,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="content">
        <div className="header">
          <img src="https://bucket.ywhdev.com/wl/?id=BqyG7fuROOlSQVAK78QUfv2JFKvzrkFK&fmode=open" alt="" />
          <h2>Multiple Listing Service</h2>
        </div>
        <p>
          Your home is immediately listed on the Multiple Listing Service which in turns syndicates in real-times to
          thousands of websites including national portals such as Zillow and Realtor.com. We personally submit quality
          photos and the descriptive elements of your home to ensure that you have the best possible representation on
          the MLS.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=EGKKGQzzNhAjMapsBMOM5ngNFX78M7K2&fmode=open"
          alt="showing a beautiful 2-storey house."
        />
      </div>
    </Section1Container>
  );
};

export default Section1;
