import { useMemo, useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import Agent from './Agent';
import LoanOfficer from './LoanOfficer';
import Tab from './Tab';
import { S3_URL, CF_URL } from '../../../../constants/common';

export const Divider = styled.svg`
  display: none;
  max-width: 1500px;
  width: 100%;
  margin: 0px auto -10px;

  &.bottom {
    margin-top: -10px;
  }
  @media (min-width: 993px) {
    display: block;
  }
`;

const Section = styled.section`
  margin-top: 4rem;
`;

const AgentLoanOfficerSectionContainer = styled.div`
  background-color: #f7f7f7;
  padding: 0 2rem;

  @media (min-width: 993px) {
    padding: 0;
  }
`;

const AgentLoanOfficerContainer = styled.div`
  margin: 0 auto;
  padding: 5rem 0;
  max-width: 1280px;

  @media (min-width: 993px) {
    padding: 5rem 32px;
  }
`;

const AgentLoanOfficerContent = styled.div`
  margin: 3.75rem auto 0 auto;
  max-width: 1280px;

  & .tabs {
    display: flex;
    gap: 0.25rem;
  }

  & .content {
    border: 1px solid #eaeaea;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 0.75rem;
    border-top-left-radius: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    @media (min-width: 993px) {
      padding: 3rem;
    }
  }
`;

const AgentLoanOfficer = ({
  agent,
  loanOfficer,
  communityName,
  community,
  state,
  market,
  community_id,
  subdomainAgent,
}) => {
  const [selectedTab, setSelectedTab] = useState('Agent');

  const getAgent = subdomainAgent ?? agent;

  const title = useMemo(() => {
    if (selectedTab === 'Agent') {
      if (!agent) {
        return '';
      }

      return `Hi! I'm ${agent?.first_name ?? ''} ${
        agent?.last_name ?? ''
      }, your local community specialist. How can I help?`;
    }

    return `Loan Officer: ${loanOfficer?.first_name} ${loanOfficer?.last_name}. How can I help?`;
  }, [agent, loanOfficer?.first_name, loanOfficer?.last_name, selectedTab]);

  return (
    <Section>
      <Divider xmlns="http://www.w3.org/2000/svg" width="1500" height="99" viewBox="0 0 1500 99" fill="none">
        <path d="M393 0.272461L0 93.2725V99.2725H1500V93.6725L393 0.272461Z" fill="#F7F7F7" />
      </Divider>
      <AgentLoanOfficerSectionContainer>
        <AgentLoanOfficerContainer>
          <SectionTitle title={title} backgroundTitle="" />
          <AgentLoanOfficerContent>
            <div className="tabs">
              <Tab
                isSelected={selectedTab === 'Agent'}
                img={`${S3_URL}/images/agent_profile/${getAgent?.photo}`}
                text="Agent"
                onClick={() => setSelectedTab('Agent')}
              />
              {!!loanOfficer && (
                <Tab
                  isSelected={selectedTab === 'LoanOfficer'}
                  img={`${S3_URL}/images/loan_officer/${loanOfficer?.headshot}`}
                  text="LoanOfficer"
                  onClick={() => setSelectedTab('LoanOfficer')}
                />
              )}
            </div>
            <div className="content">
              {selectedTab === 'Agent' ? (
                <Agent
                  agent={agent}
                  communityName={communityName}
                  community={community}
                  state={state}
                  market={market}
                  subdomainAgent={subdomainAgent}
                  community_id={community_id}
                />
              ) : (
                <LoanOfficer loanOfficer={loanOfficer} />
              )}
            </div>
          </AgentLoanOfficerContent>
        </AgentLoanOfficerContainer>
      </AgentLoanOfficerSectionContainer>
      <Divider
        className="bottom"
        xmlns="http://www.w3.org/2000/svg"
        width="1500"
        height="99"
        viewBox="0 0 1500 99"
        fill="none"
      >
        <path d="M1107 99.2725L1500 6.27246V0.272461H0V5.87246L1107 99.2725Z" fill="#F7F7F7" />
      </Divider>
    </Section>
  );
};

export default AgentLoanOfficer;
