import { getLocalStorage, removeLocalStorage, setLocalStorage } from './storage';
import { STORAGE_KEYS } from '../constants/common';
import { checkTokenIsExpired } from './jwt';
import { useEffect } from 'react';

export const checkIsLoggedIn = () => {
    const accessToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)));
    // const refreshToken = JSON.parse(JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN)));

    if (checkTokenIsExpired(accessToken)) {
        // if (checkTokenIsExpired(refreshToken)) {
        //     removeAccessToken();
        // } else {
        //     return 'request-refresh';
        // }
        return false;
    } else {
        if (!accessToken) {
            return false;
        }
        return true;
    }
};

export const setAccessToken = (data) => {
    setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, data.token);
    // setLocalStorage(STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token);
};

export const setUserType = (type) => {
    setLocalStorage(STORAGE_KEYS.USER_TYPE, type);
    // setLocalStorage(STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token);
};

export const removeAccessToken = () => {
    removeLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
    removeLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);
};

export const getAccessToken = () => {
    if (checkIsLoggedIn()) {
        const accessToken = JSON.stringify(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN));
        if (accessToken) {
            return JSON.parse(accessToken);
        }
    }
    return '';
};

export const getUserType = () => {
    if (checkIsLoggedIn()) {
        const userType = JSON.stringify(getLocalStorage(STORAGE_KEYS.USER_TYPE));
        if (userType) {
            return JSON.parse(userType);
        }
    }
    return '';
};

export const useScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};
