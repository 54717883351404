const VolleyballIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
    <path
      d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.012998 24.0013 -0.581101 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4235C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4457 51.6476 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C59.9907 22.0464 56.827 14.4212 51.2029 8.79711C45.5788 3.17305 37.9536 0.00934073 30 0ZM54.5206 39.9676C50.3255 42.8845 45.3759 44.5248 40.2692 44.6908C35.1624 44.8568 30.1168 43.5412 25.7412 40.9029L31.0177 31.7647H56.4118C56.2255 34.5828 55.587 37.3525 54.5206 39.9676ZM18.3235 6.24706C20.8553 4.99871 23.5714 4.16553 26.3677 3.77941C30.9918 5.95355 34.8879 9.4196 37.5856 13.7592C40.2833 18.0988 41.6673 23.1264 41.5706 28.2353H31.0177L18.3235 6.24706ZM56.4118 28.2353H45.1C45.1781 23.4893 44.1302 18.7923 42.0422 14.5295C39.9543 10.2667 36.8858 6.55949 33.0882 3.71176C39.2243 4.44113 44.913 7.29075 49.1715 11.7684C53.43 16.246 55.9909 22.0703 56.4118 28.2353ZM15.2706 8.01764L20.9206 17.8029C16.7719 20.1091 13.2286 23.3653 10.5809 27.3049C7.93324 31.2444 6.25656 35.7552 5.68825 40.4676C3.24238 34.7914 2.86161 28.4378 4.61205 22.51C6.36249 16.5823 10.1336 11.4547 15.2706 8.01764ZM9.11766 46.2323C8.69326 41.1443 9.74939 36.0421 12.1588 31.5406C14.5681 27.0392 18.2279 23.3306 22.6971 20.8618L27.9618 30L15.2706 51.9823C12.9278 50.404 10.8509 48.4631 9.11766 46.2323ZM30 56.4706C25.9506 56.4749 21.9549 55.5449 18.3235 53.7529L23.9765 43.9618C28.0455 46.4007 32.6343 47.8412 37.3672 48.1653C42.1 48.4894 46.8424 47.6879 51.2059 45.8265C48.7445 49.1298 45.5455 51.8124 41.8638 53.6604C38.182 55.5084 34.1195 56.4707 30 56.4706Z"
      fill="#3C4D7D"
    />
  </svg>
);

export default VolleyballIcon;
