const getRandomItems = (arr, number) => {
  const copyArray = arr.slice();
  const randomItems = [];

  for (let i = 0; i < number; i++) {
    const randomIndex = Math.floor(Math.random() * copyArray.length);

    randomItems.push(copyArray.splice(randomIndex, 1)[0]);
  }

  return randomItems;
};

export default getRandomItems;
