import { styled } from '@mui/material';

const Section3Container = styled('div')(({ theme }) => ({
  backgroundColor: '#1F1F1F',
  '& div.content': {
    maxWidth: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
    height: 300,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 370,
      textAlign: 'center',
      maxWidth: '100%',
      padding: '0 64px',
    },
    [theme.breakpoints.down('sm')]: {
      height: 450,
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '48px',
      color: 'white',
    },
    img: {
      height: 114.5,
      width: 120,
    },
  },
}));

const Section3 = () => (
  <Section3Container>
    <div className="content">
      <img src="https://bucket.ywhdev.com/wl/?id=VP523ezXSk8nkCqCeCz2gjb99hnj5ETW&fmode=open" alt="" />
      <h2>If your dreams don’t scare you, they’re not big enough.</h2>
    </div>
  </Section3Container>
);

export default Section3;
