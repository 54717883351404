const TrailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="64" viewBox="0 0 70 64" fill="none">
    <path
      d="M35 52.7692V62M35 25.0769V34.3077M35 2V6.61538M58.5294 25.0769H12.4456C12.1364 25.077 11.8303 25.0173 11.5446 24.9012C11.259 24.7851 10.9995 24.615 10.7809 24.4005L2.05884 15.8462L10.7809 7.29183C10.9995 7.07733 11.259 6.90719 11.5446 6.79112C11.8303 6.67505 12.1364 6.61534 12.4456 6.61538H58.5294C59.1535 6.61538 59.7519 6.85852 60.1932 7.29129C60.6345 7.72407 60.8824 8.31104 60.8824 8.92308V22.7692C60.8824 23.3813 60.6345 23.9682 60.1932 24.401C59.7519 24.8338 59.1535 25.0769 58.5294 25.0769ZM11.4706 52.7692H57.5544C57.8636 52.7693 58.1698 52.7096 58.4554 52.5935C58.7411 52.4774 59.0006 52.3073 59.2191 52.0928L67.9412 43.5385L59.2191 34.9841C59.0006 34.7696 58.7411 34.5995 58.4554 34.4834C58.1698 34.3674 57.8636 34.3076 57.5544 34.3077H11.4706C10.8466 34.3077 10.2481 34.5508 9.80682 34.9836C9.36556 35.4164 9.11766 36.0033 9.11766 36.6154V50.4615C9.11766 51.0736 9.36556 51.6605 9.80682 52.0933C10.2481 52.5261 10.8466 52.7692 11.4706 52.7692Z"
      stroke="#3C4D7D"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TrailIcon;
