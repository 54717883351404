import { styled } from '@mui/material';
import CheckboxIcon from './CheckboxIcon';

const Section1Container = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  margin: '0 auto',
  marginBottom: 64,
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '64px',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 64,
    paddingTop: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    marginBottom: 0,
  },
  '& div.content': {
    maxWidth: 526,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 672,
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 32px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32,
      [theme.breakpoints.down('lg')]: {},
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    ul: {
      marginTop: 64,
      marginBottom: 40,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      li: {
        svg: {
          flexShrink: 0,
        },
        textAlign: 'left',
        display: 'flex',
        gap: 16,
        alignItems: 'center',
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 500,
      },
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 608,
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      objectFit: 'cover',
      [theme.breakpoints.down('lg')]: {
        height: 400,
        width: 672,
      },
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        width: '100%',
        boxShadow: 'none',
      },
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="content">
        <div className="header">
          <img src="https://bucket.ywhdev.com/wl/?id=FGfj0fExcZJHitAQK1QLQjRaxJVKNrSR&fmode=open" alt="" />
          <h2>The right price should</h2>
        </div>
        <ul>
          <li>
            <CheckboxIcon />
            Attract buyers
          </li>
          <li>
            <CheckboxIcon />
            Allow you to earn the most money possible
          </li>
          <li>
            <CheckboxIcon />
            Help you sell as quickly as possible
          </li>
        </ul>
        <p>
          Simply put, price is the number one factor that most homebuyers use to determine which homes they want to
          view. It's important to remember that, although the price is set by you, the value of the is determined by the
          buyer. Try to avoid allowing your enthusiasm to impact your better judgment - overpricing is a common mistake
          that can cost you in the end.
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=KOxLCjVsvToHqgrMz4B9hj8ZoM8dVjZb&fmode=open"
          alt=""
        />
      </div>
    </Section1Container>
  );
};

export default Section1;
