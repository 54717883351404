import { useEffect, useState } from 'react';
import { getCitiesByState, getCommunity, getCommunityList, getMarkets } from '../../services/general';
import { useNavigate, useParams } from 'react-router-dom';
import { DOMAIN, getStateByCode } from '../../constants/common';
import _ from 'lodash';
import './MarketList.scss';
import { useMainContext } from '../../context';
import Loader from '../../components/loader';
import { styled } from '@mui/material';
import { STATE_IMAGES } from '../StateList/StateList';
import { getRequest } from '../../services/base';

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  maxWidth: 1500,
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 auto',
  padding: '0 64px',
  [theme.breakpoints.down('md')]: {
    padding: '0 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
  },
  h2: {
    fontSize: 39,
    fontWeight: 700,
    lineHeight: '50.7px',
    color: '#04246B',
  },
  h3: {
    fontSize: 30,
    fontWeight: 400,
    lineHeight: '39px',
  },
}));

const HeroSection = styled('section')(({ image }) => ({
  background: `url("${image}")`,
  overflow: 'hidden',
  height: 700,
  '@media (max-width: 600px)': {
    height: 450,
  },
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'center',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  gap: 8,
  textAlign: 'center',
  '& div.header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    h1: {
      textTransform: 'uppercase',
      color: 'white',
      fontFamily: 'Playfair Display',
      zIndex: 2,
      fontSize: 59,
      lineHeight: '76.7px',
      fontWeight: 700,
      padding: '32px 64px',
      backgroundColor: '#000000B2',
      width: '100%',
    },
  },
}));

const AccordionItem = ({city, state, stateName, i}) => {
  const [communities, setCommunities] = useState([])
  const [active, setActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const clickAccordion = (event) => {
    event.preventDefault();
    const accordionItem = event.target.parentElement;
    const accordionContent = event.target.nextElementSibling;

    // Close previously opened accordion
    const activeAccordion = document.querySelector('.state-accordion__item.active');
    if (activeAccordion && activeAccordion !== accordionItem) {
      activeAccordion.classList.remove('active');
      const activeAccordionContent = activeAccordion.querySelector('.state-accordion__content');
      activeAccordionContent.style.maxHeight = '0';
    }

    // Toggle current accordion
    accordionItem.classList.toggle('active');
    if (accordionItem.classList.contains('active')) {
      setActive(true)
       if (accordionContent) {
        accordionContent.style.maxHeight = 'inherit';
      }
    } else {
      if (accordionContent) {
        accordionContent.style.maxHeight = '0';
      }
    }
  };

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const formatCurrency = (number) => {
    if (number === null || number === 0) {
      return '0';
    }
    return USDollar.format(number);
  };

  const homePriceRange = (cm) => {
    if (cm.price_min == 0 && cm.price_max == 0) {
      return '';
    }
    return (
      <>
        , Homes from {formatCurrency(cm.price_min)} - {formatCurrency(cm.price_max)}
      </>
    );
  };

  const fetchCommunity = async () => {
    setIsLoading(true)
    const result = await getCommunityList({ market: city, state})
    if(result.result) {
      setTimeout(() =>  setIsLoading(false), 500 )
      setCommunities(result.communities)
    }
    
  }

  useEffect(() => {
    if(active && communities.length == 0) { // just fetch one time
      fetchCommunity()
    }
  }, [active])
  return (
    <div className="state-accordion__item">
    <div
      className={i === 0 ? 'state-accordion__header accordion-top' : 'state-accordion__header'}
      onClick={clickAccordion}
    >
      <h4 className="no-click">
        {city}, {stateName}
      </h4>
      <span className="state-accordion__icon no-click">+</span>
    </div>

    <div className="state-accordion__content"> 
      <div className="content-wrapper">
        {isLoading && <Loader />}
        {!isLoading && <ul>
          {communities.map((cm, i) => {
            let url = `/community/${cm.slug.substring(0, 2)}/${_.kebabCase(
              city,
            )}/${_.kebabCase(cm.name)}/${cm.id}`;
            return (
              <li className="community-list">
                <a className="community-link" href={url} target="_blank">
                  {cm.name}
                  {homePriceRange(cm)}
                </a>
              </li>
            );
          })}
        </ul> }
      </div>
    </div>
  </div>
  )
}

const MarketList = () => {
  const navigate = useNavigate();
  let { state } = useParams();
  const [markets, setMarkets] = useState([]);
  const [stateObj, setStates] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, setLoading, loadMore} = useMainContext();

  const fetchMarkets = async () => {
    setLoading(true);
    try {
      let result = await getCitiesByState(state);
      if (result.result) {
        setMarkets(result.cities.data);
        setNextPage(`/api/cities/${state}?page=${currentPage + 1}`);
        setCurrentPage(currentPage + 1)
        setStates(getStateByCode(result.state));
      } else {
        navigate('/error/404', { replace: true });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMarkets();
  }, []);


  const fetchMoreMarkets = async () => {
    if(nextPage) {
      const result = await getRequest(nextPage)
      if(result.cities.data.length > 0) {
        setMarkets(markets.concat(result.cities.data));
        setNextPage(`/api/cities/${state}?page=${currentPage + 1}`);
        setCurrentPage(currentPage + 1);
      } else setNextPage(null)
    }
  }
  useEffect(() => {
    if(loadMore)  {
      fetchMoreMarkets()
    }
  }, [loadMore])

  return loading ? (
    <Loader />
  ) : (
    <>
      <HeroSection image={STATE_IMAGES[state.toUpperCase()] || STATE_IMAGES['AZ']}>
        <div className="header">
          <h1>{getStateByCode(state.toUpperCase())}</h1>
        </div>
      </HeroSection>
      <section className="city">
        <Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path
              d="M84.8946 77.9794C87.0525 74.5954 88.2786 70.5248 88.2786 66.2089C88.2786 53.948 78.4698 44.1393 66.2089 44.1393C53.948 44.1393 44.1393 53.948 44.1393 66.2089C44.1393 78.4698 53.948 88.2786 66.2089 88.2786C70.4757 88.2786 74.4973 87.0525 77.8813 84.9436L93.1829 100L100 93.1829L84.8946 77.9794ZM66.2089 78.4698C62.9571 78.4698 59.8385 77.1781 57.5392 74.8787C55.2398 72.5793 53.948 69.4607 53.948 66.2089C53.948 62.9571 55.2398 59.8385 57.5392 57.5392C59.8385 55.2398 62.9571 53.948 66.2089 53.948C69.4607 53.948 72.5793 55.2398 74.8787 57.5392C77.1781 59.8385 78.4698 62.9571 78.4698 66.2089C78.4698 69.4607 77.1781 72.5793 74.8787 74.8787C72.5793 77.1781 69.4607 78.4698 66.2089 78.4698ZM14.7131 83.3742V44.1393H0L49.0437 0L98.0873 44.1393H89.1614C84.9366 39.7167 79.5187 36.6158 73.5655 35.2133L49.0437 13.1927L24.5218 35.2624V73.5655H35.2133C36.0471 77.0966 37.4693 80.3825 39.382 83.3742H14.7131Z"
              fill="#CDA07D"
            />
          </svg>
          <h2>Featured Cities With New Homes</h2>
          <h3>
            Explore these featured cities and their new home communities. With detailed information on communities,
            amenities, prices and floorplans you can make an informed decision on your next home.
          </h3>
        </Header>
        <section className="state-accordion">
          {markets &&
            markets
              .map((mk, i) => {
                return (
                  <AccordionItem i={i} city={mk.city} state={state} stateName={stateObj} />
                );
              })}
        </section>
      </section>
    </>
  );
};

export default MarketList;
