const getCroppedImg = (image, pixelCrop) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject(new Error('No 2d context'));
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);

    const cropX = pixelCrop.x * scaleX;
    const cropY = pixelCrop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(0);
    ctx.scale(1, 1);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    ctx.restore();

    // As Base64 string
    const base64Image = canvas.toDataURL('image/png');

    // As a blob
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Error creating blob.'));
        return;
      }
      resolve({
        blob: blob,
        base64Image: base64Image,
      });
    }, 'image/png');
  });
};

export default getCroppedImg;
