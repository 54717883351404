import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Modal, ModalBody } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Slider from 'react-slick';
import {
  AgentLoanOfficer,
  Builder,
  CommunityImage,
  Description,
  Details,
  Gallery,
  Highlights,
  ViewHomes,
} from './components';
import {
  useGetCommunityAgent,
  useGetCommunityDescription,
  useGetCommunityDetails,
  useGetCommunityHighlights,
  useGetCommunityImages,
} from './hooks';
import getRandomImageUrl from './utils/getRandomImages';
import { Helmet } from 'react-helmet';
import { US_STATES } from '../../constants/common';
import { MainContext } from '../../context';
import { getUserType } from '../../utils/common';
import { getLocalStorage } from '../../utils/storage';
import { UserStaticNavigation } from '../User/components';
import { StaticNavigation } from '../Admin/layout';
import { ScheduleModal } from './Community';
import { scheduleTour } from '../../services/general';

const CommunityHero = styled.div`
  height: 700px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    flex: 1;
    border-right: 1px solid white;
    object-fit: cover;

    @media (min-width: 992px) {
      max-width: 50%;
    }
  }
`;

const CommunityHeroImageContainer = styled.div`
  display: none;
  flex: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (min-width: 992px) {
    display: flex;
  }

  img {
    height: 350px;
    min-width: 50%;
    border: 1px solid white;
    object-fit: cover;
  }
`;

const CommunityDetailsContainer = styled.div`
  border-radius: 1rem;
  background-color: white;
  padding: 3rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 10;
  margin: -200px auto 0;
  max-width: calc(100% - 4rem);
  text-align: center;

  @media (min-width: 992px) {
    max-width: 1100px;
  }

  & .header {
    h1 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3rem;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.25rem;
      margin-bottom: 1rem;
    }
  }
`;

const STATIC_KEYWORDS = ['new homes', 'home sales', 'home sales near me', 'new home sales'];

const sliderSettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <img src="https://bucket.ywhdev.com/wl/?id=O2Sn57iluv4VB7jKfYdD6aC5zYJMQ3OH&fmode=open" alt="" />,
  nextArrow: <img src="https://bucket.ywhdev.com/wl/?id=xVvbeIxqKMg7ii1L6l1hswvyAaiQ9qAy&fmode=open" alt="" />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const getMonth = (index) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months[index];
};

const getDayShorten = (index) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[index];
};
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0);

const INIT_TOUR = {
  name: '',
  email: '',
  phone: '',
  message: '',
  schedule: tomorrow,
  preferred_time: '',
  tour_type: 1,
};
const Community = () => {
  const { state, market, community, id } = useParams();
  const { isLoggedIn, activeUser, setActiveUser, setSubdomainAgent, setActivePage, subdomainAgent } =
    useContext(MainContext);
  const [showSchedule, setShowSchedule] = useState(false);
  const [tour, setTour] = useState(INIT_TOUR);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useRef(null);

  const { data: communityData } = useGetCommunityDetails({ state, market, community, id });
  const { data: communityImages } = useGetCommunityImages({ state, market, community, id });
  const { data: communityAgent } = useGetCommunityAgent({ state, market, community, id });
  const { data: communityDescription } = useGetCommunityDescription({ state, market, community, id });
  const { data: communityHighlights } = useGetCommunityHighlights({ state, market, community, id });

  const hero_0 = useMemo(() => getRandomImageUrl('hero_0', communityImages?.images), [communityImages]);
  const hero_1 = useMemo(() => getRandomImageUrl('hero_1', communityImages?.images), [communityImages]);
  const hero_2 = useMemo(() => getRandomImageUrl('hero_2', communityImages?.images), [communityImages]);
  const hero_3 = useMemo(() => getRandomImageUrl('hero_3', communityImages?.images), [communityImages]);
  const hero_4 = useMemo(() => getRandomImageUrl('hero_4', communityImages?.images), [communityImages]);

  const communityName = useMemo(() => communityData?.community?.name ?? '', [communityData?.community?.name]);
  const builderName = useMemo(() => communityData?.builder?.name ?? '', [communityData?.builder?.name]);
  const communityAddress = useMemo(
    () => [
      communityData?.address.state ?? '',
      communityData?.address.city ?? '',
      US_STATES.find((state) => state.abbreviation === communityData?.address.state)?.name ?? '',
    ],
    [communityData?.address.city, communityData?.address.state],
  );

  const fullStateName = useMemo(() => {
    const state = US_STATES.find((state) => state.abbreviation === communityData?.address.state);
    return `${communityName}, ${communityData?.address?.city || ''}, ${state?.name || ''} (${communityData?.address?.state ?? ''})`;
  }, [
    communityData?.address.state,
  ]);

  const title = useMemo(() => {
    return communityData
      ? `Homes in ${fullStateName}`
      : 'YourWayHome';
  }, [communityData?.address.city, communityData?.address.state]);

  const metaDesc = useMemo(() => {
    return communityData
      ? `Explore new homes and master-planned communities in ${fullStateName}. Browse through floor plans, photos, videos, and other highlights of these new homes.`
      : ''
  }, [communityData?.address.city, communityData?.address.state]);

  const metaKeywords = useMemo(
    () => [...STATIC_KEYWORDS, communityName, builderName, ...communityAddress],
    [builderName, communityAddress, communityName],
  );

  const sendSchedule = async (data) => {
    let params = {
      state: state,
      market: market,
      community: community,
      id,
    };
    try {
      if (subdomainAgent) {
        data.agent_id = subdomainAgent.id;
      }
      await scheduleTour(params, data);
      setSubmitting(false);
      setSuccess(true);
      setTour(INIT_TOUR);
      setTimeout(() => setSuccess(false), 3000);
    } catch (e) {
      setSubmitting(false);
      setError(true);
    }
  };

  useEffect(() => {
    setActivePage('view-community');
  });

  const handleTourSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    sendSchedule({
      ...tour,
      schedule: tour.schedule.toISOString().split('T')[0],
    });
  };
  const handleTourChange = (e) => {
    const { name, value } = e.target;
    setTour({
      ...tour,
      [name]: value,
    });
  };

  const getAgent = subdomainAgent ?? (communityAgent?.agent ?? null);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={metaDesc}
        />
        <meta name="keywords" content={metaKeywords.join(', ')} />
      </Helmet>
      {isLoggedIn &&
        (getUserType() == 2 ||
          (getLocalStorage('agent_id') && communityAgent && getLocalStorage('agent_id') == communityAgent?.id)) && (
          <StaticNavigation page="view-community" />
        )}
      {isLoggedIn && getUserType() == 3 && (
        <UserStaticNavigation
          activeUser={activeUser}
          setActiveUser={setActiveUser}
          setSubdomainAgent={setSubdomainAgent}
          top={116}
        />
      )}
      <section>
        <div>
          <CommunityHero>
            <CommunityImage alt="" src={hero_0} />
            <CommunityHeroImageContainer>
              <CommunityImage alt="" src={hero_1} />
              <CommunityImage alt="" src={hero_2} />
              <CommunityImage alt="" src={hero_3} />
              <CommunityImage alt="" src={hero_4} />
            </CommunityHeroImageContainer>
          </CommunityHero>
          <CommunityDetailsContainer>
            <div className="header">
              <h1>{communityName}</h1>
              <h2>Community by: {builderName}</h2>
              <Description description={communityData?.community?.description ?? ''} />
            </div>
            <ViewHomes
              url={communityData?.community?.url ?? ''}
              fallbackUrl={communityData?.community?.fallback_nhs_url}
              agentIdentifier={getAgent?.showingnew_identifier || ''}
              city={communityData?.address?.city ?? ''}
              state={communityData?.address?.state ?? ''}
              priceMax={communityData?.community?.price_max}
              priceMin={communityData?.community?.price_min}
            />
          </CommunityDetailsContainer>
        </div>
        <Details communityDetails={communityData} />
        <Highlights
          description={communityData?.community.highlights_description}
          highlights={communityHighlights?.highlights ?? []}
          communityDescription={communityDescription?.community_description}
          images={communityImages?.images ?? []}
          additionalHighlights={communityData?.community?.additional_highlights}
        />
        <AgentLoanOfficer
          agent={communityAgent?.agent}
          loanOfficer={communityData?.community?.loan_officer}
          communityName={communityData?.community.name}
          community={community}
          state={state}
          market={market}
          subdomainAgent={subdomainAgent}
          community_id={id}
        />
        <Gallery images={communityImages?.images ?? []} videos={communityImages?.videos ?? []} />
        <Builder builder={communityData?.builder} />
        <button class="sticky-button" onClick={() => setShowSchedule(!showSchedule)}>
          Schedule a Tour
        </button>
        <ScheduleModal
          show={showSchedule}
          centered
          onHide={() => setShowSchedule(!showSchedule)}
          className="modal-schedule"
        >
          <div class="schedule-a-tour">
            <div class="tour-card">
              <div class="tour-card__content-wrapper">
                {/* <!-- HEADING --> */}
                <div class="tour-card__heading">
                  <img src="https://bucket.ywhdev.com/wl/?id=XJA9n3mcBEdFUQXTYSQ6rG4cYfaOu9jF&fmode=open" alt="" />
                  <h3>Schedule a Tour</h3>
                </div>
                {/* <!-- SELECT BUTTON --> */}
                <div class="select-btn">
                  <button
                    type="button"
                    onClick={() =>
                      setTour({
                        ...tour,
                        tour_type: 1,
                      })
                    }
                    class={`select-btn--${tour.tour_type == 1 ? 'active' : 'regular'}`}
                  >
                    <div>In-Person</div>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      setTour({
                        ...tour,
                        tour_type: 2,
                      })
                    }
                    class={`select-btn--${tour.tour_type == 2 ? 'active' : 'regular'}`}
                  >
                    <div>Online Call</div>
                  </button>
                </div>
                <div class="tour-card__form-wrapper">
                  <form ref={form} class="tour-card__form" onSubmit={handleTourSubmit}>
                    <>
                      <input
                        class="tour-card__form--input form-control form-control-lg mb-3"
                        type="text"
                        placeholder="Name*"
                        onChange={handleTourChange}
                        aria-label="Name Required"
                        required
                        value={tour.name}
                        name="name"
                      />
                      <div class="d-flex gap-3">
                        <input
                          class="form-control form-control-lg mb-3"
                          type="email"
                          placeholder="Email*"
                          aria-label="Email Required"
                          required
                          name="email"
                          value={tour.email}
                          onChange={handleTourChange}
                        />
                        <input
                          class="form-control form-control-lg mb-3"
                          placeholder="Phone"
                          aria-label="Phone"
                          name="phone"
                          value={tour.phone}
                          onChange={handleTourChange}
                        />
                      </div>
                      <div className="w-100">
                        <textarea
                          class="form-control form-control-lg mb-3"
                          type="text"
                          placeholder="Message"
                          aria-label="Message"
                          rows="5"
                          name="message"
                          onChange={handleTourChange}
                          value={tour.message}
                        ></textarea>
                      </div>
                    </>
                    <div class="d-flex flex-column gap-4 tour-card__calendar">
                      <div class="tour-card__month d-flex">
                        <h6>{getMonth(new Date().getMonth())}</h6>
                      </div>
                      <div class="d-flex flex-column gap-2">
                        {/* <div class="tour-card__day">Tomorrow</div> */}
                        <div class="date-card d-flex">
                          {/* <div class="date-card__nav">
													<img
														src="https://bucket.ywhdev.com/wl/?id=O2Sn57iluv4VB7jKfYdD6aC5zYJMQ3OH&fmode=open"
														alt=""
													/>
												</div> */}
                          <div class="date-card__wrapper">
                            <Slider {...sliderSettings}>
                              {Array.from(Array(7).keys()).map((value) => {
                                const date = new Date();
                                date.setDate(date.getDate() + (value + 1));
                                date.setHours(0, 0, 0);
                                return (
                                  <div
                                    class={`${
                                      date.toDateString() == tour.schedule.toDateString()
                                        ? 'date-card--active'
                                        : 'date-card--normal'
                                    }`}
                                    onClick={() =>
                                      setTour({
                                        ...tour,
                                        schedule: date,
                                      })
                                    }
                                  >
                                    <small>{getDayShorten(date.getDay())}</small>
                                    <h3>{date.getDate()}</h3>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div class="preferred-time">
                        <select
                          class="form-select form-select-lg mb-3"
                          aria-label="Select Preferred Time"
                          onChange={handleTourChange}
                          name="preferred_time"
                          required
                        >
                          <option selected={tour.preferred_time == ''} value="">
                            Preferred Time
                          </option>
                          <option value="Morning">Morning</option>
                          <option value="Afternoon">Afternoon</option>
                          <option value="Evening">Evening</option>
                        </select>
                      </div>
                      {success && <Alert variant="success">Request successfully submitted.</Alert>}
                      {error && <Alert variant="danger">Something went wrong, please try again</Alert>}
                      <button
                        className="btn btn-primary btn-lg schedule-card__submit-btn"
                        type="submit"
                        disabled={submitting}
                      >
                        {submitting ? 'Submitting...' : 'Submit Request'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ScheduleModal>
      </section>
    </>
  );
};

export default Community;
