import { styled } from '@mui/material';

const Section4Container = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  padding: '100px 64px',
  display: 'flex',
  alignItems: 'center',
  gap: 64,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: {
    padding: '80px 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& div.content': {
    maxWidth: 300,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 700,
    },
    h4: {
      fontSize: 28,
      lineHeight: '40px',
      color: '#04246B',
      fontWeight: 600,
      marginBottom: 48,
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 50px',
      },
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
  },
  '& form.form': {
    padding: 48,
    display: 'flex',
    columnGap: 32,
    rowGap: 24,
    border: '1px solid #CDA07D',
    borderRadius: 16,
    width: '100%',
    flexFlow: 'row wrap',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('md')]: {
      padding: 32,
    },
    div: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 'calc(50% - 16px)',
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
      },
      input: {
        width: '100%',
        borderRadius: 8,
        backgroundColor: '#E8E8E8',
        border: 'none',
        fontSize: 16,
        lineHeight: '24px',
        padding: '14px',
        ':focus': {
          outline: 'none',
        },
      },
      textarea: {
        width: '100%',
        borderRadius: 8,
        backgroundColor: '#E8E8E8',
        border: 'none',
        fontSize: 16,
        lineHeight: '24px',
        padding: '14px',
        ':focus': {
          outline: 'none',
        },
      },
      label: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
      },
    },
    button: {
      padding: 24,
      backgroundColor: '#04246B',
      color: 'white',
      width: '100%',
      flexBasis: '100%',
      borderRadius: 16,
      fontSize: 18,
      lineHeight: '28px',
      marginTop: 16,
    },
  },
}));

const Section4 = () => (
  <Section4Container>
    <div className="content">
      <h4>INTERESTED IN BUILDING A CUSTOM HOME?</h4>
      <p>
        Fill out the form to the right and we'll be in touch with you as soon as possible to discuss building the custom
        home of your dreams! <br />
        <br /> Let us know which style and where you would like to build. If you are open to ideas, please let us know
        that as well!
      </p>
    </div>
    <form action="" className="form">
      <div>
        <label>First name*</label>
        <input type="text" aria-label="First Name Required" required />
      </div>
      <div>
        <label>Last name*</label>
        <input type="text" aria-label="Last Name Required" required />
      </div>
      <div>
        <label>Phone*</label>
        <input type="tel" aria-label="Phone Required" required />
      </div>
      <div>
        <label>Email*</label>
        <input type="email" aria-label="Email Required" required />
      </div>
      <div style={{ flexBasis: '100%' }}>
        <label>Message</label>
        <textarea type="text" aria-label="Message" rows="6" />
      </div>
      <button type="submit">SEND MESSAGE</button>
    </form>
  </Section4Container>
);

export default Section4;
