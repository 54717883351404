import { memo, useEffect, useCallback, useState, useRef, useContext } from 'react';
import { registerAgentv2 } from '../../../services/admin';
import { useLocation, useNavigate } from "react-router-dom";
import { checkIsLoggedIn } from '../../../utils/common';
import routes from '../../../routes';

import './RegisterAgent.scss';
import AddAgent from '../addagent';
import { ToastContainer, toast } from 'react-toastify';
import { MainContext } from '../../../context';
import { StaticNavigation } from '../layout';
import AuthChecker from '../AuthChecker/AuthChecker';

const RegisterAgent = () => {
    const navigate = useNavigate();

    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const toastId = useRef(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {setActivePage, activeAgent} = useContext(MainContext)
    const [fieldErrors, setFieldErrors] = useState({})
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleRegister = async (data) => {

        try {
            toastId.current = toast.loading("Submitting", {
                onOpen: () => setIsSubmitting(true)
            })
            
            let result = await registerAgentv2(data);
            if (result.token) {
                toast.update(toastId.current, {
                    render: 'Agent successfully registered',
                    type: 'success',
                    autoClose: 2000,
                    closeOnClick: true,
                    isLoading: false,
                    onClose: () => {
                        setIsSubmitting(false)
                        if (checkIsLoggedIn()) {
                           setActivePage('agents')
                            navigate('/admin/agents');
                        }
                        else {
                            navigate(routes.login());
                        }
                    }
                
                })
                
            } else {
                toast.update(toastId.current, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    closeOnClick: true,
                    autoClose: 2000,
                    onClose: () =>  setIsSubmitting(false)
                })
                if(result.errors) {
                    setFieldErrors(result.errors)
                }
            }
        } catch(e) {
            toast.update(toastId.current, {
                render: e?.response?.data?.message ?? 'Something went wrong.',
                type: 'error',
                isLoading: false,
                closeOnClick: true,
                autoClose: 2000,
                onClose: () =>  setIsSubmitting(false)
            })
        }

    }

    const handleSubmit2 = useCallback(
        async (values)  => {
            const data = {
                ...values,
                name: values.first_name + ' ' + values.last_name,
                company_website: values.company_website || 'n/a',
                company_phone: values.company_phone || 'n/a',
                mls: JSON.stringify(values.mls),
                licenses: JSON.stringify(values.licenses),
                states_served: values.states_served.map(a => a.value).join(','),
                //city|state, city|state format
                cities_served: values.cities_served.map(a => a.name.replace(', ', '|')).join(','),
                communities_served: values.communities_served.map(a => a.value).join(','),
            }

            handleRegister(data)
        }
    )

    const handleSave = () => {
        handleSubmit2(activeAgent)
    }

    useEffect(() => setActivePage('add-agents'), [])

		useEffect(() => {
			const handleBeforeUnload = (event) => {
				const message = 'Are you sure you want to leave?';
				event.returnValue = message;
				return message;
			};
	
			window.addEventListener('beforeunload', handleBeforeUnload);
	
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}, []);

    return (
        <>
         <AuthChecker />
         <StaticNavigation page="add-agents" handleSave={handleSave}/>
        <AddAgent handleSubmit={handleSubmit2} isSubmitting={isSubmitting}  fieldErrors={fieldErrors}/>
        <ToastContainer />
        </>
    );
};


export default RegisterAgent;