import styled from 'styled-components';

const TabContainer = styled.div`
  background-color: ${({ isSelected }) => (isSelected ? '#3C4D7D' : '#DADADA')};
  color: ${({ isSelected }) => (isSelected ? '#EAEAEA' : '#979797')};
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.5')};
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 0.3125rem 0.3125rem 0 0;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }

  p {
    display: none;
    font-size: 1.25rem;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    p {
      display: block;
    }
  }
`;

const Tab = ({ isSelected, img, text, onClick }) => (
  <TabContainer isSelected={isSelected} onClick={onClick}>
    <img src={img} alt="" />
    <p>{text}</p>
  </TabContainer>
);

export default Tab;
