import { styled } from '@mui/material';
import React from 'react';
import { HomePageButton } from './HomePage';

const HomePageSection = styled('section')(({ theme }) => ({
  maxWidth: 1500,
  // [theme.breakpoints.down('lg')]: {
  //   maxWidth: 1200,
  // },
}));

const HomepageSection4 = styled(HomePageSection)(({ theme }) => ({
  padding: '120px 0 100px 0',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 120,
  alignSelf: 'stretch',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    backgroundColor: '#EDF2F4',
    padding: '0 64px 0 0',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: 0,
    gap: 0,
  }
}));

const HomepageSection4RightCol = styled('div')(({ theme }) => ({
  order: 2,
  borderRadius: '32px 0px 0px 32px',
  padding: '64px 120px 64px 240px',
  marginLeft: -240,
  backgroundColor: '#EDF2F4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 830,
  gap: 32,
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
    width: 496,
    margin: 0,
    padding: '100px 0px',
    borderRadius: 0,
  },
  [theme.breakpoints.down('md')]: {
    order: 1,
    width: '100%',
    padding: '80px 32px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2.5rem 1rem',
  },
  '& p': {
    fontSize: 18,
    lineHeight: '28px',
  },
  '& p.title': {
    borderBottom: '2px solid #CDA07D',
    paddingBottom: 8,
  },
}));

const HomepageSection4LeftCol = styled('div')(({ theme }) => ({
  zIndex: 2,
  maxWidth: 800,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 0,
  },
  [theme.breakpoints.down('md')]: {
    order: 2,
    width: '100%',
    flex: 1,
    maxWidth: '100%',
  },
  '& img': {
    borderRadius: '0px 16px 16px 0px',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.40)',
    height: 600,
    maxWidth: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: 692,
      width: 576,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: 0,
      height: 300,
      boxShadow: 'none',
    },
  },
}));

const HomepageSection4Heading = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: 16,
  '& img': {
    width: 48,
    height: 48,
  },
  '& h2': {
    fontSize: 32,
    lineHeight: '48px',
    fontWeight: 700,
    color: '#04246B',
    textAlign: 'center',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Section4 = () => {
  return (
    <HomepageSection4>
      <HomepageSection4LeftCol>
        <img
          src="https://bucket.ywhdev.com/wl/?id=O34VVcYuH5l6Fir4qSujHVo2vPyAay1b&fmode=open"
          alt="A beautiful showing a deck of a house."
        />
      </HomepageSection4LeftCol>
      <HomepageSection4RightCol>
        <div>
          <p className="title">Secure the financing you need.</p>
        </div>
        <HomepageSection4Heading>
          <img src="https://bucket.ywhdev.com/wl/?id=31zMQWfAa3SfpWMkJ6sgMGLXYHXFMnOd&fmode=open" alt="" />
          <h2>HOME LOAN</h2>
        </HomepageSection4Heading>
        <p>
          Our dedicated loan officers are here to help you secure the financing you need to purchase your dream home. We
          understand that every buyer has unique financial needs, which is why we offer a wide range of loan options to
          fit your your unique financial situation. Our loan officers have extensive knowledge of the latest loan
          programs and interest rates, and can help you secure the most competitive rates and terms available. We will
          help you navigate the complex world of home loans.
        </p>
        <p>Start browsing our inventory today and take the first step towards owning your dream home!</p>
        <HomePageButton onClick={() => window.open('https://yourwayloan.com/purchase/', '_blank')}>
          <span>Learn More</span>
          <i className="fa-sharp fa-solid fa-circle-chevron-right"></i>
        </HomePageButton>
      </HomepageSection4RightCol>
    </HomepageSection4>
  );
};

export default Section4;
