import { CF_URL } from '../../../../constants/common';

const CommunityImage = ({ src, alt }) => {
  return (
    <img
      alt={alt}
      src={src}
      fetchpriority="low"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = `${CF_URL}/web-assets/yourwayhome/community/assets/for-sale-default-logo.webp`;
      }}
    />
  );
};

export default CommunityImage;
