import { styled } from '@mui/material';

const Section5Container = styled('div')(({ theme }) => ({
  padding: '64px 0',
  backgroundColor: '#F4F4F6',
}));

const SectionContent = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 1300,
  padding: 64,
  display: 'flex',
  gap: 100,
  [theme.breakpoints.down('md')]: {
    padding: '64px 0',
    gap: 64,
  },
  '& div.content': {
    order: 2,
    padding: '64px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 64,
    flexShrink: 0,
    width: 536,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 64px',
      order: 1,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      textAlign: 'center',
      width: 400,
      position: 'relative',
      paddingBottom: 24,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      h1: {
        position: 'absolute',
        left: 0,
        bottom: 4,
        fontSize: 128,
        fontFamily: 'Inter',
        lineHeight: '90px',
        fontWeight: 700,
        color: '#DCE7F9',
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
        textAlign: 'center',
        zIndex: 2,
      },
      div: {
        height: 8,
        width: '100%',
        backgroundColor: '#CDA07D',
        borderRadius: 10,
        position: 'absolute',
        bottom: -8,
      },
    },
    h4: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '32px',
      },
    },
  },
  '& div.image': {
    order: 1,
    textAlign: 'center',
    flexBasis: '50%',
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
    img: {
      maxWidth: 536,
      width: '100%',
      borderRadius: 16,
      height: 400,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('xl')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        height: 300,
      },
    },
  },
}));

const Section5Content1 = styled(SectionContent)(({ theme }) => ({
  '& div.content': {
    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: '100%',
    },
  },
  '& div.image': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Section5Content2 = styled(SectionContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  '& div.content': {
    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: '100%',
    },
  },
}));

const Section5 = () => (
  <Section5Container>
    <Section5Content1>
      <div className="content">
        <div className="header">
          <h1>7</h1>
          <h2>BEDROOMS</h2>
          <div />
        </div>
        <h4>
          Turn off the television and tune the radio (low volume) to a soft rock, middle of the road, or classic rock
          station.
        </h4>
      </div>
      <div className="image">
        <img src="https://bucket.ywhdev.com/wl/?id=bBg3I98fDOnKzMA8C4MCv10z9SmIE0qu&fmode=open" alt="" />
      </div>
    </Section5Content1>
    <Section5Content2>
      <div className="content">
        <div className="header">
          <h1>8</h1>
          <h2>EXTERIOR</h2>
          <div />
        </div>
        <h4>Put away toys and clutter. Keep the walkway clear.</h4>
      </div>
      <div className="image">
        <img src="https://bucket.ywhdev.com/wl/?id=ENPUhu2C7E1pChDF0w98nuhxXxu0Ktvc&fmode=open" alt="" />
      </div>
    </Section5Content2>
  </Section5Container>
);

export default Section5;
