const CheckboxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 15C30 23.2845 23.2845 30 15 30C6.7155 30 0 23.2845 0 15C0 6.7155 6.7155 0 15 0C23.2845 0 30 6.7155 30 15ZM21.045 10.455C21.2557 10.6659 21.374 10.9519 21.374 11.25C21.374 11.5481 21.2557 11.8341 21.045 12.045L13.545 19.545C13.3341 19.7557 13.0481 19.874 12.75 19.874C12.4519 19.874 12.1659 19.7557 11.955 19.545L8.955 16.545C8.84447 16.442 8.75582 16.3178 8.69433 16.1798C8.63284 16.0418 8.59978 15.8928 8.59711 15.7418C8.59445 15.5907 8.62223 15.4407 8.67882 15.3006C8.7354 15.1605 8.81961 15.0333 8.92644 14.9264C9.03327 14.8196 9.16052 14.7354 9.3006 14.6788C9.44069 14.6222 9.59073 14.5944 9.74178 14.5971C9.89284 14.5998 10.0418 14.6328 10.1798 14.6943C10.3178 14.7558 10.442 14.8445 10.545 14.955L12.75 17.16L16.1025 13.806L19.455 10.455C19.6659 10.2443 19.9519 10.126 20.25 10.126C20.5481 10.126 20.8341 10.2443 21.045 10.455Z"
      fill="#5284E0"
    />
  </svg>
);

export default CheckboxIcon;
