import { styled } from '@mui/material';

const Section6Container = styled('div')(({ theme }) => ({
  padding: '64px 0',
  [theme.breakpoints.down('md')]: {
    paddingBottom: 0,
  },
}));

const SectionContent = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 1300,
  padding: 64,
  display: 'flex',
  gap: 100,
  [theme.breakpoints.down('md')]: {
    padding: '64px 0',
    gap: 64,
  },
  '& div.content': {
    padding: '64px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 64,
    flexShrink: 0,
    width: 536,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 64px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    '& div.header': {
      textAlign: 'center',
      width: 400,
      position: 'relative',
      paddingBottom: 24,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      h1: {
        position: 'absolute',
        left: 0,
        bottom: 4,
        fontSize: 128,
        fontFamily: 'Inter',
        lineHeight: '90px',
        fontWeight: 700,
        color: '#DCE7F9',
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
        textAlign: 'center',
        zIndex: 2,
      },
      div: {
        height: 8,
        width: '100%',
        backgroundColor: '#CDA07D',
        borderRadius: 10,
        position: 'absolute',
        bottom: -8,
      },
    },
    h4: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '32px',
      },
    },
  },
  '& div.image': {
    textAlign: 'center',
    flexBasis: '50%',
    img: {
      maxWidth: 526,
      borderRadius: 16,
      height: 400,
      objectFit: 'cover',
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('xl')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: 0,
        height: 300,
      },
    },
  },
}));

const Section6Content1 = styled(SectionContent)(({ theme }) => ({
  '& div.content': {
    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: '100%',
    },
  },
  '& div.image': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Section6Content2 = styled(SectionContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingBottom: 0,
  },
  '& div.content': {
    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: '100%',
    },
  },
}));

const Section6 = () => (
  <Section6Container>
    <Section6Content1>
      <div className="content">
        <div className="header">
          <h1>9</h1>
          <h2>CHILDREN/PETS</h2>
          <div />
        </div>
        <h4>They are a distraction, so send them outside.</h4>
      </div>
      <div className="image">
        <img src="https://bucket.ywhdev.com/wl/?id=JVbk91c4DQ7z0vqT07MISwerLfr74j1J&fmode=open" alt="" />
      </div>
    </Section6Content1>
    <Section6Content2>
      <div className="content">
        <div className="header">
          <h1>10</h1>
          <h2>GOODBYE</h2>
          <div />
        </div>
        <h4>
          You must leave the home so the buyers can get emotionally committed to your home to buy it and they cannot
          become emotional about "their new home" if you, the current owners, are "hanging around".
        </h4>
      </div>
      <div className="image">
        <img src="https://bucket.ywhdev.com/wl/?id=oGLnsZTCol8aBHuBVSe2LG9zSjcKNdUZ&fmode=open" alt="" />
      </div>
    </Section6Content2>
  </Section6Container>
);

export default Section6;
