import { styled } from '@mui/material';

const Section1Container = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: 120,
  padding: '48px 64px 128px',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '0 32px 80px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '0',
  },
  '& div.content': {
    width: 526,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      width: 446,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& div.header': {
      marginBottom: 64,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 16,
      padding: '0 64px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
      img: {
        height: 100,
        width: 100,
      },
      h2: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '48px',
        color: '#04246B',
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
      },
    },
    p: {
      fontSize: 18,
      lineHeight: '28px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  '& div.image': {
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      borderRadius: 16,
      height: 532,
      boxShadow: '16px 8px 48px 0px rgba(0, 0, 0, 0.25)',
      objectFit: 'cover',
      [theme.breakpoints.down('lg')]: {
        height: 560,
      },
      [theme.breakpoints.down('md')]: {
        height: 400,
        width: 500,
      },
    },
  },
}));

const Section1 = () => {
  return (
    <Section1Container>
      <div className="content">
        <div className="header">
          <img src="https://bucket.ywhdev.com/wl/?id=5LNkTWp8BqcpCM5E7ZprLvBF09S16Dw2&fmode=open" alt="" />
          <h2>Considering Buying A New Home?</h2>
          <h4>Nothing quite compares to a brand new home...</h4>
        </div>
        <p>
          It's all yours and (hopefully) exactly what you expected. But buying a new home isn't without its challenges.
          With thousands of choices and lots of $000's how do you manage expectations and your budget? And more
          importantly, how do you compare home builders and evaluate their quality?
        </p>
      </div>
      <div className="image">
        <img
          className="shadow-left"
          src="https://bucket.ywhdev.com/wl/?id=gBO2ZTTwi5XqJLxPkQUzGRGgvn8Ak3PP&fmode=open"
          alt="showing a beautiful 2-storey house."
        />
      </div>
    </Section1Container>
  );
};

export default Section1;
