import styled from 'styled-components'

const LoaderWrapper = styled.div`
    min-height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
		flex-direction: column;
`

export const Loader = styled.div`

    width: ${(props) => props.size || 50}px;
    height: ${(props) => props.size || 50}px;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#5284E0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  @keyframes s3 {to{transform: rotate(1turn)}}
`
export default  () => {
    return (
        <LoaderWrapper>
            <Loader />
						<p>Loading...</p>
        </LoaderWrapper>
    )
}